import { Injectable } from '@angular/core';
import { DeviceBaseFragment, Maybe } from '@designage/gql';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TableRowSelectionDialogComponent } from '@desquare/components/common/src/tablerow-selection-dialog/tablerow-selection-dialog.component';
import { ConfirmationButtons, ConfirmationResponse } from '@desquare/enums';
import {
  IDesignageDataTableColumns,
  ITableRowSelection,
} from '@desquare/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(private modalService: NgbModal) {}

  async selectDevices(
    showDevices: DeviceBaseFragment[],
    allowMultiselect: boolean
  ) {
    return new Promise<Maybe<DeviceBaseFragment[]>>((resolve, reject) => {
      // console.log('promise start');
      const modalRef = this.modalService.open(
        TableRowSelectionDialogComponent,
        {
          windowClass: 'cesdk-modal',
          backdrop: 'static',
        }
      );

      const columns: IDesignageDataTableColumns[] = [
        {
          fieldName: 'id',
          name: '',
          type: 'device-status-indicator',
          visible: 'mandatory',
        },
        {
          fieldName: 'name',
          name: 'DEVICE_NAME',
          type: 'string',
          visible: 'mandatory',
        },
        {
          fieldName: 'location.name',
          name: 'LOCATION',
          type: 'object',
          visible: 'mandatory',
        },
        {
          fieldName: 'appVersionStatus',
          name: 'Screen App',
          type: 'string',
          visible: 'mandatory',
        },
      ];

      const dialogComponentInstance =
        modalRef.componentInstance as ITableRowSelection; // .id = id;

      dialogComponentInstance.columns = columns;
      dialogComponentInstance.rows = showDevices;
      dialogComponentInstance.multiSelect = allowMultiselect;
      dialogComponentInstance.title = 'SELECT_DEVICE';

      modalRef.result.then(
        (value: Maybe<DeviceBaseFragment[]>) => {
          // console.log('resolve promise');
          resolve(value);
        },
        () => {
          // console.log('dismiss with no value');
          resolve(null);
        }
      );
    });
  }

  async confirm(
    message: string,
    items: Maybe<string> | string[] = undefined,
    danger: boolean = false,
    title: string = 'CONFIRM_DIALOG_MESSAGE',
    buttons: ConfirmationButtons = ConfirmationButtons.YesCancel
  ): Promise<ConfirmationResponse> {
    return new Promise<ConfirmationResponse>((resolve, reject) => {
      const modalRef = this.modalService.open(ConfirmDialogComponent, {
        size: 'sm',
      });

      const dialogComponentInstance =
        modalRef.componentInstance as ConfirmDialogComponent;

      dialogComponentInstance.headerText = title;
      dialogComponentInstance.bodyText = message;
      dialogComponentInstance.extraBodyItems = !items
        ? undefined
        : items instanceof Array
        ? items
        : [items];
      dialogComponentInstance.useDangerButton = danger;
      dialogComponentInstance.useDangerMessage = danger;
      modalRef.result.then(
        (value) => {
          const answer = value
            ? ConfirmationResponse.Yes
            : ConfirmationResponse.No;
          resolve(answer);
        },
        () => {
          resolve(ConfirmationResponse.Cancel);
        }
      );
    });
  }
}
