import { createAction, props } from '@ngrx/store';
import { Profile, Location } from '@designage/gql';

export enum ProfileActionTypes {
  SWITCH_ACTIVE_PROFILE = 'SWITCH_ACTIVE_PROFILE',
  CLEAR_ACTIVE_PROFILE = 'CLEAR_ACTIVE_PROFILE',
  SET_ACTIVE_PROFILE_LOCATIONS = 'SET_ACTIVE_PROFILE_LOCATIONS',
}

const switchActiveProfile = createAction(
  ProfileActionTypes.SWITCH_ACTIVE_PROFILE,
  props<{ profile: Profile }>()
);
const clearActiveProfile = createAction(
  ProfileActionTypes.CLEAR_ACTIVE_PROFILE
);
const setActiveProfileLocations = createAction(
  ProfileActionTypes.SET_ACTIVE_PROFILE_LOCATIONS,
  props<{ locations: Location[] }>()
);

export const profileActions = {
  switchActiveProfile,
  clearActiveProfile,
  setActiveProfileLocations,
};
