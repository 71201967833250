import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

/** transforms from NgbTimeStruct object to HH:MM */
const formatNgbTime = (inputNgbTime: NgbTimeStruct) => {
  const formattedTimeHH = inputNgbTime.hour.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const formattedTimeMM = inputNgbTime.minute.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return `${formattedTimeHH}:${formattedTimeMM}`;
};

const stringHMtoNgbTime = (stringTime: string): NgbTimeStruct => {
  const HH = stringTime.split(':')[0];
  const MM = stringTime.split(':')[1];
  return { hour: +HH, minute: +MM, second: 0 };
};

/** this function converts time format from HH:MM (HM) to HH:MM:SS (HMS) */
const formatTimeHMToHMS = (timeHMformatted: string) => {
  // the back end needs the time format to be in 24hrs HH:MM:SS
  // since the SS (seconds) is not specified it should be 00
  return timeHMformatted + ':00';
};

/** this function converts time format from HH:MM:SS (HMS) to HH:MM (HM) */
const formatTimeHMSToHM = (timeHMSformatted: string) => {
  const HMformatted = timeHMSformatted.split(':');
  return `${HMformatted[0]}:${HMformatted[1]}`;
};

export const ngbTimeStructUtil = {
  formatNgbTime,
  stringHMtoNgbTime,
  formatTimeHMToHMS,
  formatTimeHMSToHM,
};
