import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ILocationState, IAppState } from '@desquare/store/states';

const featureKey = 'location';

const locationFeature = createFeatureSelector<IAppState, ILocationState>(
  featureKey
);

const locations = createSelector(locationFeature, (state: ILocationState) => {
  return state.locations;
});
const error = createSelector(locationFeature, (state: ILocationState) => {
  return state.error;
});
const deleteLocationStatus = createSelector(
  locationFeature,
  (state: ILocationState) => {
    return state.deleteLocationStatus;
  }
);

export const fromLocation = {
  featureKey,
  locations,
  error,
  deleteLocationStatus,
};
