import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SlidePanelComponent } from '../../shared/slide-panel/slide-panel.component';
import { LayoutCreateComponent } from '../layout-create/layout-create.component';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, LayoutCreateComponent],
  selector: 'designage-layout-create-panel',
  template: `<app-slide-panel
    (closePanel)="closeLayoutManagePanel()"
    [fullscreen]="true"
    [panelContent]="'layoutCreate'"
  >
    <app-layout-create></app-layout-create>
  </app-slide-panel> `,
})
export class LayoutCreatePanelComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  closeLayoutManagePanel() {
    this.router.navigate(['layouts']);
  }
}
