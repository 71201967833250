import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlidePanelComponent } from '@designage/app/shared/slide-panel/slide-panel.component';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import {
  CurrentUserService,
  EncryptionService,
  PlaylistViewService,
  PlaylistEditorService,
} from '@desquare/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubSink } from 'subsink';
import { PlaylistManageComponent } from '../playlist-manage/playlist-manage.component';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, PlaylistManageComponent],
  selector: 'app-playlist-manage-panel',
  template: `
    <app-slide-panel
      (closePanel)="closePlaylistManagePanel()"
      [fullscreenTooltip]="'TOGGLE_PLAYLIST_TABLE'"
      [closePanelTooltip]="'CLOSE_THIS_PLAYLIST'"
      [openWidth]="20"
      [fullscreen]="true"
      [panelContent]="'playlistManage'"
    >
      <app-playlist-manage></app-playlist-manage>
    </app-slide-panel>
  `,
})
export class PlaylistManagePanelComponent implements OnDestroy {
  private subs: SubSink = new SubSink();

  showTableBelowPlaylist: boolean = false;
  isPlaylistManagePanelCollapsed: boolean = true;
  playlistId?: string;
  isOkToRemovePendingPlaylist: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private playlistViewService: PlaylistViewService,
    private modalService: NgbModal,
    private encryptionService: EncryptionService,
    private playlistEditorService: PlaylistEditorService,
    private currentUserService: CurrentUserService
  ) {}

  async canDeactivate(): Promise<boolean> {
    return this.playlistViewService.hasUnsavedPlaylistChanges
      ? this.openUnsavedConfirmationDialog()
      : true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.subs.sink = this.route.params.subscribe((params) => {
      const encryptedPlaylistId = params.playlistId;
      this.playlistId = this.encryptionService.decrypt(encryptedPlaylistId);
    });
  }

  async openUnsavedConfirmationDialog() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'sm',
    });

    modalRef.componentInstance.headerText = 'Leave the page?';
    modalRef.componentInstance.bodyText =
      'There are unsaved changes, are you sure?';

    return await modalRef.result.then(
      (value) => {
        this.playlistViewService.hasUnsavedPlaylistChanges = false;

        // this prevents the unsaved changes info banner to show (backup system of playlist)
        // it is alright to remove the backup since the user at this point on purposely left
        // the playlist manage page
        this.removePendingPlaylist();

        return value;
      },
      () => {
        return false;
      }
    );
  }

  closePlaylistManagePanel() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  removePendingPlaylist() {
    this.playlistEditorService.deletePendingPlaylist({
      profileId: this.currentUserService.currentProfile?.id,
      playlistId: this.playlistId,
    });
  }
}
