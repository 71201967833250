import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { MeGQL, PaginatedProfiles, GetMeProfilesGQL } from '@designage/gql';
import { UserActionTypes, userActions } from '@desquare/store/actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { IUser } from '@desquare/interfaces';
import { EncryptionService } from '@desquare/services';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { authUtil } from '@desquare/utils';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private meGQL: MeGQL,
    private getMeProfilesGQL: GetMeProfilesGQL,
    private encryptionService: EncryptionService
  ) {}

  loadActiveUserDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof userActions.loadActiveUser>>(
        UserActionTypes.LOAD_ACTIVE_USER
      ),
      switchMap((action) =>
        this.meGQL.fetch().pipe(
          map((result) => {
            const user: IUser = {
              ...result.data.me,
            } as IUser;

            user.idEncrypted = this.encryptionService.encrypt(user.id);

            return userActions.setActiveUser({ user });
          }),
          catchError((error) => of(userActions.loadActiveUserError({ error })))
        )
      )
    )
  );

  loadActiveUserProfiles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof userActions.loadActiveUserProfiles>>(
        UserActionTypes.LOAD_ACTIVE_USER_PROFILES
      ),
      switchMap((action) =>
        this.getMeProfilesGQL
          .fetch({
            page: action.page,
            pageSize: action.pageSize,
          })
          .pipe(
            map((result) => {
              return userActions.setActiveUserProfiles({
                profiles: (result.data.me?.profiles as PaginatedProfiles) || {
                  total: 0,
                  results: [],
                },
              });
            }),
            catchError((error) =>
              of(userActions.loadActiveUserProfilesError({ error }))
            )
          )
      )
    )
  );
}
