import {
  Component,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  computed,
  viewChild,
} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { trigger, transition, style, animate } from '@angular/animations';
import { ResponsiveUiService } from '@desquare/services';
import { NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from '@desquare/components/common/src/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    TopbarComponent,
    SidebarComponent,
    FooterComponent,
    RouterOutlet,
  ],
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger('updateSlideIn', [
      transition(':enter', [
        style({ top: -40, opacity: 0 }),
        animate('0.5s ease-out', style({ top: 0, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ top: 0, opacity: 1 }),
        animate('0.5s ease-in', style({ top: -40, opacity: 0 })),
      ]),
    ]),
  ],
})
export class MainComponent {
  updateAvailable = false;
  updateLoading: boolean = false;

  sidebarComponent = viewChild(SidebarComponent);

  isSidebarCollapsed = computed(
    () => this.sidebarComponent()?.isSidebarCollapsed() ?? false
  );
  constructor(
    private readonly swUpdate: SwUpdate,
    public responsiveUiService: ResponsiveUiService
  ) {
    console.log('swUpdate.isEnabled: ', this.swUpdate.isEnabled);
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(takeUntilDestroyed()).subscribe((e) => {
        console.log('swUpdate.versionUpdates: ', e);
        if (e.type === 'VERSION_READY') this.updateAvailable = true;
      });
      setInterval(() => {
        this.swUpdate.checkForUpdate();
      }, 1000 * 60 * 10); // check every 10 min
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  reloadWindow() {
    this.updateLoading = true;
    window.location.reload();
  }
}
