import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationManageComponent } from '../location-manage/location-manage.component';
import { SlidePanelComponent } from '../../shared/slide-panel/slide-panel.component';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, LocationManageComponent],
  selector: 'app-location-manage-slider',
  template: `<app-slide-panel
    (closePanel)="closeLocationManagePanel()"
    [fullscreenTooltip]="'TOGGLE_CHANNEL_TABLE'"
    [closePanelTooltip]="'CLOSE_THIS_CHANNEL'"
    [fullscreen]="true"
    [openWidth]="20"
    [panelContent]="'locationManage'"
  >
    <app-location-manage></app-location-manage>
  </app-slide-panel> `,
})
export class LocationManageSliderComponent {
  constructor(private router: Router, private route: ActivatedRoute) {}

  closeLocationManagePanel() {
    this.router.navigate(['../../../'], { relativeTo: this.route });
  }
}
