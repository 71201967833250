import { CustomResolver } from './common';
import {
  GetSelectedChannelQuery,
  GetSelectedChannelDocument,
  MutationSetSelectedChannelArgs,
} from '@designage/gql';

export const setSelectedChannel: CustomResolver<
  any,
  MutationSetSelectedChannelArgs
> = (_, { input }, { cache }) => {
  /*
  const data = cache.readQuery<GetSelectedChannelQuery>({
    query: GetSelectedChannelDocument,
  });

  if (data) {
    data.selectedChannel = input;
  }*/

  const data: GetSelectedChannelQuery = {
    selectedChannel: input,
  };

  cache.writeQuery({
    query: GetSelectedChannelDocument,
    data,
  });

  return input;
};
