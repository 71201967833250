import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthModule } from '@auth0/auth0-angular';
import { HttpLoaderFactory } from '@desquare/factories';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@desquare/store/store.module';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { environment as env } from '@desquare/environments';
import { NgxEchartsModule } from 'ngx-echarts';
import { IConfig, provideNgxMask } from 'ngx-mask';
import { MqttModule, IMqttServiceOptions } from 'ngx-mqtt';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { GraphqlModule } from './graphql.module';
import * as echarts from 'echarts';

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'mqtt.designage.se', // 'broker.emqx.io',
  port: 443,
  protocol: 'wss',
  reconnectPeriod: 10000,

  // path: '/mqtt',
};

export const ngxMaskOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      // AppRoutingModule,
      GraphqlModule,
      StoreModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: env.production,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
      AuthModule.forRoot({
        domain: env.urls.auth0,
        clientId: env.auth0.clientId.designage,
        useRefreshTokens: env.auth0.useRefreshToken,
        useRefreshTokensFallback: true,
        cacheLocation: env.auth0.cacheLocation,
        httpInterceptor: {
          allowedList: ['/api/*'],
        },
        authorizationParams: {
          audience: env.auth0.apiAudience,
          redirect_uri: window.location.origin,
        },
      }),
      ToastrModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),

      MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
      NgxEchartsModule.forRoot({ echarts }),
      NgxSkeletonLoaderModule.forRoot({
        theme: {
          extendsFromRoot: true,
          height: '0.5rem',
          'border-radius': '0.25rem',
          'background-color': '#38414a',
        },
      }),
      NgxWebstorageModule.forRoot({
        prefix: 'designage',
        separator: '-',
        caseSensitive: false,
      })
    ),
    NgbActiveModal,
    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    provideNgxMask(ngxMaskOptions),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
