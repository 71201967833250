import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss'],
})
export class LocationDetailComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
