<app-loader
  class="loader"
  [message]="loaderMessage"
  *ngIf="loading"
></app-loader>

<div [hidden]="loading" class="d-flex flex-column h-100">
  <div class="card-box">
    <div class="row">
      <div class="col-12">
        <app-user-selection
          *ngIf="profile"
          [profileId]="profile.id"
          [(selectedUsers)]="selectedUsers"
          [enableSelectionActionButtons]="true"
          selectActionButtonText="ADD"
          deselectActionButtonText="REMOVE"
          (userDeselect)="onUserDeselected($event)"
          (rowActivate)="navigateToChannel($event)"
          (userSelect)="onUserSelect($event)"
          (openInviteDialogClicked)="openInviteDialog()"
          [profileUsersUpdated]="profileUsersUpdated"
        ></app-user-selection>
      </div>
    </div>

    <div *ngIf="lastInvitedUrl?.length > 0" class="mt-2 card">
      <div class="card-header">
        <h5>Generated invite URL</h5>
      </div>
      <div class="card-body">
        <div class="card-text">
          <p>
            The invite link is sent to the user's email address. The user can
            then use the link to register and join this profile.
            <br />
            If you want to send this link in another way (like with What's App
            or your own email program) you can copy this link from below.
            <br />
            <br />
            The link is valid for 48 hours and can only be used once.
          </p>
        </div>
      </div>
      <div class="card-body">
        <div class="card-text font-monospace p-2">
          {{ lastInvitedUrl }}
        </div>
        <copy-to-clipboard
          [cbcontent]="lastInvitedUrl"
          [button]="true"
        ></copy-to-clipboard>
      </div>
    </div>
  </div>
</div>
