import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  computed,
  inject,
  output,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  signal,
  input,
  DestroyRef,
  effect,
} from '@angular/core';
import { SubSink } from 'subsink';
import {
  ChannelsForChannelListFragment,
  WriteSelectedChannelToCacheGQL,
  WriteSelectedDeviceToCacheGQL,
  GetChannelPlaylistsGQL,
  PlaylistStatus,
  Playlist,
  ChannelPlaylist,
  Device,
  ChannelType,
  AddDevicesToChannelGQL,
  RemoveDevicesFromChannelGQL,
  UpdateChannelMutation,
  Channel,
  GetChannelPlaylistsQuery,
} from '@designage/gql';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  EncryptionService,
  CurrentUserService,
  DeviceDataService,
  UiDataService,
  SlidePanelService,
  ResponsiveUiService,
  PopupService,
  ChannelService,
} from '@desquare/services';
import {
  NgbModal,
  NgbNav,
  NgbNavContent,
  NgbNavItem,
  NgbNavLink,
  NgbNavModule,
  NgbNavOutlet,
  NgbPopover,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { DeviceCreateDialogComponent } from '@designage/app/device/device-create-dialog/device-create-dialog.component';
import {
  IChannelForm,
  IDesignageDataTableColumns,
  ILayoutEditorSource,
} from '@desquare/interfaces';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@desquare/environments';
import { DeviceManageComponent } from '@designage/app/device/device-manage/device-manage.component';
import { lastValueFrom } from 'rxjs';
import { DeviceData } from '@desquare/types';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { cloneDeep } from 'lodash';
import { AsyncPipe, JsonPipe, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { AngularSplitModule } from 'angular-split';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';
import { ChannelSettingsComponent } from '../channel-settings/channel-settings.component';
import { ChannelFormComponent } from '../channel-form/channel-form.component';
import { PlaylistListForChannelPageComponent } from '@designage/app/playlist/playlist-list-for-channel-page/playlist-list-for-channel-page.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ChannelsStore, DevicesStore, PlaylistsStore } from '@desquare/stores';
import { LayoutExplorerComponent } from '@desquare/components/common/src/layout-explorer/layout-explorer.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

enum ChannelTabs {
  PLAYLISTS = 'PLAYLISTS',
  USERS = 'USERS',
}

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
    AngularSplitModule,
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbNavContent,
    NgbNavOutlet,
    LoaderComponent,
    DesignageDataTableComponent,
    TableDateTimeComponent,
    DeviceManageComponent,
    ChannelSettingsComponent,
    ChannelFormComponent,
    PlaylistListForChannelPageComponent,
    LayoutExplorerComponent,
    NgbTooltip,
    NgbPopover,
    AsyncPipe,
    JsonPipe,
  ],
  selector: 'app-channel-manage',
  templateUrl: './channel-manage.component.html',
  styleUrls: ['./channel-manage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelManageComponent implements OnInit, OnDestroy {
  devicesStore = inject(DevicesStore);
  channelStore = inject(ChannelsStore);
  playlistsStore = inject(PlaylistsStore);
  @ViewChild('deviceManageComponent')
  deviceManageComponent!: DeviceManageComponent;
  @Output() submitForm = new EventEmitter<IChannelForm>();

  selectedChannel = computed(
    () =>
      this.channelStore
        .channels()
        .filter((channel) => channel.id === this.decryptedChannelId())[0] ||
      null
    // this.channelStore.getChannel(this.decryptedChannelId())() || null
  );

  channelId = computed(() => this.selectedChannel()?.id);

  selectedDeviceId = signal<string | null>(null);

  private subs = new SubSink();
  private statusFilters: PlaylistStatus[] = [PlaylistStatus.Active];
  devicesConnectedToChannel = computed(() =>
    this.devicesStore
      .devices()
      .filter((d) => this.selectedChannel()?.devices.find((x) => x.id === d.id))
  );
  selectedDevice = computed(() =>
    this.singleDeviceChannel()
      ? this.devicesStore
          .devices()
          .find((x) => x.id === this.selectedChannel()?.devices[0]?.id)
      : this.devicesStore
          .devices()
          .find((x) => x.id === this.selectedDeviceId())
  );

  singleDeviceChannel = computed(
    () =>
      (this.selectedChannel()?.type === ChannelType.Channel &&
        this.devicesConnectedToChannel().length === 1) ||
      false
  );

  currentUserService = inject(CurrentUserService);

  /** only used for debug applet settings panel */
  apiUrl = environment.urls.designageApi;
  dataBucketUrl = environment.urls.dataBucket;
  selectedPlaylistRegion = signal<string | null>(null);

  isChannel = computed(
    () => this.selectedChannel()?.type === ChannelType.Channel
  );
  isEvent = computed(() => this.selectedChannel()?.type === ChannelType.Event);

  playlistRegions: Maybe<ChannelPlaylist[]> = [];
  playlistsConnectedToChannel = computed(() =>
    this.playlistsStore
      .playlists()
      .filter((p) =>
        this.selectedChannel()?.playlists.find((x) => x.id === p.id)
      )
  );

  tabs = ChannelTabs;
  currentTab!: ChannelTabs;

  profileId = computed(() => this.currentUserService.getCurrentProfileId());
  loadingDevices!: boolean;
  loadingPlaylists = signal<boolean>(false);
  loadingChannels!: boolean;
  loadingChannelSettings!: boolean;
  isMultipleDeviceChannel!: boolean;

  canManageUserPermissions = false;
  /** custom feature */
  canLinkChannelsToUsers = false;

  decryptedChannelId = signal<string>('');
  decryptedProfileId!: string;
  channelForm!: FormGroup;

  deviceColumns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'status',
      name: '',
      type: 'status-indicator',
      visible: 'mandatory',
      flex: '0',
      style: 'min-width:3rem;max-width:3rem;',
      disableSorting: true,
    },
    {
      fieldName: 'name',
      name: 'name',
      type: 'string',
      visible: 'mandatory',
    },
    {
      fieldName: 'deviceInfo.appletVersion',
      name: 'applet',
      type: 'string',
      visible: false,
    },
    {
      fieldName: 'deviceInfo.currentTime.currentDate',
      name: 'LOCAL_DEVICE_TIME',
      type: 'template',
      templateRef: 'deviceTime',
      visible: true,
      disableSorting: true,
    },
    {
      fieldName: 'lastPing',
      name: 'PING',
      type: 'template',
      templateRef: 'deviceTimeAgo',
      visible: true,
    },
    {
      fieldName: 'location.name',
      name: 'location',
      type: 'string',
      visible: false,
    },
  ];

  get layoutAll() {
    // TODO: fix later, type assertion here is a temporary solution
    const source: ILayoutEditorSource = this.selectedChannel()?.layout
      ?.source as ILayoutEditorSource;
    return source?.regionBlocks;
  }
  get s3smilPlaylistsUrl() {
    return `${
      this.dataBucketUrl
    }/api/v1/smil_playlists/channel/${this.channelId()?.substring(
      0,
      3
    )}/${this.channelId()}.json`.toLowerCase();
  }

  get s3playlistsUrl() {
    return `${
      this.dataBucketUrl
    }/api/v1/playlists/channel/${this.channelId()?.substring(
      0,
      3
    )}/${this.channelId()}.json`.toLowerCase();
  }
  get s3LayoutUrl() {
    return `${
      this.dataBucketUrl
    }/api/v1/playlists/layout/${this.channelId()?.substring(
      0,
      3
    )}/${this.channelId()}.json`.toLowerCase();
  }

  /** list of device ids for a normal channel or an event */
  deviceIds: string[] = [];
  selectedDevices: DeviceData[] = [];

  destroyRef = inject(DestroyRef);

  channelPlaylists = inject(GetChannelPlaylistsGQL);

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private encryptionService: EncryptionService,
    private modalService: NgbModal,
    private writeSelectedChannelToCache: WriteSelectedChannelToCacheGQL,
    private writeSelectedDeviceToCache: WriteSelectedDeviceToCacheGQL,
    private deviceDataService: DeviceDataService,
    private uiDataService: UiDataService,
    private slidePanelService: SlidePanelService,
    private addDevicesToChannel: AddDevicesToChannelGQL,
    private removeDevicesFromChannel: RemoveDevicesFromChannelGQL,
    private popupService: PopupService,
    public responsiveUiService: ResponsiveUiService,
    private breadcrumbService: BreadcrumbService
  ) {
    // effect(() => {
    //   console.log('channel', this.selectedChannel());
    // });
  }

  ngOnInit() {
    this.initSubscriptions();
  }

  async ngOnDestroy() {
    await this.writeSelectedChannelToCache.mutate({ input: null }).toPromise();
    await this.writeSelectedDeviceToCache.mutate({ input: null }).toPromise();

    this.subs.unsubscribe();
  }

  get explorerSize() {
    if (this.responsiveUiService.xl()) {
      return '275px';
    }
    if (this.responsiveUiService.md()) {
      return '220px';
    }
    if (this.responsiveUiService.sm()) {
      return '300px';
    }
    return '200px';
  }

  updateChannel(channel: IChannelForm) {
    this.submitForm.emit(channel);
  }

  resetValues() {
    this.loadingDevices = false;
    this.loadingPlaylists.set(false);
    this.loadingChannels = false;
    this.loadingChannelSettings = false;
    this.currentTab = ChannelTabs.PLAYLISTS;
  }

  get isUserSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  initChannelForm() {
    this.channelForm = this.formBuilder.group({
      profileId: [this.profileId() || null, Validators.required],
      name: [null, Validators.required],
      description: [null],
      layoutId: [null],
      layoutName: [null],
    });

    this.setControlValues();
  }

  async setControlValues() {
    this.channelForm.patchValue({
      name: this.selectedChannel()?.name,
      description: this.selectedChannel()?.description,
      layoutId: this.selectedChannel()?.layout?.id || null,
      layoutName: this.selectedChannel()?.layout?.name || null,
    });
  }

  initSubscriptions() {
    this.canManageUserPermissions = false;

    // subscribing to route params to get channelId and profileId
    this.route.params
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        const encryptedChannelId = params.channelId;
        const encryptedProfileId = params.profileId;

        if (encryptedChannelId && encryptedProfileId) {
          this.decryptedChannelId.set(
            this.encryptionService.decrypt(encryptedChannelId)
          );
          // reset values
          this.resetValues();

          // get channel
          this.initSelectedChannel(this.decryptedChannelId());
          this.selectedDeviceId.set(
            this.singleDeviceChannel()
              ? this.selectedChannel()?.devices[0]?.id ?? null
              : null
          );
        }
      });
  }

  async initSelectedChannel(channelId: string) {
    console.log('initSelectedChannel', channelId);

    this.deviceIds =
      this.selectedChannel()
        ?.devices.map((x) => x.id)
        .filter((x) => !!x) || [];
    if (this.isChannel() && this.selectedChannel()?.devices.length === 1) {
      this.selectedDeviceId.set(this.selectedChannel()?.devices[0]?.id ?? null);
    } else {
      this.showDeviceList();
    }
    if (this.selectedChannel()?.name) {
      this.breadcrumbService.set('@channelManage', {
        skip: false,
        label: this.selectedChannel()?.name,
        info: 'fat',
      });
    }
    if (this.selectedChannel()?.id) {
      // TODO: these 2 lines could be unnecessary, just fix correct return fields in the main getChannel query
      this.getPlaylistsByChannel(this.channelId()!);
      this.slidePanelService.setPanelComponentId(this.selectedChannel()!.id);
    }

    // set channel form values
    if (this.selectedChannel()) this.initChannelForm();
  }

  /** get list of playlists */
  getPlaylistsByChannel(channelId: string) {
    this.loadingPlaylists.set(true);
    lastValueFrom(
      this.channelPlaylists.fetch(
        {
          channelId,
          playlistName: '',
          statuses: this.statusFilters,
        },
        { fetchPolicy: 'network-only' }
      )
    )
      .then(({ data }) => {
        this.loadingPlaylists.set(false);
        const channel = cloneDeep(data.channel);

        if (channel) {
          this.playlistRegions = channel.playlistRegions;
          // this.playlists.set(channel.playlists as Playlist[]);
        } else {
          // this.playlists.set([]);
        }
      })
      .catch((err) => {
        this.loadingPlaylists.set(false);
        console.error('error in channelPlaylists.valueChanges', err);
      });
  }

  refreshPlaylists() {
    this.getPlaylistsByChannel(this.channelId()!);
  }

  refreshDevices() {
    this.refreshPlaylists();
    this.deviceDataService.refreshDevicesFromApi();
  }

  showDeviceList() {
    this.selectedDeviceId.set(null);
  }
  /** handle click event from deviceList object */
  onDeviceClick(device: DeviceData) {
    this.selectedDeviceId.set(device.id);
  }

  async provisionDevice() {
    if (this.selectedChannel()) {
      const devices = this.selectedChannel()?.devices || [];
      const device = await this.doProvisionDevice(devices.length + 1);
      if (device) {
        // refresh UI

        devices.push(device);
        // this.selectedChannel.update((channel) => {
        //   // Ensure all necessary properties are included

        //   return {
        //     ...channel,
        //     devices: devices,
        //     createdAt: channel?.createdAt ?? new Date().toISOString(), // Or other default handling for createdAt
        //     // Include any other required properties here
        //   } as Maybe<ChannelsForChannelListFragment>;
        // });
        this.selectedDeviceId.set(device.id);
        this.deviceDataService.refreshDevices();
      }
    }
  }

  doProvisionDevice(deviceCount: number) {
    return new Promise<Device>((resolve, reject) => {
      const modalRef = this.modalService.open(DeviceCreateDialogComponent, {
        size: 'lg',
        backdrop: 'static',
      });
      modalRef.componentInstance.channelId = this.selectedChannel()?.id;
      modalRef.componentInstance.deviceName = `${
        this.selectedChannel()?.name
      } - ${deviceCount}`;
      modalRef.componentInstance.profileId = this.profileId;
      modalRef.componentInstance.isHideChannelSelection = true;
      modalRef.result.then(
        (device: Device) => {
          resolve(device);
        },
        () => {
          reject();
        }
      );
    });
  }

  async connectExistingDevices(type: 'channel' | 'event') {
    const availableDevices = this.devicesStore
      .devices()
      .filter((x) => !this.deviceIds.includes(x.id));
    const addDevices = await this.popupService.selectDevices(
      this.devicesStore.devices(),
      true
    );
    if (addDevices && addDevices.length > 0) {
      const { data } = await lastValueFrom(
        this.addDevicesToChannel.mutate({
          input: {
            channelId: this.channelId()!,
            deviceIds: addDevices.map((x) => x.id),
          },
        })
      );
      if (data) {
        this.deviceIds =
          data.addDevicesToChannel.channel?.devices.map((x) => x.id) || [];
      }
    }
  }

  async removeSelectedDevices() {
    const { data } = await lastValueFrom(
      this.removeDevicesFromChannel.mutate({
        input: {
          channelId: this.channelId()!,
          deviceIds: this.selectedDevices.map((x) => x.id),
        },
      })
    );
    const channel = data?.removeDevicesFromChannel.channel;
    if (channel) {
      this.deviceIds =
        channel.devices.map((x) => x.id).filter((x) => !!x) || [];
      this.selectedDevices = [];
    }
  }

  /** somebody says that the current channel has been updated */
  selectUpdatedChannel(
    channel: UpdateChannelMutation['updateChannel']['channel']
  ) {
    if (channel) {
      this.decryptedChannelId.set(channel.id);
      this.initSelectedChannel(channel.id);
    }
  }

  splitDragEnd() {
    // this.deviceManageComponent.splitDragEnd();
    this.uiDataService.updateViewMode();
  }

  onExplorerSelectedRegion(event: string) {
    this.selectedPlaylistRegion.set(event);
  }
  clearRegionSelection() {
    this.selectedPlaylistRegion.set(null);
  }
}
