import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';

const registerLanguages = () => {
  registerLocaleData(localeSv);
};

export const languageUtil = {
  registerLanguages,
};
