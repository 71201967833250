import {
  MutationSetIsInitializingAppArgs,
  GetIsInitializingAppQuery,
  GetIsInitializingAppDocument,
} from '@designage/gql';
import { CustomResolver } from './common';
import { writeIsInitializingApp } from '@desquare/utils';

export const setIsInitializingApp: CustomResolver<
  any,
  MutationSetIsInitializingAppArgs
> = (_, { input }, { cache }) => {
  return writeIsInitializingApp(cache, input);
};
