import { createReducer, on, Action } from '@ngrx/store';
import { initialChannelState, IChannelState } from '@desquare/store/states';
import { channelActions } from '@desquare/store/actions';

const reducer = createReducer(
  initialChannelState,
  on(channelActions.switchChannel, (state, action) => ({
    ...state,
    channel: action.channel,
  }))
);

export function channelReducer(
  state: IChannelState | undefined,
  action: Action
) {
  return reducer(state, action);
}
