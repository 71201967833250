import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Organization, Profile } from '@designage/gql';
import { ProfileSelectionMode } from '@desquare/enums';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileListComponent } from '../profile-list/profile-list.component';
import { ProfileSelectionComponent } from '../profile-selection/profile-selection.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ProfileListComponent,
    ProfileSelectionComponent,
  ],
  selector: 'app-profile-selection-dialog',
  templateUrl: './profile-selection-dialog.component.html',
  styleUrls: ['./profile-selection-dialog.component.scss'],
})
export class ProfileSelectionDialogComponent {
  organization!: Organization;
  selectedProfiles!: Profile[];
  organizationProfiles!: Profile[];
  profileSelectionMode!: ProfileSelectionMode;
  profileSelected = false;

  constructor(public modal: NgbActiveModal) {}

  get enableProfileSwitcher() {
    return this.profileSelectionMode === ProfileSelectionMode.SWITCHER;
  }

  get enableProfileSelection() {
    return this.profileSelectionMode === ProfileSelectionMode.CHECKBOX;
  }

  get enableAddButton() {
    return this.profileSelected;
  }

  selectProfile(profile: Profile) {
    this.modal.close(profile);
  }

  onSave() {
    const profileIds = this.selectedProfiles.map((x) => x.id);
    this.modal.close({
      name: this.organization.name,
      title: this.organization.title,
      profileIds,
      id: this.organization.id,
    });
  }

  onProfileSelect(value: boolean) {
    this.profileSelected = value;
  }
}
