import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import packageJson from '../../../../../../package.json';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { InitializationLogoComponent } from './initialization-logo/initialization-logo.component';

gsap.registerPlugin(TextPlugin);
@Component({
  selector: 'designage-inititalization-page',
  standalone: true,
  imports: [CommonModule, InitializationLogoComponent],
  templateUrl: './inititalizationPage.component.html',
  styleUrls: ['./inititalizationPage.component.scss'],
  animations: [fadeInOnEnterAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InititalizationPageComponent implements AfterViewInit {
  @Output() initAnimDone = new EventEmitter<boolean>();

  version = packageJson.version;

  ngAfterViewInit(): void {
    this.loadingTextAnim();
    setTimeout(() => {
      this.initAnimDone.emit(true);
    }, 4000);
  }
  loadingTextAnim() {
    const tl = gsap.timeline();
    tl.to('#spinnerContainer', {
      delay: 2,
      duration: 1,
      opacity: 1,
      ease: 'power1',
    })
      .to('#loadingText', {
        delay: 2,
        duration: 0.25,
        opacity: 1,
        text: {
          value: 'loading...',
          newClass: 'fw-bold',
          type: 'diff',
        },
        ease: 'none',
      })
      .to('#loadingText', {
        delay: 4,
        duration: 0.25,
        text: {
          value: 'taking a while... 🧐',
          newClass: 'fw-bold',
          type: 'diff',
        },
        ease: 'none',
      })
      .to('#loadingText', {
        delay: 4,
        duration: 0.25,
        text: {
          value: 'hmm... 🤔',
          newClass: 'fw-bold',
          type: 'diff',
        },
        ease: 'none',
      })
      .to('#spinnerContainer', {
        delay: 4,
        duration: 1,
        opacity: 0,
      })
      .to('#loadingText', {
        delay: 1,
        duration: 2,
        text: {
          value:
            'There seem to be something wrong. 🤨 <br> Try to reload the page and see if the error persists, else feel free contact us on <b>support@designage.io</b>',
          type: 'diff',
        },
        ease: 'power1',
      });
  }
}
