<div class="d-flex flex-column h-100">
  <div class="mb-2 d-flex justify-content-between align-items-center">
    <h4 class="flex-grow-1">{{ 'SELECT_USERS' | translate }}</h4>
    <button
      class="btn waves-effect waves-light mt-2 me-2 btn-outline-primary"
      (click)="openInviteDialog()"
    >
      {{ 'INVITE' | translate }}
    </button>
  </div>

  <designage-data-table
    configId="user-selection"
    [data]="users"
    [columns]="desColumns"
    [columnSelector]="false"
    [customComponent]="[removeButton, mfaSecurity]"
    (rowClick)="onRowClick($event)"
    [rowActiveInSlidingPanel]="selectedUserId()"
  >
  </designage-data-table>
</div>
<ng-template #removeButton let-row let-removeUser="action">
  <span class="text-truncate">
    <button
      class="btn btn-sm btn-outline-danger"
      (click)="$event.stopPropagation(); onRemoveUserButtonClick(row)"
    >
      {{ 'REMOVE_USER' | translate }}
    </button>
  </span>
</ng-template>
<ng-template #mfaSecurity let-row>
  <span class="text-truncate">
    <ng-container *ngIf="mfaInfo(row) as mfaResponse">
      <!-- let-mfaResponse="mfaInfo(row)" -->
      <ng-container *ngIf="mfaResponse === 'MFA_ACTIVE'">
        <div class="text-truncate">
          <span class="badge bg-success me-2">{{ 'ACTIVE' | translate }} </span
          >{{ 'MFA_ACTIVE' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="mfaResponse === 'MFA_INACTIVE'">
        <div class="text-truncate">
          <span class="badge bg-warning me-2"
            >{{ 'INACTIVE' | translate }} </span
          >{{ 'MFA_INACTIVE' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="mfaResponse === 'MFA_NOT_LICENSED'">
        <div class="text-secondary text-truncate">
          {{ 'MFA_NOT_LICENSED' | translate }}
        </div>
      </ng-container>
      <ng-container *ngIf="mfaResponse === 'MFA_RESET'">
        <div class="text-secondary text-truncate">
          {{ 'MFA_RESET_PENDING' | translate }}
        </div>
      </ng-container>
    </ng-container>
  </span>
</ng-template>

<!-- Channel Manage Slide Panel -->
<router-outlet *ngIf="enableSlidePanel"></router-outlet>
