<table class="table table-sm">
  <tbody>
    <tr>
      <th style="min-width: 8rem">{{ 'SECURE_URL' | translate }}</th>
      <th class="d-flex" style="max-width: 22rem">
        <span class="text-truncate">
          {{ details?.secureUrl || ('NO_DATA' | translate) }}
        </span>
        <copy-to-clipboard [cbcontent]="details?.secureUrl"></copy-to-clipboard>
      </th>
    </tr>
    <tr>
      <th>{{ 'RESOURCE_TYPE' | translate }}</th>
      <th>{{ details?.resourceType || ('NO_DATA' | translate) }}</th>
    </tr>
    <tr>
      <th>{{ 'FORMAT' | translate }}</th>
      <th>{{ details?.format || ('NO_DATA' | translate) }}</th>
    </tr>
    <tr>
      <th>{{ 'FILE_SIZE' | translate }}</th>
      <th>{{ (details?.bytes | fileSize) || ('NO_DATA' | translate) }}</th>
    </tr>
    <br />
    <tr>
      <th>{{ 'WIDTH' | translate }}</th>
      <th>{{ details?.width || ('NO_DATA' | translate) }} px</th>
    </tr>
    <tr>
      <th>{{ 'HEIGHT' | translate }}</th>
      <th>{{ details?.height || ('NO_DATA' | translate) }} px</th>
    </tr>
    <tr [hidden]="hideDuration">
      <th>{{ 'DURATION' | translate }}</th>
      <th>
        {{
          formattedDuration
            ? (formattedDuration * 1000 | date: 'm:ss')
            : ('NO_DATA' | translate)
        }}
      </th>
    </tr>
    <br />
    <tr>
      <th>{{ 'CREATED_AT' | translate }}</th>
      <th>
        {{ (details?.createdAt | date: 'short') || ('NO_DATA' | translate) }}
      </th>
    </tr>
  </tbody>
</table>
