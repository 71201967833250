import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  GetAllProfilesForProfileSelectionGQL,
  GetAllProfilesForProfileSelectionQuery,
  Profile,
} from '@designage/gql';
import { CurrentUserService, ToasterService } from '@desquare/services';
import { SubSink } from 'subsink';
import {
  IDatatablePageChangeArgs,
  IDesignageDataTableColumns,
} from '@desquare/interfaces';
import { ExcludeMaybe } from '@desquare/types';
import { ApolloError } from '@apollo/client/errors';
import { domConstants } from '@desquare/constants';
import { CommonModule } from '@angular/common';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DesignageDataTableComponent,
    TableDateTimeComponent,
  ],
  selector: 'app-profile-selection',
  template: `<div
    class="container-fluid content-container-card profile-list-container"
  >
    <div class="overflow-auto h-100">
      <designage-data-table
        configId="profile-selection"
        [data]="profiles"
        [columns]="desColumns"
        [selectedRows]="selectedProfiles"
        [customComponent]="[createdAt, updatedAt]"
        (selectedRowsChange)="updateSelectedProfiles($event)"
        [showMultiSelect]="true"
        [loading]="loading"
      ></designage-data-table>

      <ng-template #createdAt let-row>
        <table-dateTime
          [data]="row.createdAt"
          [tooltipPipeArgs]="'medium'"
        ></table-dateTime>
      </ng-template>
      <ng-template #updatedAt let-row>
        <table-dateTime
          [data]="row.updatedAt"
          [tooltipPipeArgs]="'medium'"
        ></table-dateTime>
      </ng-template>

      <div [class.hidden]="loading || !hasNoData" class="col-12">
        <hr />
        <h4 class="unavailable-message">
          {{ 'NO_PROFILES_FOUND' | translate }}
        </h4>
      </div>
    </div>
  </div> `,
  styleUrls: ['./profile-selection.component.scss'],
})
export class ProfileSelectionComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private _selectedProfiles!: Profile[];

  @Input() organizationUid!: string;
  @Input() enableCheckBox = true;
  @Input()
  get selectedProfiles() {
    return this._selectedProfiles;
  }

  set selectedProfiles(profiles: Profile[]) {
    this._selectedProfiles = profiles;
  }

  @Output() selectedProfilesChange = new EventEmitter<Profile[]>();
  @Output() profileSelect = new EventEmitter<boolean>();

  loading!: boolean;
  page!: number;
  pageSize!: number;
  total!: number;
  searchText!: string;
  pageSizeOptions!: number[];
  externalPaging!: boolean;

  profiles!: ExcludeMaybe<
    GetAllProfilesForProfileSelectionQuery['profiles']
  >['results'];
  desColumns!: IDesignageDataTableColumns[];
  profileSearchFrom!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private getAllProfilesGQL: GetAllProfilesForProfileSelectionGQL,
    private currentUserService: CurrentUserService,
    private toasterService: ToasterService
  ) {}

  get hasNoData(): boolean {
    return !this.loading && this.total === 0;
  }

  ngOnInit() {
    this.initVariables();
    this.initForm();
    this.setFormState();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.loading = false;
    this.page = 1;
    this.pageSize = 10;
    this.total = 0;
    this.searchText = '';
    this.pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;
    this.externalPaging = true;
    this.pageSize = this.currentUserService.preferredPageSize;

    this.initColumns();
    this.getProfiles();
  }

  initForm() {
    this.profileSearchFrom = this.formBuilder.group({
      search: [null],
      pageSize: [this.pageSize],
    });
  }

  setFormState() {
    this.subs.sink =
      this.profileSearchFrom.controls.pageSize.valueChanges.subscribe(
        (value: number) => {
          this.pageSize = value;
          this.currentUserService.setPreferredPageSize(value);

          this.page = 1;
        }
      );
  }

  initColumns() {
    this.desColumns = [
      {
        fieldName: 'name',
        name: 'NAME',
        type: 'string',
        visible: 'mandatory',
        flex: '2',
      },
      {
        fieldName: 'updatedAt',
        name: 'LAST_UPDATED',
        type: 'template',
        templateRef: 'createdAt',
        visible: true,
      },
      {
        fieldName: 'createdAt',
        name: 'CREATED_ON',
        type: 'template',
        templateRef: 'createdAt',
        visible: true,
      },
    ];
    this.getProfiles();
  }

  getProfiles() {
    this.externalPaging = false;
    this.loading = true;
    this.subs.sink = this.getAllProfilesGQL
      .watch(
        {
          active: true,
          name: this.searchText,
        },
        {
          fetchPolicy: 'network-only',
        }
      )
      .valueChanges.subscribe({
        next: ({ data, loading }) => {
          const { profiles } = data;
          this.loading = loading;

          if (profiles) {
            this.profiles = profiles.results;
            const ownedProfiles = this.profiles.filter(
              (x) => x.organizationUid === this.organizationUid
            );
            const availableProfiles = this.profiles.filter(
              (x) => !x.organizationUid
            );
            this.profiles = [...ownedProfiles, ...availableProfiles];
            this.total = profiles.total;
          } else {
            this.total = 0;
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            this.toasterService.handleGqlError(gqlError);
          });

          this.loading = false;
          this.profiles = [];
        },
      });
  }

  onPageChange({ offset }: IDatatablePageChangeArgs) {
    this.page = offset;
    this.getProfiles();
  }

  search(value: string) {
    this.searchText = value;
    this.page = 1;
    this.getProfiles();
  }

  emitProfileChanges() {
    this.profileSelect.emit(true);
    this.selectedProfilesChange.emit(this.selectedProfiles);
  }

  updateSelectedProfiles(rows: Profile[]) {
    this.selectedProfiles = rows;
    this.emitProfileChanges();
  }
}
