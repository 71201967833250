<div class="container-fluid content-container-card device-list-container">
  <h4>{{ location?.name }}</h4>

  <nav ngbNav #nav="ngbNav" class="nav-tabs">
    <ng-container ngbNavItem>
      <a ngbNavLink>{{ 'DETAILS' | translate }}</a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="locationDetails"></ng-container>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a ngbNavLink>{{ 'CHANNELS' | translate }}</a>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="locationChannels"></ng-container>
      </ng-template>
    </ng-container>
  </nav>

  <div [ngbNavOutlet]="nav"></div>

  <ng-template #locationDetails>
    <app-location-form
      [formId]="'locationForm'"
      [location]="location"
      (valid)="isFormValid = $event"
      (pristine)="isFormPristine = $event"
      (values)="setLocation($event)"
      (loaderMessage)="loaderMessage = $event"
    ></app-location-form>
    <div class="row controls">
      <div class="col-12 text-end">
        <button
          class="deactivate-btn btn btn-outline-danger waves-effect waves-light mt-2"
          class="btn btn-outline-danger mt-2 mx-2"
          (click)="openDeleteLocationDialog()"
        >
          <i class="mdi mdi-delete"></i>
          {{ 'DELETE' | translate }}
        </button>

        <button
          [disabled]="!saveEnabled"
          [class.btn-outline-success]="isFormPristine"
          [class.btn-success]="!isFormPristine"
          class="btn waves-effect waves-light mt-2"
          (click)="saveLocation()"
        >
          <i class="mdi mdi-content-save"></i>
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #locationChannels>
    <h4 class="py-2">
      {{ 'CHANNELS_CONNECTED_TO_THIS_LOCATION' | translate }}
    </h4>
    <app-channel-list
      [hideHeader]="true"
      [hideAddChannelButton]="true"
      [hideLocation]="true"
      [hiddenButtons]="false"
      [locationId]="location.id"
      [relativeRoute]="false"
    ></app-channel-list>
  </ng-template>
</div>
