import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  EncryptionService,
  ToasterService,
  CurrentUserService,
  ProfileService,
} from '@desquare/services';
import {
  GetProfilesForProfileListGQL,
  Profile,
  GetUserProfilesForProfileListGQL,
  ProfilesForProfileListFragment,
  GetOrganizationProfilesForProfileListGQL,
} from '@designage/gql';
import {
  IDatatablePageChangeArgs,
  IDatatableRowActivateArgs,
  IDesignageDataTableColumns,
} from '@desquare/interfaces';
import { DatatableRowActivationType, StatusFilters } from '@desquare/enums';
import { FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { SubSink } from 'subsink';
import { domConstants } from '@desquare/constants';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    DesignageDataTableComponent,
    TableDateTimeComponent,
  ],
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent implements OnInit {
  @Input() isProfileSelection = false;
  @Input() organizationId!: string;
  @Input() redirectOnSelect = true;

  @Output() selectedProfile = new EventEmitter<Profile>();
  @Output() profileSelect = new EventEmitter<IDatatableRowActivateArgs>();

  private subs = new SubSink();

  loaderMessage!: string;
  loading!: boolean;
  desColumns!: IDesignageDataTableColumns[];
  profiles!: Observable<ProfilesForProfileListFragment>;
  total = 0;
  statusFilters = Object.keys(StatusFilters);
  selectedStatusFilter: StatusFilters = StatusFilters.ACTIVE;
  searchText = '';
  profileSearchForm!: FormGroup;
  page = 1;
  pageSize = 10;
  pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;

  constructor(
    private profileService: ProfileService,
    private currentUserService: CurrentUserService,
    private getProfilesGQL: GetProfilesForProfileListGQL,
    private getUserProfilesGQL: GetUserProfilesForProfileListGQL,
    private getOrganizationProfilesGQL: GetOrganizationProfilesForProfileListGQL,
    private encryptionService: EncryptionService,
    private toasterService: ToasterService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  get hasNoData(): boolean {
    return !this.loading && this.total === 0;
  }

  get statusFilterValue() {
    return this.selectedStatusFilter === StatusFilters.ACTIVE
      ? true
      : this.selectedStatusFilter === StatusFilters.DEACTIVATED
      ? false
      : null;
  }

  get showAddProfileButton() {
    // TODO: ask what is !this.organizationId for (in this context)

    return this.currentUserService.canManageProfiles;
    // return this.currentUserService.canManageProfiles && !this.organizationId;
  }

  get enableProfileSelect() {
    return this.isProfileSelection;
  }

  get showProfileFilters() {
    return this.isProfileSelection;
  }

  ngOnInit() {
    this.initVariables();
    this.initSubscriptions();
  }

  initVariables() {
    this.desColumns = [
      {
        fieldName: 'name',
        name: 'NAME',
        type: 'string',
        visible: 'mandatory',
        flex: '2',
      },
      {
        fieldName: 'owner.displayName',
        name: 'OWNER',
        type: 'string',
        visible: 'mandatory',
        flex: '2',
      },
      {
        fieldName: 'users.length',
        name: 'NUMBER_OF_USERS',
        type: 'string',
        visible: true,
      },
      {
        fieldName: 'updatedAt',
        name: 'LAST_UPDATED',
        type: 'template',
        templateRef: 'createdAt',
        visible: true,
      },
      {
        fieldName: 'createdAt',
        name: 'CREATED_ON',
        type: 'template',
        templateRef: 'createdAt',
        visible: true,
      },
      {
        fieldName: 'active',
        name: 'STATUS',
        type: 'template',
        templateRef: 'statusBadge',
        visible: true,
      },
    ];

    this.getProfiles();
  }

  initSubscriptions() {
    this.subs.sink = this.profileService.profileListChanged.subscribe(() => {
      this.getProfiles();
    });
  }

  getProfiles() {
    if (!this.loading) {
      this.loading = true;
      this.loaderMessage = 'FETCHING_PROFILES';

      // TODO: organization is probably obsolete, reseller case
      if (this.organizationId) {
        this.getOrganizationProfiles();
      } else if (this.currentUserService.isSuperAdmin) {
        this.getPaginatedProfiles();
      } else {
        this.getUserProfiles();
      }
    }
  }

  getPaginatedProfiles() {
    if (this.currentUserService.isSuperAdmin) {
      this.profiles = this.getProfilesGQL
        .watch(
          {
            page: this.page - 1,
            pageSize: this.pageSize,
            active: this.statusFilterValue,
            name: this.searchText,
          },
          {
            fetchPolicy: 'cache-and-network',
          }
        )
        .valueChanges.pipe(
          map(({ loading, data: { profiles } }) => {
            this.loading = loading;
            this.total = profiles.total;
            return profiles;
          }),
          catchError((error) => {
            this.toasterService.error('UNKNOWN_ERROR');
            this.loading = false;
            return of({
              total: 0,
              results: [],
            } as ProfilesForProfileListFragment);
          })
        );
    }
  }
  getUserProfiles() {
    if (this.currentUserService.currentUser) {
      this.profiles = this.getUserProfilesGQL
        .watch(
          {
            userId: this.currentUserService.currentUser.id,
            page: this.page - 1,
            pageSize: this.pageSize,
            active: this.statusFilterValue,
            name: this.searchText,
          },
          {
            fetchPolicy: 'cache-and-network',
          }
        )
        .valueChanges.pipe(
          map(({ loading, data: { user } }) => {
            this.loading = loading;
            if (user?.profiles) {
              const { profiles } = user;
              this.total = profiles.total;
              return profiles;
            } else {
              return {
                total: 0,
                results: [],
              } as ProfilesForProfileListFragment;
            }
          }),
          catchError((error) => {
            this.toasterService.error('UNKNOWN_ERROR');
            this.loading = false;
            return of({
              total: 0,
              results: [],
            } as ProfilesForProfileListFragment);
          })
        );
    }
  }

  getOrganizationProfiles() {
    if (this.organizationId) {
      this.profiles = this.getOrganizationProfilesGQL
        .watch(
          {
            page: this.page - 1,
            pageSize: this.pageSize,
            organizationId: this.organizationId,
            active: this.statusFilterValue,
            name: this.searchText,
          },
          {
            fetchPolicy: 'cache-and-network',
          }
        )
        .valueChanges.pipe(
          map(({ loading, data: { organization } }) => {
            this.loading = loading;
            if (organization?.profiles) {
              const { profiles } = organization;
              this.total = profiles.total;
              return profiles;
            } else {
              return {
                total: 0,
                results: [],
              } as ProfilesForProfileListFragment;
            }
          }),
          catchError((error) => {
            this.toasterService.error('UNKNOWN_ERROR');
            this.loading = false;
            return of({
              total: 0,
              results: [],
            } as ProfilesForProfileListFragment);
          })
        );
    }
  }

  onPageChange({ offset }: IDatatablePageChangeArgs) {
    this.page = offset;
    this.getProfiles();
  }

  navigateToProfile({ type, row }: IDatatableRowActivateArgs) {
    if (type === DatatableRowActivationType.CLICK && this.redirectOnSelect) {
      const profile: Profile = row;

      if (profile?.id) {
        if (this.isProfileSelection) {
          this.selectedProfile.emit(profile);
        } else {
          const id = this.encryptionService.encrypt(profile.id);
          this.router.navigate(['/profile/manage', id]);
        }
      }
    }
  }

  setSelectedStatusFilter(status: StatusFilters) {
    this.page = 1;
    this.selectedStatusFilter = status;
    this.getProfiles();
  }

  search(value: string) {
    this.searchText = value;
    this.page = 1;
    this.getProfiles();
  }
}
