import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  inject,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  CurrentUserService,
  ToasterService,
  EncryptionService,
  UiMode,
  ResponsiveUiService,
  SessionService,
  sessionUserProfiles,
} from '@desquare/services';
import { environment } from '@desquare/environments';
import { Profile } from '@designage/gql';
import { SubSink } from 'subsink';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileSelectionDialogComponent } from '@designage/app/profile/profile-selection-dialog/profile-selection-dialog.component';
import { ProfileActivationDialogComponent } from '@designage/app/profile/profile-activation-dialog/profile-activation-dialog.component';
import { Roles, ProfileSelectionMode } from '@desquare/enums';
import { Observable } from 'rxjs';
import {
  BreadcrumbComponent,
  BreadcrumbItemDirective,
  BreadcrumbService,
} from 'xng-breadcrumb';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationListComponent } from '../notification-list/notification-list.component';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    AsyncPipe,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    NgbDropdownModule,
    TranslateModule,
    NotificationListComponent,
  ],
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private roleValues: string[] = Object.values(Roles);

  languages!: Array<{
    id: number;
    flag?: string;
    name: string;
  }>;
  openMobileMenu!: boolean;
  profilePicture = environment.assets.placeholderImage;
  appLogo = environment.assets.appLogo;
  smallLogo = environment.assets.logoSmall;

  get displayedProfiles() {
    const pList = sessionUserProfiles() || [];
    return pList.filter((x) => x.id !== this.session.profile()?.id);
  }

  get displayedProfiles2() {
    return (
      this.session
        .profiles()
        ?.filter((x) => x.id !== this.session.profile()?.id) || []
    );
  }

  get totalProfiles() {
    return this.session.profiles()?.length || 0;
  }

  loading = false;
  loaderMessage = '';

  uiMode$!: Observable<UiMode>;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    private router: Router,
    public session: SessionService,
    private currentUserService: CurrentUserService,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private encryptionService: EncryptionService,
    public responsiveUiService: ResponsiveUiService
  ) {}

  get canManageProfiles() {
    return this.currentUserService.canManageProfiles;
  }

  get showAddProfileButton() {
    return (
      this.currentUserService.isSuperAdmin ||
      (this.currentUserService.isProfileManager && this.totalProfiles === 0) ||
      this.currentUserService.canManageProfiles
    );
  }

  get activeUserProfilePicture() {
    return (
      this.session.user()?.profilePicture?.secureUrl ||
      this.session.user()?.auth0Profile?.picture ||
      environment.assets.placeholderImage
    );
  }

  get userRole() {
    return this.roleValues.find((value) =>
      this.session
        .user()
        ?.auth0Profile?.roles.map((role) => role.name)
        .includes(value)
    );
  }

  ngOnInit() {
    this.openMobileMenu = false;
    this.uiMode$ = this.currentUserService.getUiMode();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  switchProfile(profile: Profile) {
    if (profile.active) {
      this.toasterService.success('SWITCH_PROFILE_MESSAGE', undefined, {
        profile: profile.name,
      });
      this.currentUserService.setActiveProfile(profile);
      window.location.href = '/';
    } else if (!profile.active && this.canManageProfiles) {
      this.showProfileReactivationDialog(profile);
    } else {
      this.toasterService.error('PROFILE_UNAVAILABLE');
    }
  }

  showProfileReactivationDialog(profile: Profile) {
    const modalRef = this.modalService.open(ProfileActivationDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.profile = profile;
    modalRef.result.then(
      (value: Profile) => {
        this.switchProfile(value);
      },
      () => {}
    );
  }

  showProfileDialog() {
    const modalRef = this.modalService.open(ProfileSelectionDialogComponent, {
      size: 'xl',
      backdrop: 'static',
    });
    modalRef.componentInstance.profileSelectionMode =
      ProfileSelectionMode.SWITCHER;
    modalRef.result.then(
      (profile: Profile) => {
        this.switchProfile(profile);
      },
      () => {}
    );
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Navigate to user settings page
   */
  settings() {
    if (this.session.user()?.id) {
      const id = this.encryptionService.encrypt(this.session.user()?.id || '');
      this.router.navigate(['/user/manage/', id]);
    }
  }

  /**
   * Logout the user
   */
  logout() {
    this.session.logout('cms');
  }

  switchUiMode(mode: boolean) {
    this.currentUserService.setUiMode({ simpleUiMode: mode });
  }
}
