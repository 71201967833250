import { Location } from '@designage/gql';
import { ApolloError } from '@apollo/client/errors';
import { IOperationStatus } from './app.state';

export interface ILocationState {
  deleteLocationStatus?: IOperationStatus;
  error?: ApolloError;
  locations?: Location[];
}

export const initialLocationState: ILocationState = {};
