<!-- DEBUG -->
<!-- <pre style="color: white;"> {{ layouts | json }} </pre> -->

<!-- layouts table -->
<div class="h-100">
  <designage-data-table
    configId="layout-list"
    [columns]="desColumns"
    [data]="layouts"
    (rowClick)="onRowClick($event)"
    [rowActiveInSlidingPanel]="selectedLayoutaId()"
  ></designage-data-table>
</div>

<!-- Media Manage Panel -->
<router-outlet *ngIf="enableSlidePanel"></router-outlet>
