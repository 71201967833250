import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { CurrentUserService } from '@desquare/services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditorGuard implements CanLoad {
  constructor(private currentUserService: CurrentUserService) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.currentUserService.isSuperAdmin;
  }
}
