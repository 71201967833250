import { createAction, props } from '@ngrx/store';
import { DevicesForDeviceListFragment, Maybe } from '@designage/gql';

export enum DeviceActionTypes {
  SWITCH_DEVICE = 'SWITCH_DEVICE',
}

const switchDevice = createAction(
  DeviceActionTypes.SWITCH_DEVICE,
  props<{ device: DevicesForDeviceListFragment | undefined }>()
);

export const deviceActions = {
  switchDevice,
};
