import { Component, Output, EventEmitter } from '@angular/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { User } from '@designage/gql';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, NgIf, TranslateModule],
  selector: 'app-user-deactivate-dialog',
  templateUrl: './user-deactivate-dialog.component.html',
  styleUrls: ['./user-deactivate-dialog.component.scss'],
})
export class UserDeactivateDialogComponent {
  @Output() close = new EventEmitter<boolean>();

  user!: Maybe<User>;

  get hasProfile() {
    const profile = this.user?.profiles?.results.find(
      (x) => x.owner?.id === this.user?.id
    );
    return !!profile;
  }

  onCancel() {
    this.close.emit(false);
  }

  onAccept() {
    this.close.emit(true);
  }
}
