import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SlidePanelComponent } from '@designage/app/shared/slide-panel/slide-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { MediaManageComponent } from '../media-manage/media-manage.component';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, TranslateModule, MediaManageComponent],
  selector: 'designage-media-manage-panel',
  template: `
    <app-slide-panel
      (closePanel)="closeMediaManagePanel()"
      [fullscreenTooltip]="'TOGGLE_PAGE_BEHIND_PANEL_TOOLTIP' | translate"
      [closePanelTooltip]="'CLOSE_PANEL' | translate"
      [openWidth]="50"
      [fullscreen]="false"
      [panelContent]="'mediaList'"
    >
      <app-media-manage></app-media-manage>
    </app-slide-panel>
  `,
  styleUrls: ['./media-manage-panel.component.scss'],
})
export class MediaManagePanelComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  closeMediaManagePanel() {
    this.router.navigate(['media/']);
  }
}
