import { __decorate, __param, __metadata } from 'tslib';
import { InjectionToken, Inject, PLATFORM_ID, Optional, NgZone, ɵɵdefineInjectable, ɵɵinject, Injectable, Input, Component, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { Subject, of, timer, combineLatest, Observable } from 'rxjs';
import { take, map, tap, startWith, switchMap, shareReplay } from 'rxjs/operators';

class LoadingBarState {
    constructor(config = {}) {
        this.config = config;
        this.state = {
            action: null,
            value: 0,
            initialValue: 0,
        };
        this.requests = null;
        this.disabled = false;
        this.stream$ = new Subject();
        this._value$ = null;
        this.timer$ = (s) => {
            let state$ = of(s);
            switch (s.action) {
                case 'start':
                case 'increment':
                case 'set': {
                    if (s.action === 'start' && this.config.latencyThreshold === 0 && s.value === 0) {
                        s.value = s.initialValue;
                    }
                    if (this.requests > 0) {
                        state$ = timer(this.config.latencyThreshold, 250).pipe(map((t) => (Object.assign(Object.assign({}, s), { value: t === 0 ? this.state.value || s.initialValue : this._increment() }))));
                    }
                    break;
                }
                case 'complete':
                case 'stop': {
                    // Attempt to aggregate any start/complete calls within 500ms:
                    state$ =
                        s.value === 0
                            ? of(Object.assign({}, s))
                            : timer(0, 500).pipe(take(2), map((t) => ({ value: t === 0 ? 100 : 0 })));
                    break;
                }
            }
            return state$.pipe(map((next) => (Object.assign(Object.assign({}, next), { action: 'set' }))), tap((next) => this.next(next, false)));
        };
        this.config = Object.assign({ latencyThreshold: 0 }, config);
    }
    get value$() {
        if (this._value$) {
            return this._value$;
        }
        return (this._value$ = this.stream$.asObservable().pipe(startWith(this.state), switchMap((s) => this.timer$(s)), shareReplay(), map((s) => s.value)));
    }
    start(initialValue = 2) {
        if (this.disabled) {
            return;
        }
        this.next({ action: 'start', initialValue });
    }
    stop() {
        this.next({ action: 'stop' });
    }
    complete() {
        this.next({ action: 'complete' });
    }
    disable() {
        this.disabled = true;
    }
    set(value) {
        this.next({ action: 'set', value });
    }
    increment(value = 0) {
        this.next({ action: 'increment', value });
    }
    next(state, emitEvent = true) {
        switch (state.action) {
            case 'start':
                this.requests = (this.requests || 0) + 1;
                break;
            case 'complete':
                this.requests = (this.requests || 1) - 1;
                if (this.requests > 0) {
                    return;
                }
                break;
            case 'stop':
                this.requests = 0;
                break;
            case 'increment':
                state.value = this._increment(state.value);
                break;
        }
        this.state = Object.assign(Object.assign(Object.assign({}, this.state), { action: null }), state);
        if (emitEvent) {
            this.stream$.next(this.state);
        }
    }
    _increment(rnd = 0) {
        const stat = this.state.value;
        if (stat >= 99) {
            rnd = 0;
        }
        if (rnd === 0) {
            if (stat >= 0 && stat < 25) {
                // Start out between 3 - 6% increments
                rnd = Math.random() * (5 - 3 + 1) + 3;
            }
            else if (stat >= 25 && stat < 65) {
                // increment between 0 - 3%
                rnd = Math.random() * 3;
            }
            else if (stat >= 65 && stat < 90) {
                // increment between 0 - 2%
                rnd = Math.random() * 2;
            }
            else if (stat >= 90 && stat < 99) {
                // finally, increment it .5 %
                rnd = 0.5;
            }
            else {
                // after 99%, don't increment:
                rnd = 0;
            }
        }
        return rnd + stat;
    }
}

const LOADING_BAR_CONFIG = new InjectionToken('LOADING_BAR_CONFIG');

let LoadingBarService = class LoadingBarService {
    constructor(platformId, config = {}, zone) {
        this.platformId = platformId;
        this.config = config;
        this.zone = zone;
        this.refs = {};
        this.streams$ = new Subject();
        this.value$ = this.streams$.asObservable().pipe(startWith(null), switchMap(() => combineLatest(...Object.keys(this.refs).map((s) => this.refs[s].value$))), runInZone(this.zone), map((v) => Math.max(0, ...v)));
    }
    /** @deprecated use `value$` instead. */
    get progress$() {
        return this.value$;
    }
    /** @deprecated use `useRef` instead. */
    start(initialValue = 2) {
        this.useRef().start(initialValue);
    }
    /** @deprecated use `useRef` instead. */
    set(value) {
        this.useRef().set(value);
    }
    /** @deprecated use `useRef` instead. */
    increment(value) {
        this.useRef().increment(value);
    }
    /** @deprecated use `useRef` instead. */
    complete() {
        this.useRef().complete();
    }
    /** @deprecated use `useRef` instead. */
    stop() {
        this.useRef().stop();
    }
    useRef(id = 'default') {
        if (!this.refs[id]) {
            this.refs[id] = new LoadingBarState(this.config);
            this.streams$.next();
            if (!isPlatformBrowser(this.platformId)) {
                this.refs[id].disable();
            }
        }
        return this.refs[id];
    }
};
LoadingBarService.ctorParameters = () => [
    { type: Object, decorators: [{ type: Inject, args: [PLATFORM_ID,] }] },
    { type: undefined, decorators: [{ type: Optional }, { type: Inject, args: [LOADING_BAR_CONFIG,] }] },
    { type: NgZone, decorators: [{ type: Optional }] }
];
LoadingBarService.ɵprov = ɵɵdefineInjectable({ factory: function LoadingBarService_Factory() { return new LoadingBarService(ɵɵinject(PLATFORM_ID), ɵɵinject(LOADING_BAR_CONFIG, 8), ɵɵinject(NgZone, 8)); }, token: LoadingBarService, providedIn: "root" });
LoadingBarService = __decorate([
    Injectable({ providedIn: 'root' }),
    __param(0, Inject(PLATFORM_ID)),
    __param(1, Optional()), __param(1, Inject(LOADING_BAR_CONFIG)),
    __param(2, Optional()),
    __metadata("design:paramtypes", [Object, Object, NgZone])
], LoadingBarService);
// https://stackoverflow.com/a/57452361/1406096
function runInZone(zone) {
    if (!zone) {
        return (source) => source;
    }
    return (source) => new Observable((observer) => source.subscribe((value) => zone.run(() => observer.next(value)), (e) => zone.run(() => observer.error(e)), () => zone.run(() => observer.complete())));
}

let LoadingBarComponent = class LoadingBarComponent {
    constructor(loader) {
        this.loader = loader;
        this.includeSpinner = true;
        this.includeBar = true;
        this.fixed = true;
        this.color = '#29d';
    }
    get value$() {
        return this.ref ? this.loader.useRef(this.ref).value$ : this.loader.value$;
    }
};
LoadingBarComponent.ctorParameters = () => [
    { type: LoadingBarService }
];
__decorate([
    Input(),
    __metadata("design:type", Object)
], LoadingBarComponent.prototype, "includeSpinner", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], LoadingBarComponent.prototype, "includeBar", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], LoadingBarComponent.prototype, "fixed", void 0);
__decorate([
    Input(),
    __metadata("design:type", Object)
], LoadingBarComponent.prototype, "color", void 0);
__decorate([
    Input(),
    __metadata("design:type", Number)
], LoadingBarComponent.prototype, "value", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LoadingBarComponent.prototype, "ref", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LoadingBarComponent.prototype, "height", void 0);
__decorate([
    Input(),
    __metadata("design:type", String)
], LoadingBarComponent.prototype, "diameter", void 0);
LoadingBarComponent = __decorate([
    Component({
        selector: 'ngx-loading-bar',
        template: `
    <ng-container *ngIf="value != null ? value : (value$ | async) as progress">
      <div *ngIf="includeSpinner" class="ngx-spinner">
        <div [style.width]="diameter" [style.height]="diameter" class="ngx-spinner-icon"></div>
      </div>
      <div
        *ngIf="includeBar"
        class="ngx-bar"
        [style.background]="color"
        [style.height]="height"
        [style.width]="progress + '%'"
      ></div>
    </ng-container>
  `,
        preserveWhitespaces: false,
        changeDetection: ChangeDetectionStrategy.OnPush,
        encapsulation: ViewEncapsulation.Emulated,
        host: {
            '[attr.fixed]': 'fixed',
            '[style.color]': 'color',
        },
        styles: [":host{position:relative;display:block;pointer-events:none}:host .ngx-spinner{transition:350ms linear;display:block;position:absolute;top:5px;left:0}:host .ngx-spinner .ngx-spinner-icon{width:14px;height:14px;border:2px solid transparent;border-top-color:inherit;border-left-color:inherit;border-radius:50%;-webkit-animation:.4s linear infinite loading-bar-spinner;animation:.4s linear infinite loading-bar-spinner}:host .ngx-bar{transition:width 350ms;position:absolute;top:0;left:0;width:100%;height:2px;border-bottom-right-radius:1px;border-top-right-radius:1px}[dir=rtl] :host .ngx-bar{right:0;left:unset}:host[fixed=true]{z-index:10002}:host[fixed=true] .ngx-bar{position:fixed}:host[fixed=true] .ngx-spinner{position:fixed;top:10px;left:10px}[dir=rtl] :host[fixed=true] .ngx-spinner{right:10px;left:unset}@-webkit-keyframes loading-bar-spinner{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}@keyframes loading-bar-spinner{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}"]
    }),
    __metadata("design:paramtypes", [LoadingBarService])
], LoadingBarComponent);

let LoadingBarModule = class LoadingBarModule {
};
LoadingBarModule = __decorate([
    NgModule({
        imports: [CommonModule],
        declarations: [LoadingBarComponent],
        exports: [LoadingBarComponent],
    })
], LoadingBarModule);

/**
 * Generated bundle index. Do not edit.
 */

export { LOADING_BAR_CONFIG, LoadingBarComponent, LoadingBarModule, LoadingBarService };

