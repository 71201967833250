<app-loader [message]="loaderMessage" *ngIf="loading"></app-loader>
<div
  *ngIf="!loading"
  class="pt-3 container-fluid content-container-card manage-media-container"
>
  <div class="row">
    <div
      class="text-truncate d-flex align-items-center justify-content-between sliding-panel-header"
    >
      <label class="d-inline pointer-text">
        {{ media?.name || ('NO_DATA' | translate) }}
      </label>
    </div>
  </div>
  <div class="card-box p-0">
    <div class="media-manage-grid">
      <div class="text-center">
        <div class="mt-3" *ngIf="isVideo && isMetadataAvailable">
          <cl-media
            loading="lazy"
            mediaType="video"
            [public-id]="media.publicId"
            ngbTooltip="{{ title | translate }}"
            class="cl-media-video"
          ></cl-media>
        </div>
        <img
          *ngIf="isRaw || !isMetadataAvailable"
          [src]="mediaUrl"
          alt="{{ 'IMAGE' | translate }}"
          class="img-fluid screenshot mh-60 p-2"
          ngbTooltip="{{ title | translate }}"
        />
        <cl-media
          *ngIf="isImage && isMetadataAvailable"
          loading="lazy"
          mediaType="image"
          [public-id]="media.publicId"
          ngbTooltip="{{ title | translate }}"
          class="img-fluid screenshot mh-60 p-2 cl-media-image"
        ></cl-media>
      </div>
      <div class="p-3">
        <div class="form-group">
          <ul class="nav nav-pills navtab-bg">
            <li class="nav-item">
              <a
                (click)="currentTab = tabs.SETTINGS"
                [class.active]="currentTab === tabs.SETTINGS"
                data-toggle="tab"
                aria-expanded="true"
                class="nav-link"
              >
                <i class="mdi mdi-settings-outline me-1"></i>
                {{ 'SETTINGS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                (click)="currentTab = tabs.DETAILS"
                [class.active]="currentTab === tabs.DETAILS"
                data-toggle="tab"
                aria-expanded="true"
                class="nav-link"
              >
                <i class="mdi mdi-settings-outline me-1"></i>
                {{ 'DETAILS' | translate }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12">
          <app-media-settings-form
            [hidden]="currentTab !== tabs.SETTINGS"
            *ngIf="currentTab == tabs.SETTINGS"
            (loaderMessage)="loaderMessage = $event"
            (loading)="loading = $event"
            (updatedMedia)="media = $event"
            [media]="media"
            [downloadLink]="downloadLink"
          ></app-media-settings-form>
          <app-media-meta-data
            *ngIf="currentTab == tabs.DETAILS"
            (loaderMessage)="loaderMessage = $event"
            (loading)="loading = $event"
            [details]="media?.metadata"
          ></app-media-meta-data>
        </div>
      </div>
    </div>
  </div>
</div>
