import { CustomResolver } from './common';
import {
  GetSelectedDeviceQuery,
  GetSelectedDeviceDocument,
  MutationSetSelectedDeviceArgs,
} from '@designage/gql';

export const setSelectedDevice: CustomResolver<
  any,
  MutationSetSelectedDeviceArgs
> = (_, { input }, { cache }) => {
  /*
  const data = cache.readQuery<GetSelectedDeviceQuery>({
    query: GetSelectedDeviceDocument,
  });

  if (data) {
    data.selectedDevice = input;
  }*/

  const data: GetSelectedDeviceQuery = {
    selectedDevice: input,
  };

  cache.writeQuery({
    query: GetSelectedDeviceDocument,
    data,
  });

  return input;
};
