const PUBLIC_API_ENDPOINTS = [
  './assets',
  '/playlists/device/',
  '/layout/device/',
  '/layout/name/',
  '/profile/devices/',
  '/device/id',
  '/device/serial',
];
const PUBLIC_APP_ROUTES = ['/register', '/emulate-device', '/export-device', '/device-preview'];

export const routeConstants = {
  PUBLIC_API_ENDPOINTS,
  PUBLIC_APP_ROUTES,
};
