<!-- ========== Left Sidebar Start ========== -->
<div
  [class.collapsed]="
    isSidebarCollapsed() && !responsiveUiService.isMobileDevice()
  "
>
  <div
    class="left-side-menu"
    [class.vh-100]="!responsiveUiService.isMobileDevice()"
    [class.justify-content-start]="responsiveUiService.isMobileDevice()"
  >
    @if(responsiveUiService.isMobileDevice()){
    <div class="hamburger">
      <mat-accordion class="d-block vw-100">
        <mat-expansion-panel
          class="px-0"
          [expanded]="hamburgerOpen"
          (opened)="hamburgerOpen = true"
          (closed)="hamburgerOpen = false"
          hideToggle
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="hamburger-icon ri-menu-line"></i>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngTemplateOutlet="sidebarMenu"></ng-container>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    } @else {
    <!-- LOGO -->
    <div class="logo-box">
      <a [routerLink]="['/']" class="logo text-center">
        <span class="logo-lg px-3">
          <!-- <img src="assets/images/designage_logo.svg" alt="" height="20" /> -->
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 472 61"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="
              fill-rule: evenodd;
              clip-rule: evenodd;
              stroke-linejoin: round;
              stroke-miterlimit: 2;
            "
          >
            <path
              d="M7.411,0.965l15.406,0.08c8.05,0 15.128,2.801 21.235,8.404c6.107,5.602 9.16,12.491 9.16,20.666c0,8.175 -2.984,15.185 -8.952,21.028c-5.968,5.844 -13.199,8.765 -21.693,8.765l-15.239,0c-3.386,0 -5.496,-0.67 -6.329,-2.01c-0.666,-1.126 -0.999,-2.841 -0.999,-5.147l0,-44.71c0,-1.286 0.042,-2.224 0.125,-2.814c0.083,-0.59 0.347,-1.287 0.791,-2.091c0.777,-1.447 2.942,-2.171 6.495,-2.171Zm15.406,44.71c3.997,0 7.606,-1.434 10.826,-4.302c3.22,-2.868 4.83,-6.473 4.83,-10.816c0,-4.342 -1.569,-7.974 -4.705,-10.896c-3.137,-2.922 -6.815,-4.382 -11.034,-4.382l-7.994,0l0,30.396l8.077,0Z"
              style="fill: #fff; fill-rule: nonzero"
            />
            <path
              d="M73.365,45.755l25.648,0c1.277,0 2.235,0.041 2.873,0.121c0.638,0.08 1.374,0.335 2.207,0.764c1.499,0.751 2.248,2.841 2.248,6.272c0,3.753 -1.027,5.951 -3.081,6.594c-0.944,0.322 -2.387,0.483 -4.33,0.483l-32.977,0c-3.886,0 -6.162,-1.019 -6.828,-3.056c-0.333,-0.858 -0.5,-2.225 -0.5,-4.101l0,-44.71c0,-2.734 0.527,-4.597 1.582,-5.589c1.055,-0.992 3.054,-1.488 5.996,-1.488l32.81,0c1.277,0 2.235,0.041 2.873,0.121c0.638,0.08 1.374,0.335 2.207,0.764c1.499,0.75 2.248,2.841 2.248,6.272c0,3.753 -1.027,5.951 -3.081,6.594c-0.944,0.322 -2.387,0.483 -4.33,0.483l-25.565,0l0,8.121l16.488,0c1.277,0 2.234,0.041 2.873,0.121c0.638,0.08 1.374,0.335 2.207,0.764c1.498,0.75 2.248,2.841 2.248,6.272c0,3.753 -1.055,5.951 -3.164,6.594c-0.944,0.322 -2.388,0.483 -4.331,0.483l-16.321,0l0,8.121Z"
              style="fill: #fff; fill-rule: nonzero"
            />
            <path
              d="M112.67,51.706c-1.443,-1.715 -2.165,-3.257 -2.165,-4.624c0,-1.367 1.193,-3.176 3.581,-5.428c1.388,-1.286 2.859,-1.93 4.413,-1.93c1.555,0 3.72,1.341 6.496,4.021c0.777,0.911 1.887,1.783 3.331,2.614c1.443,0.83 2.775,1.246 3.997,1.246c5.163,0 7.744,-2.037 7.744,-6.112c0,-1.233 -0.708,-2.264 -2.123,-3.095c-1.416,-0.831 -3.179,-1.434 -5.288,-1.81c-2.11,-0.375 -4.386,-0.978 -6.829,-1.809c-2.442,-0.831 -4.719,-1.809 -6.828,-2.935c-2.11,-1.126 -3.872,-2.908 -5.288,-5.348c-1.416,-2.439 -2.124,-5.374 -2.124,-8.805c0,-4.718 1.819,-8.832 5.455,-12.343c3.636,-3.512 8.591,-5.268 14.864,-5.268c3.331,0 6.371,0.416 9.119,1.247c2.748,0.831 4.649,1.675 5.704,2.533l2.082,1.528c1.721,1.554 2.581,2.868 2.581,3.94c0,1.072 -0.666,2.546 -1.998,4.423c-1.888,2.68 -3.831,4.02 -5.829,4.02c-1.166,0 -2.61,-0.536 -4.331,-1.608c-0.166,-0.107 -0.485,-0.375 -0.957,-0.804c-0.472,-0.429 -0.902,-0.777 -1.291,-1.045c-1.166,-0.697 -2.651,-1.046 -4.455,-1.046c-1.804,0 -3.303,0.416 -4.497,1.247c-1.194,0.831 -1.79,1.983 -1.79,3.457c0,1.475 0.707,2.668 2.123,3.579c1.416,0.911 3.178,1.528 5.288,1.849c2.11,0.322 4.414,0.818 6.912,1.488c2.498,0.67 4.802,1.488 6.912,2.453c2.109,0.965 3.872,2.64 5.287,5.026c1.416,2.385 2.124,5.32 2.124,8.805c0,3.484 -0.722,6.554 -2.165,9.207c-1.444,2.654 -3.331,4.704 -5.663,6.152c-4.497,2.841 -9.299,4.262 -14.406,4.262c-2.609,0 -5.08,-0.308 -7.412,-0.925c-2.331,-0.616 -4.219,-1.38 -5.662,-2.292c-2.943,-1.715 -4.997,-3.377 -6.163,-4.985l-0.749,-0.885Z"
              style="fill: #fff; fill-rule: nonzero"
            />
            <path
              d="M160.303,8.122c0,-1.233 0.041,-2.158 0.125,-2.774c0.083,-0.617 0.347,-1.327 0.791,-2.131c0.777,-1.448 2.942,-2.172 6.495,-2.172c3.886,0 6.19,0.992 6.912,2.976c0.278,0.911 0.416,2.305 0.416,4.181l0,44.71c0,1.287 -0.041,2.225 -0.125,2.815c-0.083,0.589 -0.347,1.286 -0.791,2.09c-0.777,1.448 -2.942,2.172 -6.495,2.172c-3.886,0 -6.162,-1.019 -6.829,-3.056c-0.333,-0.858 -0.499,-2.225 -0.499,-4.101l0,-44.71Z"
              style="fill: #fff; fill-rule: nonzero"
            />
            <path
              d="M213.515,24.446l14.656,0c2.998,0.107 4.83,0.858 5.496,2.251c0.555,1.019 0.833,2.52 0.833,4.504l0,17.691c0,1.715 -0.777,3.35 -2.332,4.905c-4.663,4.664 -11.658,6.996 -20.985,6.996c-8.272,0 -15.461,-3.016 -21.568,-9.047c-6.106,-6.031 -9.16,-13.308 -9.16,-21.832c0,-8.524 3.109,-15.641 9.327,-21.35c6.218,-5.709 13.546,-8.564 21.984,-8.564c6.551,0 12.741,2.091 18.57,6.272c1.499,1.072 2.249,2.265 2.249,3.579c0,1.313 -0.639,2.828 -1.916,4.543c-2.165,2.841 -4.135,4.262 -5.912,4.262c-1.055,0 -2.748,-0.737 -5.08,-2.211c-2.331,-1.475 -5.107,-2.212 -8.327,-2.212c-4.219,0 -7.967,1.434 -11.242,4.302c-3.276,2.868 -4.913,6.621 -4.913,11.258c0,4.637 1.665,8.578 4.996,11.821c3.331,3.243 7.106,4.865 11.325,4.865c3.054,0 5.802,-0.482 8.245,-1.447l0,-8.765l-6.579,0c-1.721,0 -2.942,-0.215 -3.664,-0.644c-0.722,-0.429 -1.194,-1.058 -1.416,-1.889c-0.222,-0.831 -0.333,-1.957 -0.333,-3.378c0,-1.42 0.125,-2.56 0.375,-3.417c0.25,-0.858 0.708,-1.448 1.374,-1.769c0.999,-0.483 2.332,-0.724 3.997,-0.724Z"
              style="fill: #fff; fill-rule: nonzero"
            />
            <path
              d="M280.551,3.538c0.555,-0.965 1.36,-1.608 2.415,-1.93c1.054,-0.321 2.373,-0.482 3.955,-0.482c1.582,0 2.859,0.134 3.831,0.402c0.971,0.268 1.693,0.59 2.165,0.965c0.472,0.375 0.819,0.938 1.041,1.689c0.333,0.857 0.499,2.224 0.499,4.101l0,44.629c0,1.287 -0.041,2.225 -0.124,2.815c-0.084,0.589 -0.347,1.286 -0.792,2.09c-0.777,1.448 -2.942,2.172 -6.495,2.172c-2.165,0 -3.664,-0.161 -4.497,-0.483c-0.832,-0.321 -1.526,-0.831 -2.082,-1.528c-12.491,-16.082 -20.707,-26.59 -24.649,-31.522l0,26.456c0,1.287 -0.041,2.225 -0.125,2.815c-0.083,0.589 -0.347,1.286 -0.791,2.09c-0.777,1.448 -2.942,2.172 -6.495,2.172c-3.442,0 -5.552,-0.724 -6.329,-2.172c-0.444,-0.804 -0.708,-1.527 -0.791,-2.171c-0.083,-0.643 -0.125,-1.581 -0.125,-2.814l0,-44.871c0,-2.037 0.264,-3.525 0.791,-4.463c0.528,-0.938 1.319,-1.568 2.373,-1.89c1.055,-0.321 2.415,-0.482 4.081,-0.482c1.665,0 2.984,0.147 3.955,0.442c0.972,0.295 1.652,0.63 2.041,1.005c0.222,0.161 0.86,0.858 1.915,2.091c11.714,15.386 19.542,25.545 23.483,30.477l0,-27.18c0,-2.037 0.25,-3.511 0.75,-4.423Z"
              style="fill: #fff; fill-rule: nonzero"
            />
            <path
              d="M336.344,5.227l22.484,44.871c0.944,1.822 1.416,3.19 1.416,4.101c0,1.93 -1.61,3.672 -4.83,5.227c-1.887,0.911 -3.372,1.367 -4.455,1.367c-1.083,0 -1.971,-0.241 -2.665,-0.724c-0.694,-0.482 -1.193,-0.992 -1.499,-1.528c-0.305,-0.536 -0.735,-1.34 -1.29,-2.412l-4.331,-8.685l-23.067,0l-4.33,8.685c-0.555,1.072 -0.985,1.849 -1.291,2.332c-0.305,0.482 -0.805,0.978 -1.499,1.487c-0.694,0.51 -1.582,0.764 -2.664,0.764c-1.083,0 -2.568,-0.455 -4.456,-1.367c-3.219,-1.501 -4.829,-3.216 -4.829,-5.146c0,-0.911 0.471,-2.279 1.415,-4.101l22.484,-44.952c0.611,-1.233 1.541,-2.224 2.79,-2.975c1.249,-0.75 2.568,-1.126 3.955,-1.126c2.998,0 5.219,1.394 6.662,4.182Zm-6.745,19.299l-5.496,11.017l11.076,0l-5.58,-11.017Z"
              style="fill: #fff; fill-rule: nonzero"
            />
            <path
              d="M396.635,24.446l14.656,0c2.998,0.107 4.83,0.858 5.496,2.251c0.555,1.019 0.833,2.52 0.833,4.504l0,17.691c0,1.715 -0.777,3.35 -2.332,4.905c-4.663,4.664 -11.658,6.996 -20.985,6.996c-8.272,0 -15.461,-3.016 -21.568,-9.047c-6.107,-6.031 -9.16,-13.308 -9.16,-21.832c0,-8.524 3.109,-15.641 9.327,-21.35c6.218,-5.709 13.546,-8.564 21.984,-8.564c6.551,0 12.741,2.091 18.57,6.272c1.499,1.072 2.249,2.265 2.249,3.579c0,1.313 -0.639,2.828 -1.916,4.543c-2.165,2.841 -4.136,4.262 -5.912,4.262c-1.055,0 -2.748,-0.737 -5.08,-2.211c-2.331,-1.475 -5.107,-2.212 -8.327,-2.212c-4.219,0 -7.967,1.434 -11.242,4.302c-3.276,2.868 -4.913,6.621 -4.913,11.258c0,4.637 1.665,8.578 4.996,11.821c3.331,3.243 7.106,4.865 11.325,4.865c3.054,0 5.802,-0.482 8.244,-1.447l0,-8.765l-6.578,0c-1.721,0 -2.943,-0.215 -3.664,-0.644c-0.722,-0.429 -1.194,-1.058 -1.416,-1.889c-0.222,-0.831 -0.333,-1.957 -0.333,-3.378c0,-1.42 0.125,-2.56 0.375,-3.417c0.25,-0.858 0.708,-1.448 1.374,-1.769c0.999,-0.483 2.331,-0.724 3.997,-0.724Z"
              style="fill: #fff; fill-rule: nonzero"
            />
            <path
              d="M439.021,45.755l25.649,0c1.277,0 2.234,0.041 2.873,0.121c0.638,0.08 1.374,0.335 2.207,0.764c1.499,0.751 2.248,2.841 2.248,6.272c0,3.753 -1.027,5.951 -3.081,6.594c-0.944,0.322 -2.387,0.483 -4.33,0.483l-32.977,0c-3.886,0 -6.162,-1.019 -6.828,-3.056c-0.334,-0.858 -0.5,-2.225 -0.5,-4.101l0,-44.71c0,-2.734 0.527,-4.597 1.582,-5.589c1.055,-0.992 3.054,-1.488 5.996,-1.488l32.81,0c1.277,0 2.234,0.041 2.873,0.121c0.638,0.08 1.374,0.335 2.207,0.764c1.499,0.75 2.248,2.841 2.248,6.272c0,3.753 -1.027,5.951 -3.081,6.594c-0.944,0.322 -2.387,0.483 -4.33,0.483l-25.566,0l0,8.121l16.489,0c1.277,0 2.234,0.041 2.873,0.121c0.638,0.08 1.374,0.335 2.206,0.764c1.499,0.75 2.249,2.841 2.249,6.272c0,3.753 -1.055,5.951 -3.165,6.594c-0.943,0.322 -2.387,0.483 -4.33,0.483l-16.322,0l0,8.121Z"
              style="fill: #fff; fill-rule: nonzero"
            />
          </svg>
          <!-- <span class="logo-lg-text-light">Xeria</span> -->
        </span>
        <span class="logo-sm">
          <!-- <span class="logo-sm-text-dark">X</span> -->
          <img src="assets/images/app_logo.png" alt="" height="24" />
        </span>
      </a>
    </div>
    <ng-container *ngTemplateOutlet="sidebarMenu"></ng-container>
    <!-- Left Sidebar End -->
    <div class="p-3 footer cms-version">
      {{ version }}
    </div>
    }
    <ng-template #sidebarMenu>
      <div
        class="sidebar-menu"
        [class.hamburger-sidebar-menu]="responsiveUiService.isMobileDevice()"
      >
        <ul class="">
          <li class="menu-title">{{ 'NAVIGATION' | translate }}</li>

          <li>
            <a
              aria-expanded="false"
              routerLink="/watchtower"
              routerLinkActive="active"
              class="pointer"
              (click)="gotoRoute('/watchtower'); settingsTreeOpen = false"
            >
              <i class="ri-dashboard-line"></i>
              <span>WatchTower</span>
            </a>
          </li>
          @if(profileRelatedLinkEnabled){
          <li [hidden]="!currentProfile">
            <!-- Sidebar collapsed -->
            @if(isSidebarCollapsed() && !responsiveUiService.isMobileDevice()){
            <a class="settings-header">
              <i class="ri-settings-line"></i>
              <span>{{ 'SETTINGS' | translate }}</span>
            </a>
            <ng-container *ngTemplateOutlet="settingsMenuItems"></ng-container>
            } @else {
            <!-- Sidebar expanded -->

            <mat-accordion>
              <mat-expansion-panel
                (opened)="settingsTreeOpen = true"
                (closed)="settingsTreeOpen = false"
                [expanded]="settingsTreeOpen"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <i class="ri-settings-line"></i>
                    <span>{{ 'SETTINGS' | translate }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container
                  *ngTemplateOutlet="settingsMenuItems"
                ></ng-container>
              </mat-expansion-panel>
            </mat-accordion>
            }

            <!-- Sub-menu -->
            <ng-template #settingsMenuItems matExpansionPanelContent>
              <ul>
                <li>
                  <a
                    class="pointer"
                    (click)="gotoRoute('/profile/settings')"
                    routerLink="/profile/settings"
                    routerLinkActive="active"
                  >
                    <i class="ri-profile-line"></i>
                    <span>{{ 'PROFILE' | translate }}</span>
                  </a>
                  <a
                    class="pointer"
                    (click)="gotoRoute('/profile/details')"
                    routerLink="/profile/details"
                    routerLinkActive="active"
                  >
                    <i class="ri-profile-line"></i>
                    <span>{{ 'DETAILS' | translate }}</span>
                  </a>
                </li>
                <li>
                  <a
                    class="pointer"
                    (click)="gotoRoute('/profile/devices')"
                    routerLink="/profile/devices"
                    routerLinkActive="active"
                  >
                    <i class="ri-device-line"></i>
                    <span>{{ 'DEVICES' | translate }}</span>
                  </a>
                </li>
                <li>
                  <a
                    class="pointer"
                    (click)="gotoRoute('/profile/locations')"
                    routerLink="/profile/locations"
                    routerLinkActive="active"
                  >
                    <i class="ri-map-pin-line"></i>
                    <span>{{ 'LOCATIONS' | translate }}</span>
                  </a>
                </li>
                <li>
                  <a
                    class="pointer"
                    (click)="gotoRoute('/profile/users')"
                    routerLink="/profile/users"
                    routerLinkActive="active"
                  >
                    <i class="ri-user-add-line"></i>
                    <span>{{ 'USERS' | translate }}</span>
                  </a>
                </li>
                <li>
                  <a
                    class="pointer"
                    (click)="gotoRoute('/profile/resource-groups')"
                    routerLink="/profile/resource-groups"
                    routerLinkActive="active"
                  >
                    <i class="ri-apps-line"></i>
                    <span>{{ 'RESOURCE_GROUPS' | translate }}</span>
                  </a>
                </li>
              </ul>
            </ng-template>
            <!-- End of Sub-menu  -->
          </li>
          } @if(contentRelatedLinkEnabled && currentProfile){
          <li class="d-none d-md-block">
            <a
              class="pointer"
              routerLinkActive="active"
              routerLink="/media"
              (click)="gotoRoute('/media'); settingsTreeOpen = false"
            >
              <i class="ri-camera-line"></i>
              <span>{{ 'CONTENT' | translate }}</span>
            </a>
          </li>
          } @if(profileRelatedLinkEnabled){
          <li class="d-none d-md-block">
            <a
              class="pointer"
              routerLinkActive="active"
              routerLink="/layouts"
              (click)="gotoRoute('/layouts'); settingsTreeOpen = false"
            >
              <i class="ri-layout-line"></i>
              <span>{{ 'LAYOUTS' | translate }}</span>
            </a>
          </li>
          } @if(contentRelatedLinkEnabled && currentProfile){
          <li>
            <a
              class="pointer"
              routerLinkActive="active"
              routerLink="/playlists"
              (click)="gotoRoute('/playlists'); settingsTreeOpen = false"
            >
              <i class="ri-play-list-line"></i>
              <span>{{ 'PLAYLISTS' | translate }}</span>
            </a>
          </li>
          } @if(currentUserService.canManageChannels &&
          contentRelatedLinkEnabled && currentProfile){
          <li>
            <a
              class="pointer"
              routerLinkActive="active"
              routerLink="/channels"
              (click)="gotoRoute('/channels'); settingsTreeOpen = false"
            >
              <i class="ri-tv-line"></i>
              <span>{{ 'CHANNELS' | translate }}</span>
            </a>
          </li>
          } @if(currentUserService.canManageEvents && contentRelatedLinkEnabled
          && currentProfile){
          <li>
            <a
              class="pointer"
              routerLinkActive="active"
              routerLink="/events"
              (click)="gotoRoute('/events'); settingsTreeOpen = false"
            >
              <i class="ri-tv-line"></i>
              <span>{{ 'EVENTS' | translate }}</span>
            </a>
          </li>
          } @if(superAdminLinksEnabled){
          <hr />
          <li>
            <a
              class="pointer"
              routerLinkActive="active"
              routerLink="/user/salist"
              (click)="gotoRoute('/user/salist'); settingsTreeOpen = false"
            >
              <i class="ri-user-settings-line"></i>
              <span>{{ 'MANAGE_ALL_USERS' | translate }}</span>
            </a>
          </li>
          <li>
            <a
              routerLinkActive="active"
              routerLink="/organization/dashboard"
              (click)="
                gotoRoute('/organization/dashboard'); settingsTreeOpen = false
              "
            >
              <i class="ri-building-2-line"></i>
              <span>{{ 'ORGANIZATIONS' | translate }}</span>
            </a>
          </li>
          }

          <hr />
          <li>
            <a
              (click)="openZendeskWidget()"
              [class.active]="supportWidget"
              class="pointer"
            >
              <i class="ri-question-line"></i>
              <span> {{ 'SUPPORT' | translate }} </span>
            </a>
          </li>

          <hr />
          @if(!responsiveUiService.isMobileDevice()){
          <li>
            <a
              class="pointer"
              aria-expanded="false"
              (click)="setSidebarState(!isSidebarCollapsed())"
            >
              <i
                [ngClass]="
                  isSidebarCollapsed()
                    ? 'ri-arrow-right-s-line'
                    : 'ri-arrow-left-s-line'
                "
              ></i>
              <span>{{
                (isSidebarCollapsed ? 'SHOW_MENU' : 'HIDE_MENU') | translate
              }}</span>
            </a>
          </li>
          }
        </ul>
      </div>
    </ng-template>
  </div>
</div>
