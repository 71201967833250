import { OnInit, Component, OnDestroy, Input, inject } from '@angular/core';
import { SubSink } from 'subsink';
import {
  Location,
  Maybe,
  GetProfileLocationsGQL,
  ProfileLocationsFragment,
} from '@designage/gql';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  ToasterService,
  EncryptionService,
  CurrentUserService,
  LocationService,
  FilterService,
  SlidePanelService,
} from '@desquare/services';
import { ApolloError } from '@apollo/client/errors';
import {
  IDatatablePageChangeArgs,
  ILocationForm,
  IDesignageDataTableColumns,
} from '@desquare/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateLocationDialogComponent } from '../create-location-dialog/create-location-dialog.component';
import { DuplicateLocationNameDialogComponent } from '../duplicate-location-name-dialog/duplicate-location-name-dialog.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { domConstants } from '@desquare/constants';
import { TranslateModule } from '@ngx-translate/core';
import { TableDateTimeComponent } from '@desquare/components/common/src/designage-data-table/table-components/table-dateTime.component';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    NgIf,
    DesignageDataTableComponent,
    TableDateTimeComponent,
    RouterOutlet,
    TranslateModule,
  ],
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss'],
})
export class LocationListComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  @Input() enableSlidePanel = true;
  isSlidePanelOpen = inject(SlidePanelService).getIsPanelOpen();
  selectedChannelId = inject(SlidePanelService).getPanelComponentId();
  profileId!: Maybe<string>;
  isConfirmationOpen = false;
  loaderMessage!: string;
  loading = false;
  desColumns: IDesignageDataTableColumns[] = [];
  locations: ProfileLocationsFragment[] = [];

  locationSearchForm!: FormGroup;
  searchText = '';
  dataTableLoading = false;
  location!: Maybe<Location>;
  duplicateLocations?: ProfileLocationsFragment[] = [];

  total = 0;
  page = 1;
  pageSize = 10;
  pageSizeOptions = domConstants.DATA_PAGE_SIZE_OPTIONS;

  constructor(
    private toasterService: ToasterService,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private getProfileLocationsGQL: GetProfileLocationsGQL,
    private router: Router,
    private route: ActivatedRoute,
    private slidePanelService: SlidePanelService,
    private locationService: LocationService,
    private filterService: FilterService
  ) {}

  get showAddLocationButton() {
    return this.currentUserService.canManageLocations;
  }

  get hasNoData(): boolean {
    return !this.loading && this.total === 0;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.initSubscriptions();
    this.initVariables();
    this.initForm();
    this.setFormState();
  }

  initForm() {
    this.locationSearchForm = this.formBuilder.group({
      search: [null],
      pageSize: [this.pageSize],
    });
  }

  initVariables() {
    this.desColumns = [
      {
        fieldName: 'name',
        name: 'NAME',
        type: 'string',
        visible: 'mandatory',
      },
      {
        fieldName: 'streetAddress1',
        name: 'STREET_ADDRESS',
        type: 'string',
        visible: true,
        groupable: true,
      },
      {
        fieldName: 'city',
        name: 'CITY',
        type: 'string',
        visible: true,
        groupable: true,
      },
      {
        fieldName: 'createdAt',
        name: 'CREATED_ON',
        type: 'template',
        templateRef: 'createdAt',
        visible: true,
      },
      {
        fieldName: 'updatedAt',
        name: 'LAST_UPDATED',
        type: 'template',
        templateRef: 'updatedAt',
        visible: true,
      },
    ];

    this.pageSize = this.currentUserService.preferredPageSize;
  }

  initSubscriptions() {
    this.profileId = this.currentUserService.getCurrentProfileId();
    this.getLocations();
  }

  setFormState() {
    this.subs.sink =
      this.locationSearchForm.controls.pageSize.valueChanges.subscribe(
        (value: number) => {
          this.pageSize = value;
          this.currentUserService.setPreferredPageSize(value);

          this.page = 1;
        }
      );
  }

  onRowClick(location: Location) {
    if (location?.id && this.profileId) {
      const id = this.encryptionService.encrypt(location.id);
      const profileId = this.encryptionService.encrypt(this.profileId);

      if (this.currentUserService.canManageLocations && this.profileId) {
        this.slidePanelService.setPanelComponentId(location.id);
        this.router.navigate(['manage', id, profileId], {
          relativeTo: this.route,
        });
      } else {
        this.toasterService.error('UNKNOWN_ERROR');
      }
    }
  }

  getLocations() {
    this.dataTableLoading = true;
    this.loaderMessage = 'FETCHING_LOCATIONS';
    this.locations = [];

    if (this.profileId) {
      this.subs.sink = this.getProfileLocationsGQL
        .watch(
          {
            profileId: this.profileId,
          },
          { fetchPolicy: 'cache-and-network' }
        )
        .valueChanges.subscribe({
          next: ({ data, loading }) => {
            this.dataTableLoading = loading;
            const { profile } = data;

            if (profile) {
              const filteredLocations = this.filterService.filterListByName(
                this.searchText,
                profile.locations
              );
              this.locations = filteredLocations;
              this.total = filteredLocations.length;
            } else {
              this.total = 0;
              this.locations = [];
            }
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('getLocations', gqlError);
              this.toasterService.handleGqlError(gqlError);
            });

            this.dataTableLoading = false;
          },
        });
    } else {
      this.dataTableLoading = false;
    }
  }

  onPageChange({ offset }: IDatatablePageChangeArgs) {
    this.page = offset;
  }

  search(searchText: string) {
    this.searchText = searchText;
    this.page = 1;
    this.getLocations();
  }

  openCreateLocationDialog() {
    const modalRef = this.modalService.open(CreateLocationDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.result
      .then((values: ILocationForm) => {
        if (this.profileId) {
          this.checkDuplicateLocation(values);
        } else {
          this.createLocation(values);
        }
      })
      .catch(() => {});
  }

  checkDuplicateLocation(values: ILocationForm) {
    if (this.profileId) {
      const profileId = this.profileId;
      const { name } = values;
      this.loading = true;
      this.loaderMessage = 'CHECKING_DUPLICATE_LOCATION_NAME';

      this.subs.sink = this.getProfileLocationsGQL
        .fetch(
          { profileId, name, findExact: true },
          { fetchPolicy: 'network-only' }
        )
        .subscribe({
          next: ({ data, loading }) => {
            this.loading = loading;

            if (data && data.profile) {
              this.duplicateLocations = data.profile.locations;

              if (this.duplicateLocations.length > 0) {
                this.openDuplicateLocationNameDialog(values);
              } else {
                this.createLocation(values);
              }
            } else {
              this.toasterService.error('UNKNOWN_ERROR');
            }
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('checkDuplicateLocation', gqlError);
              this.toasterService.handleGqlError(gqlError);
            });
            this.loading = false;
          },
        });
    }
  }

  createLocation(input: ILocationForm) {
    if (this.profileId) {
      input.profileId = this.profileId;
      this.loading = true;
      this.loaderMessage = 'CREATING_LOCATION';

      // Remove id property before creation
      if ('id' in input) {
        delete input.id;
      }

      this.subs.sink = this.locationService
        .createLocation(input, this.profileId)
        .subscribe({
          next: ({ data }) => {
            if (data && data.createLocation.isSuccessful) {
              this.toasterService.success('CREATE_LOCATION_SUCCESS');
              this.getLocations();
            } else {
              this.toasterService.error('UNKNOWN_ERROR');
            }
            this.loading = false;
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('createLocation', gqlError);
              this.toasterService.handleGqlError(gqlError);
            });
            this.loading = false;
          },
        });
    }
  }

  openDuplicateLocationNameDialog(values: ILocationForm) {
    if (!this.isConfirmationOpen) {
      const modal = this.modalService.open(
        DuplicateLocationNameDialogComponent,
        { windowClass: 'app-modal-xl', backdrop: 'static' }
      );
      modal.componentInstance.duplicateLocations = this.duplicateLocations;
      modal.componentInstance.locationName = values.name;
      modal.result
        .then((value) => {
          if (value) {
            values.name = value;
            this.createLocation(values);
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isConfirmationOpen = false;
        });
    }
  }
}
