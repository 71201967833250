import { writeActiveProfile } from '@desquare/utils';
import { CustomResolver } from './common';
import {
  MutationSetActiveProfileArgs,
  GetActiveProfileQuery,
  GetActiveProfileDocument,
} from '@designage/gql';

export const setActiveProfile: CustomResolver<
  any,
  MutationSetActiveProfileArgs
> = (_, { input }, { cache }) => {
  return writeActiveProfile(cache, input);
};
