import { Component, OnInit, OnDestroy } from '@angular/core';
import { Maybe, Location } from '@designage/gql';
import { SessionService } from '@desquare/services';
import { SubSink } from 'subsink';
import { TranslateModule } from '@ngx-translate/core';
import { LocationSelectionComponent } from '../location-selection/location-selection.component';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';

@Component({
  standalone: true,
  imports: [FormDialogComponent, LocationSelectionComponent, TranslateModule],
  selector: 'app-location-replacement-dialog',
  templateUrl: './location-replacement-dialog.component.html',
  styleUrls: ['./location-replacement-dialog.component.scss'],
})
export class LocationReplacementDialogComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  isFormValid = false;
  isFormPristine = true;
  currentLocation!: Maybe<Location>;
  selectedLocation!: Maybe<Location>;
  profileId!: Maybe<string>;
  promptMessage!: Maybe<string>;

  constructor(private session: SessionService) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.profileId = this.session.profileId();
  }
}
