import { Component, OnInit, Input } from '@angular/core';
import {
  GetNotificationsGQL,
  Notification,
  UpdateNotificationStatusGQL,
  UpdateBulkNotificationStatusGQL,
  NotificationStatusInput,
  BulkNotificationStatusInput,
  User,
  Maybe,
  SaveNotificationPayload,
  GetNotificationsQuery,
  UpdateNotificationSubscriptionGQL,
  UpdateBulkNotificationSubscriptionGQL,
  UpdateBulkNotificationStatusPayload,
  GetMeProfilesQuery,
  NotificationContentType,
  GetNotificationsQueryVariables,
  NewNotificationSubscriptionGQL,
  SaveMediaPayload,
} from '@designage/gql';
import { SubSink } from 'subsink';
import { ToasterService } from '@desquare/services';
import { ApolloError } from '@apollo/client/errors';
import { ArrayElement, ExcludeMaybe } from '@desquare/types';
import { QueryRef } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { RouterLink } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgIf } from '@angular/common';
import { NotificationRowComponent } from '../notification-row/notification-row.component';
import { TranslateModule } from '@ngx-translate/core';

type TopBarProfile = ArrayElement<
  ExcludeMaybe<ExcludeMaybe<GetMeProfilesQuery['me']>['profiles']>['results']
>;

@Component({
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    NgFor,
    TranslateModule,
    NgbDropdownModule,
    NotificationRowComponent,
  ],
  selector: 'app-notification-list',
  template: `<a
      class="nav-link dropdown-toggle no-caret"
      ngbDropdownToggle
      id="notificationDropDown"
      role="button"
      aria-haspopup="false"
      aria-expanded="false"
    >
      <i class="fe-bell noti-icon"></i>
      <span
        *ngIf="showNotifications"
        class="badge bg-danger rounded-circle noti-icon-badge"
        >{{ totalTextLabel }}</span
      >
    </a>

    <div
      autoClose="false"
      class="dropdown-menu dropdown-menu-right dropdown-lg p-0"
      aria-labelledby="notificationDropDown"
      ngbDropdownMenu
    >
      <div
        *ngIf="showNotifications"
        class="noti-title shadowed-box p-2 notification-board"
      >
        <h5 class="m-0 text-white">
          <span class="float-end">
            <a [routerLink]="" (click)="markAllAsRead()" class="pointer">
              <small>{{ 'CLEAR_ALL' | translate }}</small>
            </a>
          </span>
          {{ 'NOTIFICATIONS' | translate }}
        </h5>
        <a
          *ngFor="let notification of notifications; let i = index"
          [routerLink]=""
          (click)="markAsRead(i)"
          class="dropdown-item notify-item"
        >
          <app-notification-row
            [notification]="notification"
          ></app-notification-row>
        </a>
      </div>
      <div
        *ngIf="!showNotifications"
        class="noti-title shadowed-box p-2 notification-board empty-notification"
      >
        <h5 class="text-white">{{ 'NO_NEW_UPDATES' | translate }}</h5>
      </div>
    </div> `,
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  private subs = new SubSink();
  private contentTypes!: NotificationContentType[];

  loading = false;

  notifications: Notification[] = [];
  notificationsQuery!: QueryRef<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >;

  @Input() maxTotal = 20;
  @Input() isRead = false;
  @Input() user!: Maybe<User>;
  @Input() profile!: Maybe<TopBarProfile>;

  constructor(
    private newNotificationSubscription: NewNotificationSubscriptionGQL,
    private updateNotificationSubscription: UpdateNotificationSubscriptionGQL,
    private updateBulkNotificationSubscription: UpdateBulkNotificationSubscriptionGQL,
    private getNotificationsGQL: GetNotificationsGQL,
    private updateNotificationStatusGQL: UpdateNotificationStatusGQL,
    private updateBulkNotificationStatusGQL: UpdateBulkNotificationStatusGQL,
    private toasterService: ToasterService
  ) {}

  get showNotifications() {
    return this.notifications.length > 0;
  }

  get totalTextLabel() {
    const total = this.notifications.length;
    return total > this.maxTotal ? `${this.maxTotal}+` : total;
  }

  ngOnInit() {
    this.initValues();
    this.initSubscriptions();
  }

  initValues() {
    this.contentTypes = [
      NotificationContentType.MediaUploaded,
      NotificationContentType.MediaUploadPending,
      NotificationContentType.MediaUploadFailed,
      NotificationContentType.DeviceStatusChanged,
      // NotificationContentType.DeviceDeleted,  # sample implementation
    ];

    this.notificationsQuery = this.getNotificationsGQL.watch(
      {
        pageSize: 20,
        filter: { isRead: this.isRead, contentTypes: this.contentTypes },
      },
      { fetchPolicy: 'cache-first' }
    );
  }

  initSubscriptions() {
    if (!this.user) {
      throw new Error('User is undefined');
    }

    const variables = {
      userId: this.user.id,
      profileId: this.profile?.id,
      contentTypes: this.contentTypes,
    };
    const typeName = 'PaginatedNotifications';

    // query on load

    this.subs.sink = this.notificationsQuery.valueChanges.subscribe(
      ({ data }) => {
        const { results } = data.notifications;
        const notifications = cloneDeep(results as Notification[]);
        this.notifications = notifications.sort((a, b) => {
          return a.createdAt > b.createdAt ? -1 : 1;
        });
      }
    );

    this.notificationsQuery.subscribeToMore({
      document: this.newNotificationSubscription.document,
      variables,
      updateQuery: (prev, { subscriptionData }) => {
        const res: SaveNotificationPayload = (subscriptionData.data as any)
          .newNotification;

        if (!res.notification || !res.isSuccessful) {
          return prev;
        }

        const newNotification = res.notification;

        const results = [newNotification, ...prev.notifications.results];
        const response: GetNotificationsQuery = {
          ...prev,
          notifications: {
            results,
            total: results.length,
            __typename: typeName,
          },
        };

        return response;
      },
    });

    // cms to api notification crud operations

    this.notificationsQuery.subscribeToMore({
      document: this.updateNotificationSubscription.document,
      variables,
      updateQuery: (prev, { subscriptionData }) => {
        const res: SaveNotificationPayload = (subscriptionData.data as any)
          .updateNotification;

        if (!res.notification || !res.isSuccessful) {
          return prev;
        }

        const readNotification = res.notification;
        const results = prev.notifications.results.filter(
          (x) => x.id !== readNotification.id
        );

        const response: GetNotificationsQuery = {
          ...prev,
          notifications: {
            results,
            total: results.length,
            __typename: typeName,
          },
        };

        return response;
      },
    });

    this.notificationsQuery.subscribeToMore({
      document: this.updateBulkNotificationSubscription.document,
      variables,
      updateQuery: (prev, { subscriptionData }) => {
        const res: UpdateBulkNotificationStatusPayload = (
          subscriptionData.data as any
        ).updateBulkNotification;
        const results = res.notifications || [];

        if (!res.isSuccessful) {
          return prev;
        }

        const response: GetNotificationsQuery = {
          ...prev,
          notifications: {
            results,
            total: results.length,
            __typename: typeName,
          },
        };

        return response;
      },
    });
  }

  markAsRead(index: number) {
    const input: NotificationStatusInput = {
      id: this.notifications[index].id,
      isRead: true,
    };

    this.updateNotificationStatusGQL.mutate({ input }).subscribe({
      next: ({ data }) => {
        if (!data?.updateNotificationStatus.isSuccessful) {
          console.error('Update status unsuccessful');
        }
      },
      error: (error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('markAsRead', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
      },
    });
  }

  markAllAsRead() {
    const ids = this.notifications.map((notification) => notification.id);
    const input: BulkNotificationStatusInput = {
      ids,
      isRead: true,
      contentTypes: this.contentTypes,
    };

    this.updateBulkNotificationStatusGQL.mutate({ input }).subscribe({
      next: ({ data }) => {
        if (!data?.updateBulkNotificationStatus.isSuccessful) {
          this.toasterService.error('ALERT');
        }
      },
      error: (error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('markAllAsRead', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
      },
    });
  }
}
