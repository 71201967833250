import { Component, OnInit, Input } from '@angular/core';
import { Maybe, MediaMetadata, ResourceType } from '@designage/gql';
import { CopyToClipboardComponent } from '@desquare/components/common/src/copy-to-clipboard/copy-to-clipboard.component';
import { FileSizePipe } from '@desquare/components/common/src/pipe/file-size/file-size.pipe';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    CopyToClipboardComponent,
    TranslateModule,
    FileSizePipe,
    DateProxyPipe,
  ],
  selector: 'app-media-meta-data',
  templateUrl: './media-meta-data.component.html',
  styleUrls: ['./media-meta-data.component.scss'],
})
export class MediaMetaDataComponent implements OnInit {
  @Input() details!: Maybe<MediaMetadata>;

  constructor() {}

  ngOnInit() {}

  get hideDuration() {
    return this.details?.resourceType !== ResourceType.Video;
  }
  get formattedDuration() {
    if (this.details) {
      return this.details.duration;
    }
  }
}
