import { Injectable } from '@angular/core';
import { UrlTree, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentUserService } from '@desquare/services';

@Injectable({
  providedIn: 'root',
})
export class ProfileManageGuard implements CanLoad {
  constructor(private currentUserService: CurrentUserService) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return (
      this.currentUserService.canManageProfiles ||
      this.currentUserService.canViewProfiles
    );
  }
}
