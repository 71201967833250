import { environment as env } from '@desquare/environments';
import {
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
} from '@apollo/client/core';
import { print, GraphQLError } from 'graphql';
import {
  createClient,
  ClientOptions,
  Client,
  ExecutionResult,
} from 'graphql-sse';

export class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<ExecutionResult<FetchResult, unknown>>(
        { ...operation, query: print(operation.query) },
        {
          next: (result) => sink.next(result.data as FetchResult),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}
