import { createReducer, on, Action } from '@ngrx/store';
import { initialDeviceState, IDeviceState } from '@desquare/store/states';
import { deviceActions } from '@desquare/store/actions';

const reducer = createReducer(
  initialDeviceState,
  on(deviceActions.switchDevice, (state, action) => ({
    ...state,
    device: action.device,
  }))
);

export function deviceReducer(state: IDeviceState | undefined, action: Action) {
  return reducer(state, action);
}
