import { createAction, props } from '@ngrx/store';
import { DeleteLocationInput, Location } from '@designage/gql';
import { ApolloError } from '@apollo/client/errors';
import { IOperationStatus } from '../states';

export enum LocationActionTypes {
  DELETE_LOCATION = 'DELETE_LOCATION',
  SET_LOCATIONS = 'SET_LOCATIONS',
  DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR',
  DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS',
  SET_LOCATION_STATUS = 'SET_LOCATION_STATUS',
}

const setLocations = createAction(
  LocationActionTypes.SET_LOCATIONS,
  props<{ locations: Location[] }>()
);
const setLocationStatus = createAction(
  LocationActionTypes.SET_LOCATION_STATUS,
  props<{ status?: IOperationStatus }>()
);
const deleteLocation = createAction(
  LocationActionTypes.DELETE_LOCATION,
  props<{ input: DeleteLocationInput }>()
);
const deleteLocationSuccess = createAction(
  LocationActionTypes.DELETE_LOCATION_SUCCESS,
  props<{ status?: IOperationStatus; locations?: Location[] }>()
);
const deleteLocationError = createAction(
  LocationActionTypes.DELETE_LOCATION_ERROR,
  props<{ error?: ApolloError }>()
);

export const locationActions = {
  setLocations,
  setLocationStatus,
  deleteLocation,
  deleteLocationError,
  deleteLocationSuccess,
};
