export enum ConfirmationButtons {
  YesNoCancel,
  YesCancel,
}

export enum ConfirmationResponse {
  Yes,
  No,
  Cancel,
}
