<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'SELECT_A_PROFILE' | translate }}</h4>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      data-dismiss="modal"
      (click)="modal.dismiss()"
    ></button>
  </div>
  <div class="modal-body">
    <app-profile-list
      *ngIf="enableProfileSwitcher"
      [isProfileSelection]="true"
      (selectedProfile)="selectProfile($event)"
    ></app-profile-list>
    <app-profile-selection
      *ngIf="enableProfileSelection"
      [organizationUid]="organization.organizationUid"
      [(selectedProfiles)]="selectedProfiles"
      (profileSelect)="onProfileSelect($event)"
    ></app-profile-selection>
  </div>

  <div class="modal-footer">
    <div class="col text-center">
      <button
        *ngIf="organization?.id"
        [disabled]="!enableAddButton"
        (click)="onSave()"
        type="button"
        class="btn btn-outline-success w-25 m-1"
        ngbTooltip="Save"
      >
        {{ 'ADD' | translate }}
      </button>
    </div>
  </div>
</div>
