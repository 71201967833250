import { initialUserState, IUserState } from '@desquare/store/states';
import { userActions } from '@desquare/store/actions';
import { createReducer, on, Action } from '@ngrx/store';
import { IUser } from '@desquare/interfaces';

const reducer = createReducer(
  initialUserState,
  on(userActions.setActiveUser, (state, action) => ({
    ...state,
    activeUser: { data: action.user },
  })),
  on(userActions.loadActiveUserError, (state, { error }) => ({
    ...state,
    activeUser: { error },
  })),
  on(userActions.setActiveUserProfiles, (state, action) => {
    const activeUser: IUser | undefined =
      ({ ...state.activeUser?.data } as IUser) || undefined;

    if (state.activeUser?.data && activeUser) {
      activeUser.profiles = action.profiles;
    }

    return { ...state, activeUser: { ...state.activeUser, data: activeUser } };
  }),
  on(userActions.loadActiveUserProfilesError, (state, { error }) => ({
    ...state,
    activeUser: { ...state.activeUser, error },
  }))
);

export function userReducer(state: IUserState | undefined, action: Action) {
  return reducer(state, action);
}
