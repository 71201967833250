import { ScheduleDisplay } from '@designage/gql';

export const displayScheduleTemplate = (): ScheduleDisplay => {
  return {
    days: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    on: '07:00:00',
    off: '19:00:00',
  };
};
