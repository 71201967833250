import { initialLocationState, ILocationState } from '@desquare/store/states';
import { locationActions } from '@desquare/store/actions';
import { createReducer, on, Action } from '@ngrx/store';

const reducer = createReducer(
  initialLocationState,
  on(locationActions.setLocations, (state, action) => ({
    ...state,
    locations: action.locations,
  })),
  on(locationActions.deleteLocationError, (state, action) => ({
    ...state,
    error: action.error,
  })),
  on(locationActions.deleteLocationSuccess, (state, action) => ({
    ...state,
    deleteLocationStatus: action.status,
    locations: action.locations,
  })),
  on(locationActions.setLocationStatus, (state, action) => ({
    ...state,
    deleteLocationStatus: action.status,
  }))
);

export function locationReducer(
  state: ILocationState | undefined,
  action: Action
) {
  return reducer(state, action);
}
