<span [ngSwitch]="notification.content.type">
  <span *ngSwitchCase="notificationContentType.DeviceStatusChanged">
    <ng-container *ngTemplateOutlet="deviceStatusChanged"></ng-container>
  </span>
  <span *ngSwitchCase="notificationContentType.DeviceDeleted">
    <ng-container *ngTemplateOutlet="deviceDeleted"></ng-container>
  </span>
</span>

<!-- Layouts -->

<ng-template #deviceStatusChanged>
  <div [ngClass]="statusIconStyle" class="notify-icon bg-soft-primary">
    <i class="mdi mdi-cast"></i>
  </div>
  <span class="title">{{ notification.title | translate }}</span>
  <p class="notify-details">
    <a
      ngbTooltip="{{ notification.content.name }}"
      [routerLink]=""
      (click)="navigateToDeviceManagePage(notification.content['deviceId'])"
    >
      {{ notification.content.name }}
    </a>
    <span
      ngbTooltip="{{ notification.message | translate }}"
      class="text-info"
      >{{ notification.message | translate }}</span
    >
    <ng-container *ngTemplateOutlet="createdAt"></ng-container>
  </p>
</ng-template>

<ng-template #deviceDeleted>
  <!--TODO: Remove sample script-->
  <div [ngClass]="statusIconStyle" class="notify-icon bg-soft-primary">
    <i class="mdi mdi-cast"></i>
  </div>
  <span class="title">{{ notification.title | translate }}</span>
  <p class="notify-details">
    <span ngbTooltip="{{ notification.content.name }}" class="text-primary">{{
      notification.content.name
    }}</span>
    <ng-container *ngTemplateOutlet="createdAt"></ng-container>
  </p>
</ng-template>

<ng-template #createdAt>
  <small
    ngbTooltip="{{ notification.createdAt | date : 'short' }}"
    class="sub-info"
    >{{ duration }}</small
  >
</ng-template>
