import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PlaylistForResourceGroupPlaylistListFragment } from '@designage/gql';
import {
  IDesignageDataTableColumns,
  IPlaylistForResourceGroupPlaylistList,
  IResourceGroup,
} from '@desquare/interfaces';
import { ResourceGroupService, ResponsiveUiService } from '@desquare/services';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSplitModule } from 'angular-split';
import {
  BehaviorSubject,
  Observable,
  combineLatestWith,
  map,
  switchMap,
  take,
} from 'rxjs';
import { ResourceGroupContentComponent } from './resource-group-content/resource-group-content.component';
import { ResourceGroupTitleComponent } from './resource-group-title/resource-group-title.component';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgbAccordionModule,
    AngularSplitModule,
    TranslateModule,
    DesignageDataTableComponent,
    ResourceGroupContentComponent,
    ResourceGroupTitleComponent,
  ],
  selector: 'app-resource-groups',
  templateUrl: './resource-groups.component.html',
  styleUrls: ['./resource-groups.component.scss'],
})
export class ResourceGroupsComponent implements OnInit {
  constructor(
    private resourceGroupService: ResourceGroupService,
    public responsiveUiService: ResponsiveUiService
  ) {}

  resourceGroups$ = this.resourceGroupService.resourceGroups$;
  users$ = this.resourceGroupService.users$;
  playlists$ = this.resourceGroupService.playlists$.pipe(
    combineLatestWith(this.resourceGroups$),
    map(([playlists, resourceGroups]) =>
      playlists.map((playlist) =>
        resourceGroups
          ? this.toIPlaylistForResourceGroupPlaylistList(
              playlist,
              resourceGroups
            )
          : []
      )
    )
  );
  // isEditing$ = new BehaviorSubject<boolean>(false);
  resourceGroupBeingEdited$ = new BehaviorSubject<string | null>(null);
  activeAccordionPanelIds$ = new BehaviorSubject<string[]>([]);

  userTableColumns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'displayName',
      type: 'string',
      name: 'USER_NAME',
      visible: 'mandatory',
    },
    // {
    //   prop: 'role',
    //   type: 'string',
    //   name: 'USER_ROLE',
    //   visible: true,
    //   disableSorting: true,
    // },
  ];

  playlistTableColumns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'name',
      type: 'string',
      name: 'PLAYLIST',
      visible: 'mandatory',
    },
    {
      fieldName: 'connectedResourceGroupName',
      type: 'string',
      name: 'CONNECTED_RESOURCE_GROUP',
      visible: true,
    },
  ];

  connectedPlaylistTableList$: Observable<string[] | undefined> =
    this.resourceGroupBeingEdited$.pipe(
      map((resourceGroupId) =>
        !resourceGroupId ? [] : [resourceGroupId + '-playlist-table']
      )
    );
  // connectedPlaylistTableList$: Observable<string[] | undefined> =
  //   this.resourceGroups$.pipe(
  //     combineLatestWith(this.activeAccordionPanelIds$),
  //     // filter out resource groups with closed accordion panels
  //     map(([resourceGroups, activeAccordionPanelIds]) =>
  //       resourceGroups?.filter(
  //         ({ id }) => activeAccordionPanelIds.includes(id)
  //         // this.activeAccordionPanelIds.includes(id)
  //       )
  //     ),
  //     // transform into list of strings w/ <resourceGroupId>-playlist-table format
  //     map((resourceGroups) =>
  //       resourceGroups?.map(
  //         (resourceGroup) => resourceGroup.id + '-playlist-table'
  //       )
  //     )
  //   );

  connectedUserTableList$: Observable<string[] | undefined> =
    this.resourceGroupBeingEdited$.pipe(
      map((resourceGroupId) =>
        !resourceGroupId ? [] : [resourceGroupId + '-user-table']
      )
    );
  // connectedUserTableList$: Observable<string[] | undefined> =
  //   this.resourceGroups$.pipe(
  //     combineLatestWith(this.activeAccordionPanelIds$),
  //     // filter out resource groups with closed accordion panels
  //     map(([resourceGroups, activeAccordionPanelIds]) =>
  //       resourceGroups?.filter(({ id }) => activeAccordionPanelIds.includes(id))
  //     ),
  //     // transform into list of strings w/ <resourceGroupId>-user-table format
  //     map((resourceGroups) =>
  //       resourceGroups?.map((resourceGroup) => resourceGroup.id + '-user-table')
  //     )
  //   );

  isAnAccordionPanelOpen$: Observable<boolean> =
    this.activeAccordionPanelIds$.pipe(
      map((activeAccordionPanelIds) => activeAccordionPanelIds.length > 0)
    );

  ngOnInit(): void {}

  onAddResourceGroup() {
    this.resourceGroups$
      .pipe(
        // take once from the resourceGroup$ stream.
        take(1),
        // take(1) prevents a potential infinite loop since
        // the mutation below creates a new resource group and
        // resourceGroups$ is a stream that emits when there is
        // change in resourceGroups (eg. adding a new resource group)

        // switch into createResourceGroup mutation (observable)
        switchMap((resourceGroups) => {
          const generatedName = `Resource Group #${
            (resourceGroups?.length ?? 0) + 1
          }`;

          return this.resourceGroupService.createResourceGroup({
            name: generatedName,
          });
        })
      )
      .subscribe();
  }

  onDeleteResourceGroup(id: string) {
    this.resourceGroupService.deleteResourceGroupById(id).subscribe();
    // note: Apollo mutations finish after a single emit, so we don't need
    // to unsubscribe or do SubSink then unsubscribe
    // source: https://github.com/kamilkisiela/apollo-angular/issues/1191#issuecomment-800609287
  }

  onEditResourceGroup(id?: string) {
    // this.isEditing$.next(!this.isEditing$.value);
    this.resourceGroupBeingEdited$.next(id ?? null);
    console.log('onEditResourceGroup', id);
  }

  onCancelEditing(id?: string) {
    this.resourceGroupBeingEdited$.next(null);
  }

  onAccordionPanelChange(id: string) {
    const newActiveAccArray = this.activeAccordionPanelIds$.getValue();

    if (newActiveAccArray.indexOf(id) === -1) {
      newActiveAccArray.push(id);
    } else {
      newActiveAccArray.splice(newActiveAccArray.indexOf(id), 1);
    }

    // console.log('activeAccordionPanelIds: ', activeAccordionPanelIds); // DEBUG

    this.activeAccordionPanelIds$.next(newActiveAccArray);
  }

  toIPlaylistForResourceGroupPlaylistList(
    playlist: PlaylistForResourceGroupPlaylistListFragment,
    resourceGroupList: IResourceGroup[]
  ): IPlaylistForResourceGroupPlaylistList {
    const { id, name, resourceGroupId } = playlist;

    // get resource group name
    const resourceGroupName = resourceGroupList.find(
      (x) => x.id === resourceGroupId
    )?.name;

    return {
      id,
      name,
      connectedResourceGroupName: resourceGroupName ?? null,
    };
  }
}
