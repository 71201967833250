import { NgModule } from '@angular/core';
import { StoreModule as NgrxStoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects, LocationEffects } from '@desquare/store/effects';
import {
  profileReducer,
  userReducer,
  appReducer,
  locationReducer,
  channelReducer,
} from '@desquare/store/reducers';
import { deviceReducer } from './reducers/device.reducer';

@NgModule({
  imports: [
    NgrxStoreModule.forRoot({
      app: appReducer,
      profile: profileReducer,
      user: userReducer,
      channel: channelReducer,
      device: deviceReducer,
      location: locationReducer,
    }),
    EffectsModule.forRoot([UserEffects, LocationEffects]),
  ],
})
export class StoreModule {}
