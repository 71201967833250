<app-loader [message]="loaderMessage" *ngIf="loading"></app-loader>

<div
  [hidden]="loading"
  class="container-fluid content-container-card manage-channel-container"
>
  <!-- <div class="card-box"> -->
  <div>
    <!-- <div class="row">
      <div class="col-12 text-start">
        <h1 class="mb-0">{{ location?.name || ('NO_DATA' | translate) }}</h1>
        <hr />
      </div>
    </div> -->
    <div class="row">
      <div class="col-12">
        <app-location-settings
          [hidden]="currentTab !== tabs.SETTINGS"
          [location]="location"
          (locationUpdated)="location = $event"
          (loaderMessage)="loaderMessage = $event"
          (loading)="settingsLoading = $event"
        ></app-location-settings>

        <app-location-detail
          *ngIf="currentTab === tabs.DETAILS"
        ></app-location-detail>
      </div>
    </div>
  </div>
</div>
