<div class="init-container">
  <div class="d-flex align-items-end p-4 h-100">
    <div class="logo-container">
      <designage-initialization-logo
        [@fadeInOnEnter]
      ></designage-initialization-logo>
      <p class="text-end">v {{ version }}</p>
    </div>
  </div>
  <div class="h-100 p-4">
    <div id="spinnerContainer" class="spinner-container">
      <div class="spinner-border" role="status"></div>
    </div>
    <div id="loadingText" class="loading-text text-center">...</div>
  </div>
</div>
