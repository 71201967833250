import { initialProfileState, IProfileState } from '@desquare/store/states';
import { profileActions } from '@desquare/store/actions';
import { createReducer, on, Action } from '@ngrx/store';

const reducer = createReducer(
  initialProfileState,
  on(profileActions.switchActiveProfile, (state, action) => ({
    ...state,
    activeProfile: action.profile,
  })),
  on(profileActions.clearActiveProfile, (state) => ({
    ...state,
    activeProfile: undefined,
  })),
  on(profileActions.setActiveProfileLocations, (state, action) => {
    const activeProfile = state.activeProfile;

    if (activeProfile) {
      activeProfile.locations = action.locations;
    }
    const returnState: IProfileState = { ...state, activeProfile };

    return returnState;
  })
);

export function profileReducer(
  state: IProfileState | undefined,
  action: Action
) {
  return reducer(state, action);
}
