import { Component } from '@angular/core';
import { Maybe, Location } from '@designage/gql';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslateModule],
  selector: 'app-delete-location-dialog',
  templateUrl: './delete-location-dialog.component.html',
  styleUrls: ['./delete-location-dialog.component.scss'],
})
export class DeleteLocationDialogComponent {
  location?: Maybe<Location>;
}
