<app-form-dialog
  closeButtonText="SAVE"
  [valid]="!!selectedLocation && selectedLocation?.id !== currentLocation?.id"
  [pristine]="false"
  [values]="selectedLocation"
  dismissButtonText="CANCEL"
  headerText="{{ promptMessage | translate }}"
>
  <div class="mb-3">
    <span>{{ 'LOCATION_REPLACEMENT_PROMPT' | translate }}</span>
  </div>
  <app-location-selection
    [profileId]="profileId"
    [selectedLocationId]="currentLocation?.id"
    [isHideEditLocationButton]="true"
    (selectionChange)="selectedLocation = $event"
    (createdLocation)="selectedLocation = $event"
  ></app-location-selection>
</app-form-dialog>
