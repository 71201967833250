import { InMemoryCache } from '@apollo/client';
import {
  GetIsInitializingAppDocument,
  GetActiveProfileDocument,
  GetSelectedChannelDocument,
  GetSelectedDeviceDocument,
  GetSelectedAssetsDocument,
  GetActiveProfileQuery,
  InputMaybe,
  ActiveProfileInput,
  GetIsInitializingAppQuery,
  GetSelectedAssetsQuery,
  Asset,
  SelectedAssetInput,
} from '@designage/gql';

export const writeIsInitializingApp = (
  cache: InMemoryCache,
  input: InputMaybe<boolean>
) => {
  const data: GetIsInitializingAppQuery = {
    isInitializingApp: input || false,
  };

  cache.writeQuery({
    query: GetIsInitializingAppDocument,
    data,
  });

  return input || false;
};

export const writeActiveProfile = (
  cache: InMemoryCache,
  input: InputMaybe<ActiveProfileInput>
) => {
  const data: GetActiveProfileQuery = {
    activeProfile: input,
  };
  cache.writeQuery({
    query: GetActiveProfileDocument,
    data,
  });
  return input;
};

export const initCache = (cache: InMemoryCache) => {
  /* ICEAGE
          cache.writeData({
            query: therightone,
            data: {
              isInitializingApp: true,
              activeProfile: null,
              selectedChannel: null,
              selectedDevice: null,
              selectedAssets: null,
              simulateDateTime: null,
            },
          });*/

  writeIsInitializingApp(cache, true);
  writeActiveProfile(cache, null);

  cache.writeQuery({
    query: GetSelectedChannelDocument,
    data: { selectedChannel: null },
  });
  cache.writeQuery({
    query: GetSelectedDeviceDocument,
    data: { selectedDevice: null },
  });
  cache.writeQuery({
    query: GetSelectedAssetsDocument,
    data: { selectedAssets: null },
  });
};
