<!-- Topbar Start -->
<div class="navbar-custom d-flex align-items-center justify-content-end">
  <!-- Breadcrumbs -->
  @if(!responsiveUiService.isMobileDevice()){
  <xng-breadcrumb class="flex-grow-1 d-flex mx-3" separator=">">
    <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info">
      <ng-containerm [class.fat-breadcrumb-trail]="info === 'fat'">{{
        breadcrumb | translate
      }}</ng-containerm>
    </ng-container></xng-breadcrumb
  >
  }
  <!-- Notification, user and profile dropdown -->
  <ul
    class="list-unstyled topnav-menu float-end mb-0 d-flex align-items-center"
  >
    <li
      class="d-none d-sm-block dropdown notification-list"
      ngbDropdown
      #notificationDropDown="ngbDropdown"
    >
      @if(session.user() && session.profile()){
      <app-notification-list
        [user]="session.user()"
        [profile]="session.profile()"
      ></app-notification-list>
      }
    </li>
    <!-- User dropdown -->
    <li
      class="dropdown notification-list"
      ngbDropdown
      #userDropDown="ngbDropdown"
    >
      <a
        class="nav-link dropdown-toggle no-caret nav-user me-0 waves-effect waves-light"
        ngbDropdownToggle
        id="userDropdown"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <div class="profile-picture-container">
          <img
            [src]="activeUserProfilePicture"
            alt="user-image"
            class="profile-image"
          />
        </div>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right profile-dropdown p-0"
        aria-labelledby="userDropDown"
        ngbDropdownMenu
      >
        <div class="dropdown-header noti-title shadowed-box p-2">
          <h5 class="text-overflow m-0 text-white">
            {{ 'ACCOUNT_INFORMATION' | translate }}:
          </h5>
          <br />
          <h5 class="text-white">{{ 'USER_NAME' | translate }}:</h5>
          <h5 class="text-white">{{ session.user()?.displayName }}</h5>
          <h5 class="text-white">{{ 'USER_ROLES' | translate }}:</h5>
          <h5 class="text-white">
            @if(userRole){
            <span>{{ userRole }}</span>
            } @else {
            <span>{{ 'NO_ROLE' | translate }}</span>
            }
          </h5>

          <hr />
          @if(uiMode$ | async; as uiMode){
          <li
            class="mx-1 d-flex align-items-center form-check form-switch py-1 rounded-pill"
          >
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              [checked]="uiMode.simpleUiMode"
              (click)="switchUiMode(!uiMode.simpleUiMode)"
              id="uiSwitch"
            />
            <label class="px-2 form-check-label" for="uiSwitch"
              >{{ uiMode.simpleUiMode ? 'Simple' : 'Advanced' }} UI
            </label>
          </li>
          }
          <hr />
          <h5>
            <a
              href="javascript:void(0);"
              (click)="
                settings(); $event.stopPropagation(); userDropDown.close()
              "
            >
              <i class="ri-settings-line"></i>
              <span>{{ 'SETTINGS' | translate }}</span>
            </a>
          </h5>
          <h5>
            <a
              href="javascript:void(0);"
              (click)="logout(); $event.stopPropagation(); userDropDown.close()"
            >
              <i class="ri-logout-box-line"></i>
              <span>{{ 'LOGOUT' | translate }}</span>
            </a>
          </h5>
        </div>
      </div>
    </li>
    <!-- profile switching dropdown -->
    <li
      class="dropdown notification-list me-3"
      ngbDropdown
      #profileDropDown="ngbDropdown"
    >
      <a
        class="nav-link dropdown-toggle no-caret nav-user me-0 waves-effect waves-light"
        ngbDropdownToggle
        id="profileDropdown"
        href="javascript: void(0);"
        role="button"
        aria-haspopup="false"
        aria-expanded="false"
      >
        <span class="pro-user-name ms-1">
          @if(!session.profile()?.name){
          <span>
            {{ 'NO_PROFILE' | translate }}
          </span>
          } @else {
          <span>
            {{ session.profile().name }}
          </span>
          }
          <i class="mdi mdi-chevron-down"></i>
        </span>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right profile-dropdown p-0"
        aria-labelledby="profileDropdown"
        ngbDropdownMenu
      >
        <!-- item-->
        @if(loading){
        <app-loader [message]="loaderMessage"></app-loader>
        } @else {
        <div
          class="dropdown-header noti-title profile-switcher shadowed-box p-2"
        >
          <h5 class="text-overflow m-0 text-white">
            {{ 'PROFILES' | translate }}:
          </h5>
          @if(session.profile()){
          <div class="profile-name">
            <h5
              class="text-white bg-primary p-1 me-2 rounded left"
              [title]="session.profile()?.name"
            >
              {{ session.profile()?.name }}
            </h5>
            @if(canManageProfiles && session.profile()?.active){
            <span class="badge bg-success right">{{
              'ACTIVE' | translate
            }}</span
            >} @if(canManageProfiles && !session.profile()?.active){
            <span class="badge right">{{ 'DEACTIVATED' | translate }}</span
            >}
          </div>
          } @if(!session.profiles()?.length){
          <h5 class="text-white profile-name">
            {{ 'NO_PROFILES_FOUND' | translate }}
          </h5>
          }
          <hr />
          <div class="profile-name-list">
            @for(profile of displayedProfiles;track profile.id){
            <div
              class="profile-name"
              (click)="
                switchProfile(profile);
                $event.stopPropagation();
                profileDropDown.close()
              "
            >
              @if(profile.active){
              <h5 class="text-white me-2 left" [title]="profile.name">
                {{ profile.name }}
              </h5>
              }
            </div>
            }
          </div>

          <button
            type="button"
            (click)="showProfileDialog()"
            class="btn btn-sm btn-outline-primary"
          >
            {{ 'SHOW_DETAILS' | translate : { total: totalProfiles } }}
          </button>
          @if(totalProfiles > 10 && showAddProfileButton){
          <span class="divider"></span>} @if(showAddProfileButton){
          <button
            [routerLink]="['/profile/create']"
            class="btn btn-sm btn-outline-primary ms-2"
            (click)="profileDropDown.close()"
          >
            {{ 'ADD_PROFILE' | translate }}</button
          >}
        </div>
        }
      </div>
    </li>
  </ul>
</div>
<!-- end Topbar -->
