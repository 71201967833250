import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpClientModule } from '@angular/common/http';
import { environment as env } from '@desquare/environments';
import { setContext } from '@apollo/client/link/context';
import { resolvers } from '@designage/gql-resolvers';
import { Router } from '@angular/router';
import { onError } from '@apollo/client/link/error';
import { split, InMemoryCache } from '@apollo/client';
// NOTE: HttpLink can be imported from @apollo/client or from apollo-angular!!! USE apollo-angular!!!
import { HttpLink } from 'apollo-angular/http';
import fragmentMatcher from '@designage/gql-lib/fragmentMatcher.json';
import { getMainDefinition } from '@apollo/client/utilities';
import { initCache } from '@desquare/utils';
import { SessionService } from '@desquare/services';
import { lastValueFrom } from 'rxjs';
import { SSELink } from './graphql-sse';

/** this error is returned by API when the token provided in header is valued but invalid/expired */
const UNAUTHENTICATED_ERROR = 'API_ERROR_401';

@NgModule({
  imports: [ApolloModule],
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (
        sessionService: SessionService,
        router: Router,
        _httpLink: HttpLink
      ) => {
        /** error management link */
        const errorLink = onError(
          ({ operation, response, graphQLErrors, networkError }) => {
            const currentUrl = router.url;

            if (graphQLErrors) {
              // gql related errors
              graphQLErrors.map((gqlError) => {
                const { message, locations, path } = gqlError;

                // TODO: mask this in prod
                let errorMsg = env.production
                  ? 'gql error'
                  : `[GraphQL error]: Operation: ${
                      operation.operationName
                    }, Message: ${message}, Path: ${path}, Location:${JSON.stringify(
                      locations
                    )}`;
                if (!message) {
                  errorMsg = `[GraphQL error]: Operation: ${
                    operation.operationName
                  } ${JSON.stringify(gqlError)}`;
                }
                if (env.production) {
                  console.error(errorMsg);
                } else {
                  console.error(errorMsg, operation);
                }

                if (
                  (message && message.includes('Unauthenticated')) ||
                  message.includes(UNAUTHENTICATED_ERROR)
                ) {
                  //   then probably an anonymous user is requesting protected data
                  //   NOTE: redirect to login should not be done if the current route is not needing a login...
                  console.error('GQL REQUIRED LOGIN');
                  sessionService.login(currentUrl);
                  // sessionService.logout('cms');
                  /*
                  sessionService.isAuthenticated$.subscribe((value) => {
                    if (!value) {
                      console.error('GQL REQUIRED LOGIN');
                      // sessionService.login(currentUrl);
                      sessionService.logout('cms');
                    }
                  });*/
                }
              });
            }

            // API OFFLINE OR TOKEN EXPIRED SHOULD END HERE
            if (networkError) {
              const extraMsg = env.production ? '' : `during ${operation}`;
              const { error } = networkError as any;
              if (error) {
                const errors: Error[] | undefined = error.errors;
                if (errors?.find((e) => e.message === UNAUTHENTICATED_ERROR)) {
                  console.error('USER TOKEN ERROR!');
                  sessionService.login(currentUrl);
                  return;
                }
              }

              console.error(
                `[Network error]: ${extraMsg}`,
                error || networkError
              );

              // probably we're disconnected from API, let's try to reload
              setTimeout(() => {
                // router.navigateByUrl(router.);

                // router
                //  .navigateByUrl('/', { skipLocationChange: true })
                //  .then(() => {
                //     router.navigate([currentUrl]);
                //  });

                // if user session is reconnected will automatically reload current page
                lastValueFrom(sessionService.isAuthenticated$).then(
                  (isAuthenticated) => {
                    if (isAuthenticated) {
                      router.navigate([currentUrl]);
                    } else {
                      sessionService.login(currentUrl);
                    }
                  }
                );
              }, 3000);
            }
          }
        );

        /** HTTP link for normal query/mutation traffic */
        const httpLink = _httpLink.create({
          // new HttpLink({
          uri: env.urls.designageGQLEndpoint,
        });

        const getAuthInfo = async () => {
          const token = await sessionService.getAuth0Token();
          const profileId = token
            ? sessionService.getCurrentProfileId()
            : undefined;

          return { token, profileId };
        };
        const getAuthHeaders = async () => {
          const { token, profileId } = await getAuthInfo();

          return {
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
            ...(profileId ? { 'x-profile-id': profileId } : {}),
          };
        };

        /** SSE Link for subscriptions  */
        const sseLink = new SSELink({
          url: env.urls.designageGQLEndpoint,
          headers: getAuthHeaders,
        });

        /** if authenticated adds Authentication Headers on HTTP as well as was requests */
        const authLink = setContext(
          async (operation, { headers, ...context }) => {
            // const { token, profileId } = await getAuthInfo();
            const authHeaders = await getAuthHeaders();
            if (!env.production) {
              console.log(`[gql module] operation ${operation.operationName}`);
            }
            return {
              headers: {
                ...headers,
                // ...(token ? { Authorization: `Bearer ${token}` } : {}),
                // ...(profileId ? { 'x-profile-id': profileId } : {}),
                ...authHeaders,
              },
              ...context,
            };
          }
        );

        /** Send query request based on the type definition */
        const splitLink = split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          errorLink.concat(sseLink),
          authLink.concat(errorLink).concat(httpLink)
        );

        const gqlEndpointLink = splitLink;

        const cache = new InMemoryCache({
          possibleTypes: fragmentMatcher.possibleTypes,
          typePolicies: {
            Auth0Profile: {
              /**
               * This is a custom identifier for Auth0Profile typesnames
               */
              keyFields: ['email'],
            },
          },
        });

        initCache(cache);
        // TODO: check this:
        // cache.writeQuery({query: GetSimulateDateTimeDocument})

        return {
          cache,
          resolvers,
          link: gqlEndpointLink,
        };
      },
      deps: [SessionService, Router, HttpLink],
    },
  ],
})
export class GraphqlModule {}
