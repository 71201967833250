import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlidePanelComponent } from '@designage/app/shared/slide-panel/slide-panel.component';
import { ChannelManageComponent } from '../channel-manage/channel-manage.component';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, ChannelManageComponent],
  selector: 'app-channel-manage-slider',
  template: `<app-slide-panel
    (closePanel)="closeChannelManagePanel()"
    [fullscreenTooltip]="'TOGGLE_CHANNEL_TABLE'"
    [closePanelTooltip]="'CLOSE_THIS_CHANNEL'"
    [fullscreen]="true"
    [openWidth]="20"
    [panelContent]="'channelManage'"
  >
    <app-channel-manage></app-channel-manage>
  </app-slide-panel> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelManageSliderComponent {
  constructor(private router: Router, private route: ActivatedRoute) {}

  closeChannelManagePanel() {
    // this navigates to the current path before 2 slashes, example:
    // current path: 'channels/manage/<channelId>
    // target path: 'channels
    this.router.navigate(['../../../'], { relativeTo: this.route });
  }
}
