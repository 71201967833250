<app-confirm-dialog [useDangerButton]="true" dismissButtonText="CANCEL" closeButtonText="PROCEED">
  <p>
    {{ 'DEACTIVATE_USER_MESSAGE' | translate }}
  </p>
  <p *ngIf="hasProfile" class="danger">
    {{ 'DEACTIVATE_USER_AS_OWNER_PROMPT' | translate }}
  </p>
  <p class="danger">
    {{ 'DEACTIVATE_USER_PROMPT' | translate }}
  </p>
</app-confirm-dialog>
