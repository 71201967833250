@if(updateAvailable){
<div
  [@updateSlideIn]
  class="position-absolute d-flex justify-content-center w-100 pt-2"
  style="z-index: 8999"
>
  <button
    class="update-notification btn btn-light px-5 py-2"
    (click)="reloadWindow()"
    [disabled]="updateLoading"
  >
    @if(!updateLoading){
    <span>
      {{ 'SW_UPDATE_AVAILABLE' | translate }}
    </span>
    } @else {
    <div class="spinner-border spinner-border-sm text-dark" role="status">
      <span class="sr-only">reloading...</span>
    </div>
    }
  </button>
</div>
}
<div
  class="main-layout"
  [ngClass]="
    responsiveUiService.isMobileDevice()
      ? 'small-screen'
      : isSidebarCollapsed() || false
      ? 'condensed-sidebar'
      : 'full-sidebar'
  "
>
  <app-topbar
    style="grid-column: 2; grid-row: 1"
    (settingsButtonClicked)="onSettingsButtonClicked()"
  ></app-topbar>

  <app-sidebar
    #sidebarComponent
    style="grid-column: 1; grid-row: 1 / -1; z-index: 99"
  ></app-sidebar>

  <div style="grid-column: 2; grid-row: 2" class="content-page">
    <!-- content -->
    <router-outlet></router-outlet>

    <!-- footer -->
  </div>
  <app-footer style="grid-column: 2; grid-row: 3"></app-footer>
</div>
