import { Injectable, OnDestroy } from '@angular/core';
import {
  CreateResourceGroupGQL,
  CreateResourceGroupInput,
  DeleteResourceGroupGQL,
  GetResourceGroupsByProfileIdGQL,
  GetResourcesByProfileIdGQL,
  PlaylistForResourceGroupPlaylistListFragment,
  ResourceGroupFragment,
  UpdateResourceGroupGQL,
  UpdateResourceGroupInput,
  UserForResourceGroupUserListFragment,
} from '@designage/gql';
import { EMPTY, Observable, map, switchMap, tap } from 'rxjs';
import { CurrentUserService } from '../current-user/current-user.service';
import { IResourceGroup, IUserForResourceGroup } from '@desquare/interfaces';
import { ToasterService } from '../toaster/toaster.service';

@Injectable({
  providedIn: 'any',
  // note: using providedIn: 'any', makes this service's instance to be
  // shared among all the eagerly loaded modules
  // more info: https://stackoverflow.com/a/72561645
})
export class ResourceGroupService implements OnDestroy {
  constructor(
    private createResourceGroupGQL: CreateResourceGroupGQL,
    private deleteResourceGroupGQL: DeleteResourceGroupGQL,
    private updateResourceGroupGQL: UpdateResourceGroupGQL,
    private getResourceGroupsByProfileIdGQL: GetResourceGroupsByProfileIdGQL,
    private getResourcesByProfileIdGQL: GetResourcesByProfileIdGQL,
    private currentUserService: CurrentUserService,
    private toasterService: ToasterService
  ) {}

  currentProfileId$ = this.currentUserService
    .getCurrentProfile()
    .pipe(map((profile) => (!profile ? null : profile.id)));

  resourceGroups$ = this.currentProfileId$.pipe(
    switchMap((profileId) => {
      return !profileId
        ? EMPTY
        : this.getResourceGroupsByProfileIdGQL.watch(
            { id: profileId },
            { fetchPolicy: 'cache-and-network' }
          ).valueChanges;
    }),
    map(({ data, loading, errors }) => {
      console.log('TRACE CHECK resourceGroupService: ', data);

      return data.profile?.resourceGroups.map((resourceGroup) => {
        // transform ResourceGroupFragment to IResourceGroup
        return this.toIResourceGroup(resourceGroup);
      });
    })
  );

  resources$ = this.currentProfileId$.pipe(
    switchMap((profileId) => {
      return !profileId
        ? EMPTY
        : this.getResourcesByProfileIdGQL.watch(
            { id: profileId },
            { fetchPolicy: 'cache-and-network' }
          ).valueChanges;
    })
  );

  users$: Observable<UserForResourceGroupUserListFragment[]> =
    this.resources$.pipe(map(({ data }) => data.profile?.users ?? []));

  playlists$: Observable<PlaylistForResourceGroupPlaylistListFragment[]> =
    this.resources$.pipe(map(({ data }) => data.profile?.playlists ?? []));

  ngOnDestroy(): void {
    // this.subs.unsubscribe();
  }

  toIResourceGroup(resourceGroup: ResourceGroupFragment): IResourceGroup {
    const { id, name, userResourceGroups, playlists } = resourceGroup;

    const modifiedUsers: IUserForResourceGroup[] = !userResourceGroups
      ? []
      : userResourceGroups.map((userResourceGroup) => {
          return {
            id: userResourceGroup.user.id,
            displayName: userResourceGroup.user.displayName,
            canWrite: userResourceGroup.canWrite ?? false,
          };
        });

    return {
      id,
      name,
      playlists: playlists || [],
      users: modifiedUsers,
    };
  }

  createResourceGroup(input: Omit<CreateResourceGroupInput, 'profileId'>) {
    const { name, playlistIds, users } = input;

    return this.currentProfileId$.pipe(
      switchMap((profileId) => {
        return !profileId
          ? EMPTY
          : this.createResourceGroupGQL.mutate(
              { input: { name, profileId, playlistIds, users } },
              {
                refetchQueries: [
                  {
                    query: this.getResourceGroupsByProfileIdGQL.document,
                    variables: {
                      id: profileId,
                    },
                  },
                  {
                    query: this.getResourcesByProfileIdGQL.document,
                    variables: {
                      id: profileId,
                    },
                  },
                ],
                awaitRefetchQueries: true,
              }
            );
      }),
      tap(({ data }) => {
        if (data?.createResourceGroup.isSuccessful)
          this.toasterService.success('RESOURCE_GROUP_CREATE_SUCCESS');
      })
    );
  }

  /**
   * Deletes the resource group by id
   * - this is a wrapper function for the actual apollo observable mutation
   * - automatically uses the current profile id
   *
   * @param id
   * @returns
   */
  deleteResourceGroupById(id: string) {
    return this.currentProfileId$.pipe(
      switchMap((profileId) => {
        return !profileId
          ? EMPTY
          : this.deleteResourceGroupGQL.mutate(
              { id },
              {
                refetchQueries: [
                  {
                    query: this.getResourceGroupsByProfileIdGQL.document,
                    variables: { id: profileId },
                  },
                  {
                    query: this.getResourcesByProfileIdGQL.document,
                    variables: {
                      id: profileId,
                    },
                  },
                ],
                awaitRefetchQueries: true,
              }
            );
      }),
      tap(({ data }) => {
        if (data?.deleteResourceGroup.isSuccessful)
          this.toasterService.success('RESOURCE_GROUP_DELETE_SUCCESS');
      })
    );
  }

  updateResourceGroup(input: UpdateResourceGroupInput) {
    return this.currentProfileId$.pipe(
      switchMap((profileId) => {
        return !profileId
          ? EMPTY
          : this.updateResourceGroupGQL.mutate(
              { input },
              {
                refetchQueries: [
                  {
                    query: this.getResourceGroupsByProfileIdGQL.document,
                    variables: { id: profileId },
                  },
                  {
                    query: this.getResourcesByProfileIdGQL.document,
                    variables: {
                      id: profileId,
                    },
                  },
                ],
                awaitRefetchQueries: true,
              }
            );
      }),
      tap(({ data }) => {
        if (data?.updateResourceGroup.isSuccessful)
          this.toasterService.success('RESOURCE_GROUP_UPDATE_SUCCESS');
      })
    );
  }
}
