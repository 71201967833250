import { Component, Input, OnInit } from '@angular/core';
import { DeviceHistoricalDashboardComponent } from '@desquare/components/common/src/device-historical-dashboard/device-historical-dashboard.component';
import { ChartType } from '@desquare/enums';
import { CurrentUserService, DeviceDataService } from '@desquare/services';
import { DeviceStatusData, DeviceMonitorData } from '@desquare/types';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'ts-luxon';
import { Observable, map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageEchartComponent } from '@desquare/components/common/src/designage-echart/designage-echart.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    NgbTooltip,
    DesignageEchartComponent,
  ],
  selector: 'designage-device-monitor',
  templateUrl: './device-monitor.component.html',
  styleUrls: ['./device-monitor.component.scss'],
})
export class DeviceMonitorComponent implements OnInit {
  @Input() deviceId!: string;
  profileId!: string;

  timespanMinutes = 720;
  readonly ChartType = ChartType;

  deviceStatusData$!: Observable<DeviceStatusData[]>; //The observable that holds the monitor data
  deviceStatusDataForEchart$!: Observable<[string, number][]>; //The observable that holds the monitor data
  deviceInfoData$!: Observable<DeviceMonitorData[]>; //The observable that holds the monitor data
  deviceTempDataForEchart$!: Observable<[string, number][]>; //The observable that holds the monitor data
  deviceCpuDataForEchart$!: Observable<[string, number][]>; //The observable that holds the monitor data

  modalCloseResult = '';

  constructor(
    private deviceDataService: DeviceDataService,
    private currentUserService: CurrentUserService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.profileId = this.currentUserService.getCurrentProfileId();
    this.initData(this.deviceId, this.profileId);
  }

  async initData(deviceId: string, profileId: string) {
    this.deviceStatusDataForEchart$ = this.deviceDataService
      .getDeviceMqttStatus$(profileId, deviceId)
      .pipe(
        map((data) => {
          const dataArray: [string, number][] = Object.values(data).map(
            (item) => {
              return [DateTime.fromISO(item.ts).toISO()!, item.online ? 1 : 0];
            }
          );
          return dataArray;
        })
      );

    this.deviceTempDataForEchart$ = this.deviceDataService
      .getDeviceMqttInfo(profileId, deviceId)
      .pipe(
        map((data) =>
          data.filter((item) => item.temp != null && item.temp != 0)
        )
      )
      .pipe(
        map((data) => {
          const dataArray: [string, number][] = Object.values(data).map(
            (item) => {
              return [DateTime.fromISO(item.ts).toISO()!, item.temp || 0];
            }
          );
          return dataArray.filter(
            (d) => DateTime.fromISO(d[0]) > DateTime.now().minus({ hours: 12 })
          );
        })
      );

    this.deviceCpuDataForEchart$ = this.deviceDataService
      .getDeviceMqttInfo(profileId, deviceId)
      .pipe(
        map((data) => data.filter((item) => item.cpu != null && item.cpu != 0))
      )
      .pipe(
        map((data) => {
          const dataArray: [string, number][] = Object.values(data).map(
            (item) => {
              return [DateTime.fromISO(item.ts).toISO()!, item.cpu || 0];
            }
          );
          return dataArray.filter(
            (d) => DateTime.fromISO(d[0]) > DateTime.now().minus({ hours: 12 })
          );
        })
      );
  }

  openModal() {
    const modal = this.modalService.open(DeviceHistoricalDashboardComponent, {
      ariaLabelledBy: 'Device-Historical-Dashboard',
      backdrop: 'static',
      size: 'xl',
      windowClass: 'stat-modal',
    });

    modal.componentInstance.modal = modal;
    modal.componentInstance.deviceId = this.deviceId;
  }
}
