import { Component, EventEmitter, Output } from '@angular/core';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [ConfirmDialogComponent, TranslateModule],
  selector: 'app-device-remove-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="true"
    [dismissButtonText]="'CANCEL'"
    [closeButtonText]="'DELETE'"
  >
    <p>
      {{ 'DEACTIVATE_DEVICE_MESSAGE' | translate }}
    </p>
    <p class="danger">
      {{ 'DEACTIVATE_DEVICE_PROMPT' | translate }}
    </p>
  </app-confirm-dialog> `,
})
export class DeviceRemoveDialogComponent {
  @Output() close = new EventEmitter<boolean>();

  onCancel() {
    this.close.emit(false);
  }

  onAccept() {
    this.close.emit(true);
  }
}
