<!-- users table -->
<h5 class="px-2 pt-2 card-title">
  {{ 'USERS' | translate }}
</h5>
<ng-container *ngIf="(resourceGroupUsers$ | async).length; else noUsersInGroup">
  <designage-data-table
    [data]="resourceGroupUsers$ | async"
    style="max-height: 30vh"
    [columns]="userTableColumns"
    [alwaysSort]="true"
    [showFilter]="false"
    [columnSelector]="false"
    [showMultiSelect]="false"
    [showFooter]="false"
    [showDeleteRow]="true"
    [readOnly]="!isEditing"
    [tableElementId]="userTableId$ | async"
    [cdkDropList]="true"
    (dataDropped)="onUserDataDropped($event)"
    (rowDelete)="onUserTableRowDelete($event)"
    (rowCheckboxChange)="onUserTableRowCheckboxChange($event)"
  >
  </designage-data-table>
</ng-container>
<ng-template #noUsersInGroup>
  <div
    class="card p-5"
    cdkDropList
    [id]="userTableId$ | async"
    (cdkDropListDropped)="onUserDataDropped($event)"
  >
    {{ 'DRAG_AND_DROP_USER_HERE' | translate }}
  </div>
</ng-template>

<hr />

<!-- playlist table -->
<h5 class="px-2 pt-2 card-title">
  {{ 'PLAYLISTS' | translate }}
</h5>

<ng-container
  *ngIf="(resourceGroupPlaylists$ | async).length; else noPlaylistsInGroup"
>
  <designage-data-table
    [data]="resourceGroupPlaylists$ | async"
    style="max-height: 30vh"
    [columns]="playlistTableColumns"
    [alwaysSort]="true"
    [showFilter]="false"
    [columnSelector]="false"
    [showMultiSelect]="false"
    [showFooter]="false"
    [showDeleteRow]="true"
    [readOnly]="!isEditing"
    [tableElementId]="playlistTableId$ | async"
    [cdkDroppable]="true"
    (dataDropped)="onPlaylistDataDropped($event)"
    (rowDelete)="onPlaylistTableRowDelete($event)"
  >
  </designage-data-table>
</ng-container>
<ng-template #noPlaylistsInGroup>
  <div
    class="card p-5"
    cdkDropList
    [id]="playlistTableId$ | async"
    (cdkDropListDropped)="onPlaylistDataDropped($event)"
  >
    <div>
      {{ 'DRAG_AND_DROP_PLAYLIST_HERE' | translate }}
    </div>
  </div>
</ng-template>

<!-- button group -->
<div class="d-flex w-100 py-4 flex-row-reverse justify-content-between">
  <!-- delete resource group button -->
  <div class="">
    <button
      class="btn btn-outline-danger mx-2"
      (click)="onDelete(); $event.stopPropagation()"
    >
      {{ 'RESOURCE_GROUP_DELETE' | translate }}
    </button>
  </div>

  <!-- edit resource group button -->
  <div class="">
    <!-- <button
      class="btn mx-2"
      [ngClass]="isEditing ? 'btn-primary' : 'btn-outline-primary'"
      (click)="onEdit(); $event.stopPropagation()"
    >
      {{
        (isEditing ? 'RESOURCE_GROUP_EDITING' : 'RESOURCE_GROUP_EDIT')
          | translate
      }}
    </button> -->
    <ng-container *ngIf="!isEditing; else saveAndCancelButtonGroup">
      <button
        class="btn btn-outline-primary mx-2"
        (click)="onEdit(); $event.stopPropagation()"
        [disabled]="!enableEditing"
      >
        {{ 'RESOURCE_GROUP_EDIT' | translate }}
      </button>
    </ng-container>

    <ng-template #saveAndCancelButtonGroup>
      <button
        class="btn btn-outline-primary mx-2"
        (click)="onEditCancel(); $event.stopPropagation()"
      >
        {{ 'RESOURCE_GROUP_EDIT_CANCEL' | translate }}
      </button>
      <button
        class="btn btn-outline-success mx-2"
        (click)="onEditSave(); $event.stopPropagation()"
      >
        {{ 'RESOURCE_GROUP_EDIT_SAVE' | translate }}
      </button>
    </ng-template>
  </div>
</div>
