<div class="d-flex flex-column h-100">
  <div class="d-flex mb-2 justify-content-between align-items-center">
    <h4 class="flex-grow-1">
      {{ 'LOCATIONS_CONNECTED_TO_THIS_PROFILE' | translate }}
    </h4>
    <button
      *ngIf="showAddLocationButton"
      class="btn btn-outline-primary"
      (click)="openCreateLocationDialog()"
    >
      {{ 'ADD_LOCATION' | translate }}
    </button>
  </div>

  <designage-data-table
    configId="location-list"
    [data]="locations"
    [columns]="desColumns"
    [loading]="dataTableLoading"
    [customComponent]="[createdAt, updatedAt]"
    [alwaysSort]="true"
    (rowClick)="onRowClick($event)"
    [rowActiveInSlidingPanel]="selectedChannelId()"
  ></designage-data-table>

  <ng-template #createdAt let-row>
    <table-dateTime
      [data]="row.createdAt"
      [tooltipPipeArgs]="'medium'"
    ></table-dateTime>
  </ng-template>
  <ng-template #updatedAt let-row>
    <table-dateTime
      [data]="row.updatedAt"
      [tooltipPipeArgs]="'medium'"
    ></table-dateTime>
  </ng-template>

  <div
    [class.hidden]="dataTableLoading || loading || !hasNoData"
    class="col-12"
  >
    <hr />
    <h4 class="unavailable-message">
      {{ 'NO_LOCATIONS_FOUND' | translate }}
    </h4>
  </div>
</div>

<!-- Location Manage Slide Panel -->
<router-outlet *ngIf="enableSlidePanel"></router-outlet>
