import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SubSink } from 'subsink';
import {
  Maybe,
  UpdateLocationMutation,
  GetLocationForLocationManagePageQuery,
  GetProfileLocationsGQL,
  DeleteLocationInput,
  ProfileLocationsFragment,
  Location,
  Channel,
} from '@designage/gql';
import {
  ToasterService,
  RoutingStateService,
  LocationService,
  EncryptionService,
  CurrentUserService,
  SessionService,
} from '@desquare/services';
import {
  NgbModal,
  NgbNav,
  NgbNavItem,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { IDatatableRowActivateArgs, ILocationForm } from '@desquare/interfaces';
import { ApolloError } from '@apollo/client/errors';
import { DeleteLocationDialogComponent } from '../delete-location-dialog/delete-location-dialog.component';
import { LocationReplacementDialogComponent } from '../location-replacement-dialog/location-replacement-dialog.component';
import { DuplicateLocationNameDialogComponent } from '../duplicate-location-name-dialog/duplicate-location-name-dialog.component';
import { cloneDeep } from 'lodash';
import { DatatableRowActivationType } from '@desquare/enums';
import { TranslateModule } from '@ngx-translate/core';
import { ChannelListComponent } from '../../channel/channel-list/channel-list.component';
import { LocationFormComponent } from '../location-form/location-form.component';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    NgTemplateOutlet,
    NgbNavOutlet,
    LocationFormComponent,
    ChannelListComponent,
    TranslateModule,
  ],
  selector: 'app-location-settings',
  templateUrl: './location-settings.component.html',
  styleUrls: ['./location-settings.component.scss'],
})
export class LocationSettingsComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  // @Input() location!: Maybe<GetLocationForLocationManagePageQuery['location']>;
  private _location!: Maybe<GetLocationForLocationManagePageQuery['location']>;
  @Input() set location(
    location: Maybe<GetLocationForLocationManagePageQuery['location']>
  ) {
    if (location) {
      this.channels = location.channels;
      this._location = location;
    }
  }
  get location() {
    return this._location;
  }
  @Input() ownerId?: string;
  @Output() loading = new EventEmitter<boolean>();
  @Output() loaderMessage = new EventEmitter<string>();
  @Output() locationUpdated = new EventEmitter<
    UpdateLocationMutation['updateLocation']['location']
  >();

  isFormValid = false;
  isFormPristine = false;
  isConfirmationOpen = false;
  profileId!: string;
  duplicateLocations?: ProfileLocationsFragment[] = [];
  channels?: ProfileLocationsFragment['channels'];
  locationForm!: ILocationForm;

  tabs = [{ title: 'Details' }, { title: 'Channels' }];

  constructor(
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private router: Router,
    private routingStateService: RoutingStateService,
    private getProfileLocationsGQL: GetProfileLocationsGQL,
    private locationService: LocationService,
    private session: SessionService,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService
  ) {}

  get saveEnabled() {
    return this.isFormValid && !this.isFormPristine;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.initVariables();
  }

  initVariables() {
    this.profileId = this.session.profileId() || '';
  }

  saveLocation() {
    if (this.profileId && this.locationForm) {
      this.loading.emit(true);
      this.loaderMessage.emit('CHECKING_DUPLICATE_LOCATION_NAME');

      this.subs.sink = this.getProfileLocationsGQL
        .fetch(
          {
            profileId: this.profileId,
            name: this.locationForm.name,
            findExact: true,
            excludeId: this.locationForm.id,
          },
          { fetchPolicy: 'network-only' }
        )
        .subscribe({
          next: ({ data, loading }) => {
            this.loading.emit(loading);

            if (data && data.profile) {
              this.duplicateLocations = data.profile.locations;

              if (this.duplicateLocations.length > 0) {
                this.openDuplicateLocationNameDialog(this.locationForm);
              } else {
                this.updateLocation(this.locationForm);
              }
            } else {
              this.toasterService.error('UNKNOWN_ERROR');
            }
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('saveLocation', gqlError);
              this.toasterService.handleGqlError(gqlError);
            });
            this.loading.emit(false);
          },
        });
    }
  }

  openDuplicateLocationNameDialog(values: ILocationForm) {
    if (!this.isConfirmationOpen) {
      this.isConfirmationOpen = true;
      const modal = this.modalService.open(
        DuplicateLocationNameDialogComponent,
        { windowClass: 'app-modal-xl', backdrop: 'static' }
      );
      modal.componentInstance.duplicateLocations = this.duplicateLocations;
      modal.componentInstance.locationName = values.name;
      modal.result
        .then(() => {
          this.updateLocation(values);
        })
        .catch(() => {})
        .finally(() => {
          this.isConfirmationOpen = false;
        });
    }
  }

  updateLocation(form: ILocationForm) {
    if (this.location) {
      this.loading.emit(true);
      this.loaderMessage.emit('UPDATING_LOCATION');

      this.subs.sink = this.locationService
        .updateLocation(form, this.profileId)
        .subscribe({
          next: ({ data }) => {
            if (
              data &&
              data.updateLocation.isSuccessful &&
              data.updateLocation.location
            ) {
              this.toasterService.success('UPDATE_LOCATION_SUCCESS');
              this.location = data.updateLocation.location;
              this.locationUpdated.emit(this.location);
            } else {
              this.toasterService.error('UNKNOWN_ERROR');
            }
            this.isFormPristine = true;
            this.loading.emit(false);
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('updateLocation', gqlError);
              this.toasterService.handleGqlError(gqlError);
            });

            this.loading.emit(false);
          },
        });
    }
  }

  openDeleteLocationDialog() {
    if (this.location) {
      const modal = this.modalService.open(DeleteLocationDialogComponent);
      modal.componentInstance.location = this.location;
      modal.result
        .then(() => {
          this.deleteLocation();
        })
        .catch(() => {});
    }
  }

  deleteLocation(newLocationId?: Maybe<string>) {
    if (this.location?.id && this.profileId) {
      this.loading.emit(true);
      this.loaderMessage.emit('DELETING_LOCATION');

      const input: DeleteLocationInput = {
        id: this.location.id,
        newLocationId,
      };

      this.subs.sink = this.locationService
        .deleteLocation(input, this.profileId)
        .subscribe({
          next: ({ data }) => {
            if (data && data.deleteLocation.isSuccessful) {
              this.toasterService.success('DELETE_LOCATION_SUCCESS');
              this.navigateBack();
            } else {
              this.toasterService.error('UNKNOWN_ERROR');
            }
          },
          error: (error: ApolloError) => {
            console.error('deleteLocation', error);
            if (error.graphQLErrors[0].extensions?.errorCode) {
              // this.toasterService.error(error.graphQLErrors[0].extensions?.errorCode);
              this.showLocationReplacementDialog(
                error.graphQLErrors[0].extensions?.errorCode
              );
            } else {
              error.graphQLErrors.forEach((gqlError) => {
                this.toasterService.handleGqlError(gqlError);
              });
            }

            this.loading.emit(false);
          },
        });
    }
  }

  showLocationReplacementDialog(error: string) {
    const modalRef = this.modalService.open(LocationReplacementDialogComponent);
    modalRef.componentInstance.currentLocation = this.location;
    modalRef.componentInstance.promptMessage = error;
    modalRef.result
      .then((selectedLocation: Location) => {
        this.deleteLocation(selectedLocation.id);
      })
      .catch(() => {});
  }

  onLoadingChanged(value: boolean) {
    this.loading.emit(value);
  }

  onLoaderMessageChanged(value: string) {
    this.loaderMessage.emit(value);
  }

  navigateBack() {
    this.router.navigateByUrl(this.routingStateService.getPreviousUrl());
  }

  setLocation(values: ILocationForm) {
    this.locationForm = cloneDeep(values);
    this.locationForm.id = this.location?.id || '';
  }

  navigateToChannel({ type, row }: IDatatableRowActivateArgs) {
    console.log('navigateToChannel', row);

    // if (type === DatatableRowActivationType.CLICK) {
    //   const channel: Channel = row;
    //   if (channel?.id && this.profileId) {
    //     const id = this.encryptionService.encrypt(channel.id);
    //     const profileId = this.encryptionService.encrypt(this.profileId);
    //     if (this.currentUserService.canManageChannels && profileId) {
    //       this.router.navigate(['/channel/manage', id, profileId]);
    //     } else if (this.currentUserService.canViewChannels) {
    //       this.router.navigate(['/channel/detail', id]);
    //     } else {
    //       this.toasterService.error('ERROR_USER_NO_PERMISSION');
    //     }
    //   } else {
    //     this.toasterService.error('UNKNOWN_ERROR');
    //   }
    // }
  }
}
