import { IUser } from '@desquare/interfaces';

interface IActiveUserState {
  data?: IUser;
  error?: any;
}

export interface IUserState {
  activeUser?: IActiveUserState;
  loading: boolean;
}

export const initialUserState: IUserState = {
  loading: false,
};
