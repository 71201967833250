import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Playlist } from '@designage/gql';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ConfirmDialogComponent],
  selector: 'app-channel-remove-playlists-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="true"
    [dismissButtonText]="'CANCEL'"
    [closeButtonText]="'REMOVE'"
  >
    <p>
      {{ 'REMOVE_PLAYLIST_FROM_CHANNEL_CONFIRMATION' | translate }}
    </p>
    <ul>
      <li *ngFor="let playlist of playlists">{{ playlist.name }}</li>
    </ul>
  </app-confirm-dialog> `,
})
export class ChannelRemovePlaylistsDialogComponent {
  playlists: Playlist[] = [];
}
