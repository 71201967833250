import { routeConstants } from '@desquare/constants';

const isPublicApiEndpoint = (endpoint: string) => {
  const path = routeConstants.PUBLIC_API_ENDPOINTS.find((x) =>
    endpoint.includes(x)
  );
  return Boolean(path);
};

const isPublicAppRoute = (route: string) => {
  const path = routeConstants.PUBLIC_APP_ROUTES.find((x) => route.includes(x));
  return Boolean(path);
};

export const routeUtil = {
  isPublicApiEndpoint,
  isPublicAppRoute,
};
