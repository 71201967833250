<div class="container-fluid content-container-card list-container">
  <div class="row pb-2 align-items-center">
    <div class="col-8">
      <h4 *ngIf="!organizationId" class="header-title">
        {{ 'PROFILE_LIST' | translate }}
      </h4>

      <div class="pb-2 row">
        <!-- <div class="col-12 col-xl-4">
        </div> -->
      </div>
    </div>
    <div class="col-2">
      <!-- <button class="btn btn-primary btn-block">Bulk actions</!-->
    </div>
    <div class="col-2">
      <button
        *ngIf="showAddProfileButton"
        [routerLink]="['/profile/create']"
        class="btn btn-outline-primary float-end"
        (click)="modal.dismiss()"
      >
        {{ 'ADD_PROFILE' | translate }}
      </button>
    </div>
  </div>

  <ng-template #dataTable>
    <designage-data-table
      configId="profile-list"
      [data]="(profiles | async)?.results"
      [columns]="desColumns"
      [loading]="loading"
      [customComponent]="[statusBadge, createdAt, updatedAt]"
    >
    </designage-data-table>
  </ng-template>

  <ng-template #createdAt let-row>
    <table-dateTime
      [data]="row.createdAt"
      [tooltipPipeArgs]="'medium'"
    ></table-dateTime>
  </ng-template>
  <ng-template #updatedAt let-row>
    <table-dateTime
      [data]="row.updatedAt"
      [tooltipPipeArgs]="'medium'"
    ></table-dateTime>
  </ng-template>

  <ng-template #statusBadge let-row>
    <span *ngIf="row.active" class="badge bg-success"
      >{{ 'ACTIVE' | translate }}
    </span>
    <span *ngIf="!row.active" class="badge bg-danger"
      >{{ 'DEACTIVATED' | translate }}
    </span>
  </ng-template>

  <div *ngIf="!loading && hasNoData; else dataTable" class="col-12">
    <hr />
    <h4 class="unavailable-message">{{ 'NO_PROFILES_FOUND' | translate }}</h4>
  </div>
</div>
