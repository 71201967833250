import { createAction, props } from '@ngrx/store';

export enum AppActionTypes {
  SET_APP_INITIALIZING = 'SET_APP_INITIALIZING',
}

const setInitializing = createAction(AppActionTypes.SET_APP_INITIALIZING, props<{ value: boolean }>());

export const appActions = {
  setInitializing,
};
