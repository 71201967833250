import { createAction, props } from '@ngrx/store';
import { ChannelsForChannelListFragment } from '@designage/gql';

export enum ChannelActionTypes {
  SWITCH_CHANNEL = 'SWITCH_SELECTED_CHANNEL',
}

const switchChannel = createAction(
  ChannelActionTypes.SWITCH_CHANNEL,
  props<{ channel: ChannelsForChannelListFragment | undefined }>()
);

export const channelActions = {
  switchChannel,
};
