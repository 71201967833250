@if(loading() ){
<app-loader></app-loader>
}@else {
<div class="inspector-panel">
  @if(!channelParent()){
  <div class="row">
    <div
      class="text-truncate d-flex align-items-center justify-content-between sliding-panel-header"
    >
      <label class="d-inline pointer-text">
        {{ device().name }}
      </label>
    </div>
  </div>
  }
  <!-- MARK: Header -->
  <div class="inspector-panel-header">
    @if(showCloseButton()){
    <div (click)="closeClick.emit()" class="back-to-list-btn">
      <i class="ri-list-unordered"></i>
    </div>
    }
    <H4 class="flex-grow-1 ps-1">
      {{
        channelParent()
          ? ('DEVICE_NAME' | translate) + ': ' + device().name
          : ''
      }}
    </H4>
    <div class="d-flex lg-me-5">
      <div
        [@StatusGaugeAnimation]
        class="d-flex align-items-center px-2 fs-6 bg-dark"
      >
        <span class="text-nowrap d-none d-sm-block me-2">
          {{ 'STATUS' | translate }}:
        </span>
        <span
          class="px-1 badge"
          [class.bg-success]="device().status?.Status === 1"
          [class.bg-danger]="device().status?.Status === 4"
        >
          {{ device().status?.StatusLabel | translate }}
        </span>
      </div>
      @if(device().status?.Status === 1){
      @if(device().deviceInfo?.screen?.brightness != null){

      <div
        [@StatusGaugeAnimation]
        class="d-flex align-items-center px-2 fs-6 bg-dark"
      >
        <span class="text-nowrap d-none d-sm-block me-2">
          {{ 'DISPLAY_POWER' | translate }}:
        </span>
        <span>
          <app-device-brightness
            [deviceInfo]="device().deviceInfo"
            [status]="device().status"
          ></app-device-brightness>
        </span>
      </div>
      } @if(device().deviceInfo?.screen?.volume != null){
      <div
        [@StatusGaugeAnimation]
        class="d-flex align-items-center px-2 fs-6 bg-dark"
      >
        <span class="text-nowrap d-none d-sm-block me-2">
          {{ 'DEVICE_VOLUME' | translate }}:
        </span>
        <span
          ><app-device-volume
            [deviceInfo]="device().deviceInfo"
            [status]="device().status"
          ></app-device-volume
        ></span>
      </div>
      } }
    </div>
  </div>

  @if(responsiveUiService.lg()){

  <as-split
    direction="horizontal"
    unit="pixel"
    (transitionEnd)="updateView()"
    (dragEnd)="updateView()"
  >
    <as-split-area class="d-flex flex-column">
      <ng-container [ngTemplateOutlet]="screenShotTemplate"></ng-container>
    </as-split-area>
    <as-split-area
      class="d-flex flex-column overflow-hidden"
      minSize="470"
      size="470"
    >
      <ng-container [ngTemplateOutlet]="deviceDetailsTemplate"></ng-container>
    </as-split-area>
  </as-split>
  } @else {
  <ng-container [ngTemplateOutlet]="deviceDetailsTemplate"></ng-container>
  }
  <!-- MARK: Screenshot -->
  <ng-template #screenShotTemplate>
    <div class="border-bottom pt-3">
      <h4 class="mb-1">{{ 'SCREENSHOT' | translate }}</h4>
    </div>
    @if(device().deviceInfo !== null){
    <app-device-screenshot
      [context]="screenshotContext"
      [device]="device()"
    ></app-device-screenshot
    >}@else {
    <ngx-skeleton-loader
      count="1"
      animation="progress-dark"
      [theme]="{
          'height': '25rem',
        }"
    />
    }
  </ng-template>

  <ng-template #deviceDetailsTemplate>
    <nav ngbNav #nav="ngbNav" class="pt-3 nav nav-pills">
      <!-- 
      ** MARK: Table for general details. Read only. 
      ** -->
      <ng-container ngbNavItem>
        <li>
          <a ngbNavLink>Info</a>
        </li>
        <ng-template ngbNavContent>
          <div class="p-2">
            @if(device()){
            <device-details-table [device]="device()"></device-details-table>
            } @else {
            <div class="p-2 text-center">
              <p>
                Missing device data. This can be due to the device just being
                created.
              </p>
              <p>
                Try to reload the page. If the issue persists, please contact
                support by clicking the help button in the menu or mail
                support&#64;designage.io
              </p>
            </div>
            }
          </div>
        </ng-template>
      </ng-container>

      <!-- 
      ** MARK: Table for Device Settings 
      ** -->
      <ng-container ngbNavItem>
        <li>
          <a ngbNavLink>{{ 'SETTINGS' | translate }}</a>
        </li>
        <ng-template ngbNavContent>
          <app-device-manage-settings
            [device]="device()"
            [saving]="saving()"
            [profileChannels]="profileChannels()"
            [isUserSuperAdmin]="isUserSuperAdmin"
            (save)="saveForm($event, 'settings')"
          />
        </ng-template>
      </ng-container>

      <!-- 
      ** MARK:Location
      ** -->
      <ng-container ngbNavItem>
        <li>
          <a ngbNavLink>{{ 'LOCATION' | translate }}</a>
        </li>

        <ng-template ngbNavContent>
          <div class="p-2 h-100 d-flex flex-column">
            <div
              style="height: 4rem"
              class="d-flex justify-content-between align-items-center p-2"
            >
              @if(editLocation()){
              <app-location-selection
                class="d-block my-2"
                [profileId]="profileId()"
                [deviceId]="vm.device.id"
                [selectedLocationId]="vm.device.location?.id"
                [isHideAddLocationButton]="true"
                [isHideEditLocationButton]="true"
                (editLocationClicked)="editLocation($event)"
                (selectionChange)="setLocation($event)"
                (createNewLocation)="showCreateLocationTab($event)"
              ></app-location-selection>
              <div class="d-flex">
                <button
                  class="btn btn-sm btn-outline-warning mx-1"
                  (click)="editLocation.set(false)"
                >
                  {{ 'CANCEL' | translate }}
                </button>
                <button
                  class="btn btn-sm btn-outline-success"
                  (click)="saveForm(vm.device, 'location')"
                >
                  {{ 'SAVE' | translate }}
                </button>
              </div>
              } @else {
              <h3>{{ device().location?.name }}</h3>
              <button
                class="btn btn-sm btn-outline-primary"
                (click)="onEditLocation()"
              >
                {{ 'CHANGE_LOCATION' | translate }}
              </button>
              }
            </div>
            <div class="location-map-border">
              <app-location-form
                #locationComponent
                class="h-100"
                [hidden]="loading()"
                [showMapOnly]="true"
                [location]="
                  editLocation() ? vm.device.location : device().location
                "
              ></app-location-form>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- 
      ** MARK:Statistics
      ** -->
      <ng-container ngbNavItem>
        <li>
          <a ngbNavLink>{{ 'STATS' | translate }}</a>
        </li>

        <ng-template ngbNavContent>
          <div class="p-2 h-100">
            <div class="location-map-border">
              <!-- <ng-container
                [ngComponentOutlet]="deviceMonitorComponent"
                [ngComponentOutletInputs]="deviceMonitorComponentInputs"
              ></ng-container> -->
              @if(deviceId()){
              <designage-device-monitor
                #monitorComponent
                class="h-100"
                [deviceId]="deviceId()"
              ></designage-device-monitor>
              }
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- 
      ** MARK: Events
      ** -->
      <ng-container ngbNavItem *ngIf="showEvents">
        <li>
          <a ngbNavLink>{{ 'EVENTS' | translate }}</a>
        </li>

        <ng-template ngbNavContent>
          <div class="p-2 h-100">
            <div class="location-map-border">
              <app-event-list
                #eventComponent
                class="h-100"
                [hidden]="loading()"
                [deviceId]="device().id"
              ></app-event-list>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- 
      ** MARK: Logs
      ** -->
      <ng-container
        *ngIf="isUserSuperAdmin"
        ngbNavItem
        [destroyOnHide]="false"
        (shown)="showDeviceLog = true"
      >
        <li>
          <a ngbNavLink>{{ 'LOGS' | translate }}</a>
        </li>

        <ng-template ngbNavContent>
          <div class="p-2 h-100">
            <div class="location-map-border">
              <app-device-log
                *ngIf="showDeviceLog"
                class="h-100"
                [deviceId]="device().id"
              ></app-device-log>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <!-- 
      ** MARK:screenshot
      ** -->
      <ng-container ngbNavItem *ngIf="!responsiveUiService.lg()">
        <li>
          <a ngbNavLink>{{ 'SCREENSHOT' | translate }}</a>
        </li>

        <ng-template ngbNavContent>
          <ng-container [ngTemplateOutlet]="screenShotTemplate"></ng-container>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="overflow-auto"></div>
  </ng-template>

  <div class="d-flex pt-2 border-top align-items-end justify-content-end">
    <button
      type="button"
      [class.btn-sm]="!responsiveUiService.xl()"
      class="btn btn-outline-danger text-light mx-3"
      (click)="deprovisionDevice()"
      [disabled]="deletingDevice"
    >
      @if(deletingDevice){
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      >
        <span class="visually-hidden"
          >{{ 'DEPROVISIONING' | translate }}...</span
        >
      </div>
      }@else {
      {{ 'DEPROVISION' | translate }}
      }
    </button>
    @if(showForceDeprovision){
    <button
      type="button"
      [class.btn-sm]="!responsiveUiService.xl()"
      class="btn btn-outline-danger text-light mx-3"
      (click)="deprovisionDevice(true)"
      [disabled]="deletingDevice"
    >
      @if(deletingDevice){
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      >
        <span class="visually-hidden"
          >{{ 'DEPROVISIONING' | translate }}...</span
        >
      </div>
      }@else {
      {{ 'DEPROVISION_FORCE' | translate }}
      }
    </button>
    }
  </div>
</div>
}

<ng-template #empty>
  <h5 *ngIf="isDeviceUnavailable" class="text-center text-danger">
    {{ 'INFORMATION_UNAVAILABLE' | translate }}
  </h5>
</ng-template>

<ng-template #loadingSpinner>
  <div class="spinner-border spinner-border-sm text-info" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-template>
