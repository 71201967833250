import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService, CurrentUserService } from '@desquare/services';
import { ActivateProfileGQL, Profile } from '@designage/gql';
import { SubSink } from 'subsink';
import { ApolloError } from '@apollo/client/errors';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, LoaderComponent],
  selector: 'app-profile-activation-dialog',
  template: `<div [hidden]="activating" class="modal-container">
      <div class="modal-header">
        <h4>{{ profile.name }}</h4>
        <button
          type="button"
          class="btn-close btn-close-white"
          aria-label="Close"
          data-dismiss="modal"
          (click)="modal.dismiss()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="card-box">
          <h2>{{ 'PROFILE_REACTIVATION_PROMPT' | translate }}</h2>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-outline-primary btn-block"
          (click)="reactivate()"
        >
          {{ 'REACTIVATE' | translate }}
        </button>
      </div>
    </div>
    <div [hidden]="!activating" class="modal-container">
      <div class="modal-header"></div>
      <div class="modal-body">
        <app-loader></app-loader>
      </div>
    </div> `,
})
export class ProfileActivationDialogComponent implements OnInit {
  private subs = new SubSink();
  profile!: Profile;
  activating = false;

  constructor(
    public modal: NgbActiveModal,
    private activateProfileGQL: ActivateProfileGQL,
    private toasterService: ToasterService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {}

  reactivate() {
    if (this.profile && this.profile.id) {
      this.activating = true;
      this.subs.sink = this.activateProfileGQL
        .mutate({ id: this.profile.id })
        .subscribe({
          next: ({ data }) => {
            if (
              data?.activateProfile.profile &&
              data?.activateProfile.isSuccessful
            ) {
              this.toasterService.success('ACTIVATE_PROFILE_SUCCESS');
              this.currentUserService.updateProfile({
                id: data.activateProfile.profile.id,
                active: data.activateProfile.profile.active,
              });
              this.modal.close(data.activateProfile.profile);
            } else {
              this.toasterService.error('UNKNOWN_ERROR');
            }
            this.activating = false;
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('reactivate', gqlError);
              this.toasterService.handleGqlError(gqlError);
            });
            this.activating = false;
          },
        });
    }
  }
}
