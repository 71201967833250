import {
  Component,
  Input,
  ChangeDetectionStrategy,
  effect,
  input,
  DestroyRef,
  Output,
  EventEmitter,
  inject,
  signal,
  computed,
  untracked,
} from '@angular/core';
import {
  Playlist,
  Maybe,
  RemovePlaylistsFromChannelGQL,
  GetChannelPlaylistsGQL,
  Channel,
  PlaylistStatus,
} from '@designage/gql';
import { ChannelService, ToasterService } from '@desquare/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChannelRemovePlaylistsDialogComponent } from '@designage/app/channel/channel-remove-playlists-dialog/channel-remove-playlists-dialog.component';
import { IPlaylistRegion } from '@desquare/interfaces';
import { AddPlaylistDialogComponent } from '@designage/app/channel/add-playlist-dialog/add-playlist-dialog.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { PlaylistRowComponent } from '../playlist-row/playlist-row.component';
import { lastValueFrom } from 'rxjs';
import { ChannelsStore, DevicesStore, PlaylistsStore } from '@desquare/stores';
import { JsonPipe } from '@angular/common';
import { ApolloError } from '@apollo/client';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    LoaderComponent,
    PlaylistRowComponent,
    JsonPipe,
  ],
  selector: 'app-playlist-list-for-channel',
  templateUrl: './playlist-list-for-channel-page.component.html',
  styleUrls: ['./playlist-list-for-channel-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistListForChannelPageComponent {
  channelsStore = inject(ChannelsStore);
  devicesStorage = inject(DevicesStore);
  playlistsStore = inject(PlaylistsStore);

  getChannelPlaylistsGQL = inject(GetChannelPlaylistsGQL);
  playlistsConnectedToChannel = input.required<Playlist[]>();
  channel = input.required<Channel>();
  selectedPlaylistRegion = input<string | null>(null);

  loading = signal<boolean>(true);
  playlistRegions = input.required<IPlaylistRegion[]>();

  /** assigned regions for each playlist */
  @Input() deviceId: Maybe<string>;
  @Output() refreshPlaylists = new EventEmitter<void>();
  loaderMessage!: string;
  destroyRef = inject(DestroyRef);
  deviceInfo = computed(() =>
    this.devicesStorage.getDevice(this.deviceId ?? '')()
  );
  layout = computed(() => this.channel().layout);
  playlists = signal<Playlist[]>([]);
  rePublishLoader = signal<boolean>(false);

  constructor(
    private channelService: ChannelService,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private removePlaylistsFromChannel: RemovePlaylistsFromChannelGQL
  ) {
    // Use a pattern for not creating cricualr effects
    // https://www.angulararchitects.io/en/blog/when-not-to-use-effects-in-angular-and-what-to-do-instead/
    effect(async () => {
      const playlists = await this.getsortedPlaylists();
      untracked(() => {
        this.playlists.set(playlists);
        this.loading.set(false);
      });
    });
  }

  async getsortedPlaylists(): Promise<Playlist[]> {
    const { data } = await lastValueFrom(
      this.getChannelPlaylistsGQL.fetch(
        {
          channelId: this.channel().id,
          onlyParentPlaylist: true,
          statuses: [PlaylistStatus.Active],
        },
        { fetchPolicy: 'no-cache' }
      )
    );
    return (
      (data?.channel?.playlists?.map((playlist) => ({
        ...playlist,
        assets: [...playlist.assets].sort((a, b) => {
          if (a.sequence && b.sequence && a.sequence > b.sequence) {
            return 1;
          }
          return -1;
        }),
      })) as Playlist[]) || []
    );
  }
  async getPlaylists() {
    const { data } = await lastValueFrom(
      this.getChannelPlaylistsGQL.fetch(
        {
          channelId: this.channel().id,
          onlyParentPlaylist: true,
          statuses: [PlaylistStatus.Active],
        },
        { fetchPolicy: 'no-cache' }
      )
    );
    const sortedPlaylists =
      data?.channel?.playlists?.map((playlist) => ({
        ...playlist,
        assets: [...playlist.assets].sort((a, b) => {
          if (a.sequence && b.sequence && a.sequence > b.sequence) {
            return 1;
          }
          return -1;
        }),
      })) || [];
    this.playlists.set(sortedPlaylists as Playlist[]);
    this.loading.set(false);
  }

  get lastDevicePingUpdated() {
    return this.deviceInfo()?.deviceInfo?.currentTime?.currentDate;
  }

  // get loadedPlaylists() {
  //   return this.deviceInfo()?. || [];
  // }

  get showLayoutRegions() {
    const regionBlocks = this.layout()?.source?.regionBlocks;
    return (
      (regionBlocks && regionBlocks.length > 0) ||
      this.playlists().filter((x) => (x.region || '') !== '').length > 0
    );
  }

  addPlaylist() {
    const modalRef = this.modalService.open(AddPlaylistDialogComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
    });

    modalRef.componentInstance.channelId = this.channel().id;
    modalRef.componentInstance.hidePlaylistIds = this.playlists().map(
      (x) => x.id
    );

    modalRef.componentInstance.selectedRegionInput =
      this.selectedPlaylistRegion();

    modalRef.componentInstance.layout = this.layout;

    modalRef.result.then(async (value: boolean) => {
      if (value) {
        const playlistIds =
          modalRef.componentInstance.selectedPlaylistsIds() as string[];
        this.loaderMessage = 'ADDING_PLAYLIST';
        const region = modalRef.componentInstance.selectedRegion() as string;
        await this.channelService.addPlaylistsToChannels(
          [this.channel().id],
          playlistIds,
          region,
          true
        );

        // loading flag will be reset at refresh end
        // this.loading = false;
        this.getPlaylists();
      }
    });
  }

  async rePublish() {
    this.rePublishLoader.set(true);
    await this.channelService
      .republishChannelContent([this.channel().id])
      .then(() => {
        this.toasterService.success('REPUBLISH_PLAYLISTS_SUCCESS');
        this.rePublishLoader.set(false);
      });
  }

  updateRegion(input: IPlaylistRegion) {
    console.log('updateRegion', input);

    this.channelsStore.updateRegion(this.channel().id, input);
  }

  removePlaylistFromChannel(playlistId: string) {
    if (!this.channel().id) return;

    const targetPlaylist = this.playlists().find((x) => x.id === playlistId);
    if (!targetPlaylist) return;

    this.loaderMessage = 'REMOVE_PLAYLIST_FROM_CHANNEL';

    const modal = this.modalService.open(
      ChannelRemovePlaylistsDialogComponent,
      {
        backdrop: 'static',
      }
    );
    modal.componentInstance.playlists.push(targetPlaylist);
    modal.result
      .then(() => {
        if (targetPlaylist.parentPlaylist)
          lastValueFrom(
            this.removePlaylistsFromChannel.mutate({
              input: {
                id: this.channel().id,
                playlistIds: [targetPlaylist.parentPlaylist.id],
              },
            })
          )
            .then(({ data }) => {
              if (data?.removePlaylistsFromChannel.isSuccessful) {
                this.toasterService.success(
                  'REMOVE_PLAYLIST_FROM_CHANNEL_SUCCESS'
                );
                console.log('removePlaylistsFromChannel', data);

                // manual remove for now, this is no longer needed once subscription
                // on channel playlists is implemented, this will automatically refetch
                // the data once a mutation is called
                this.playlists.update((state) => {
                  return [...state.filter(({ id }) => id !== playlistId)];
                });
              } else {
                this.toasterService.warning(
                  'REMOVE_PLAYLIST_FROM_CHANNEL_FAIL'
                );
              }
            })
            .catch((error: ApolloError) => {
              error.graphQLErrors.forEach((gqlError) => {
                console.error('removePlaylistFromChannel', gqlError);
                this.toasterService.handleGqlError(gqlError);
              });
            });
      })
      .catch(() => {});
  }
}
