import { Component, OnInit, Input } from '@angular/core';
import { Notification, NotificationContentType } from '@designage/gql';
import { Router, RouterLink } from '@angular/router';
import { EncryptionService } from '@desquare/services';
import { styleUtil } from '@desquare/utils';
import { DateTime } from 'ts-luxon';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NgbDropdownModule,
    NgbTooltip,
    DateProxyPipe,
  ],
  selector: 'app-notification-row',
  templateUrl: './notification-row.component.html',
  styleUrls: ['./notification-row.component.scss'],
})
export class NotificationRowComponent implements OnInit {
  @Input() notification!: Notification;

  duration!: string;

  notificationContentType = NotificationContentType;
  statusIconStyle = 'text-primary';

  constructor(
    private router: Router,
    private encryptionService: EncryptionService
  ) {}

  ngOnInit() {
    this.duration = DateTime.fromISO(this.notification.createdAt).toRelative({
      locale: 'en',
    })!;
    this.setContentStyle();
  }

  setContentStyle() {
    this.statusIconStyle = styleUtil.getNotificationContentStyle(
      this.notification.content
    );
  }

  navigateToDeviceManagePage(id: string) {
    const deviceId = this.encryptionService.encrypt(id);
    this.navigateToPage('/device/manage', [deviceId]);
  }

  navigateToMediaManagePage(id: string) {
    const mediaId = this.encryptionService.encrypt(id);
    this.navigateToPage('/media/manage', [mediaId]);
  }

  // SEALED: this code is sealed due to the removal of uploadService
  // reUploadMedia(content: MediaUploadFailedNotificationContent) {
  //   const userId = this.currentUserService.currentUser?.id;

  //   if (!userId) {
  //     throw new Error('UserId is undefined');
  //   }

  // this.uploadService.reUpload(userId, this.notification.profileId, content);
  // }

  private navigateToPage(baseUrl: string, queries: string[]) {
    this.router.navigate([baseUrl, ...queries]);
  }
}
