<form
  *ngIf="userForm"
  [formGroup]="userForm"
  (submit)="onSubmit(userForm?.value)"
  class="row p-3"
>
  <div class="col-8 d-flex flex-column">
    <h1 *ngIf="!isEditingName; else editName" class="editable-field ps-3">
      {{ userForm.value.name || ('NO_DATA' | translate)
      }}<i
        (click)="editNameInput()"
        class="px-2 edit-icon ri-pencil-fill text-primary"
      ></i>
    </h1>
    <ng-template #editName>
      <div class="input-group mb-2 mt-1 ps-1">
        <input
          #userNameInput
          type="text"
          name="name"
          class="form-control form-control-dark"
          placeholder="{{ 'NAME' | translate }}"
          aria-label="{{ 'NAME' | translate }}"
          aria-describedby="basic-addon2"
          formControlName="name"
          (blur)="isEditingName = false"
        />
      </div>
    </ng-template>
    <div
      class="error"
      *ngIf="
        (userForm?.controls)['name']?.pristine &&
        userForm?.controls['name']?.hasError('required')
      "
    >
      {{ 'NAME_IS_REQUIRED' | translate }}
    </div>
    <div class="py-2 row">
      <div class="col-6">
        <label class="form-label" for="email"
          >{{ 'EMAIL_ADDRESS' | translate }}:
        </label>
        <span class="">
          {{ user?.auth0Profile?.email || 'NO_DATA' | translate }}
        </span>
      </div>
      <div class="col-6 d-flex flex-nowrap align-items-baseline">
        <!-- Role switcher -->
        <label class="form-label" for="roles">{{ 'ROLE' | translate }}: </label>
        <ng-container *ngIf="isEditingRole; else roleReadonly">
          <select
            style="max-width: 10rem; margin-top: -0.35rem"
            formControlName="roleId"
            class="form-select form-select-sm form-select-dark role-select"
            (change)="isEditingRole = false"
          >
            <option [ngValue]="null" selected disabled>
              {{ 'SELECT_A_ROLE' | translate }}
            </option>
            <option *ngFor="let role of roles" [value]="role.id">
              {{ role.name | translate }}
            </option>
          </select>
          <div
            class="my-auto ms-2 btn-close btn-close-white cursor-pointer"
            (click)="isEditingRole = false"
          ></div>
        </ng-container>
        <ng-template #roleReadonly>
          <div style="margin-top: -0.35rem" class="px-1 editable-field">
            {{ userForm.value.roleId }}
            <i
              *ngIf="canManageUsers"
              (click)="isEditingRole = true"
              class="px-2 edit-icon ri-pencil-fill text-primary"
            ></i>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="py-2 row">
      <div class="col-6">
        <label class="form-label" for="dateCreated"
          >{{ 'DATE_CREATED' | translate }}:</label
        >
        <span class="px-1">{{
          user?.auth0Profile?.created_at | date : 'short'
        }}</span>
      </div>
      <div class="col-6">
        <label class="form-label" for="dateUpdated"
          >{{ 'DATE_UPDATED' | translate }}:</label
        >
        <span class="px-1">{{ user?.updatedAt | date : 'short' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="mt-3">
        <button
          [disabled]="userForm.invalid || userForm.pristine"
          type="submit"
          class="btn btn-outline-success"
        >
          {{ 'SAVE' | translate }}
        </button>
        <button
          [disabled]="!userForm.dirty"
          type="revert"
          class="ms-3 btn btn-outline-danger"
          (click)="revertChanges()"
        >
          {{ 'REVERT' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
