import {
  Component,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  inject,
  DestroyRef,
  signal,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CurrentUserService, ResponsiveUiService } from '@desquare/services';
import { SubSink } from 'subsink';
import { Maybe } from '@designage/gql';
import { LocalStorageService } from 'ngx-webstorage';
import { localStorageKeys } from '@desquare/constants';
import {
  NgxZendeskWebwidgetConfig,
  NgxZendeskWebwidgetModule,
  NgxZendeskWebwidgetService,
} from 'ngx-zendesk-webwidget';
import packageJson from '../../../../../../package.json';
import { IProfile } from '@desquare/interfaces';
import { CommonModule, NgClass, NgTemplateOutlet } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  override lazyLoad = false;
  accountUrl = 'desquare.zendesk.com';
  callback(zE: any) {}
}

@Component({
  standalone: true,
  imports: [
    RouterModule,
    NgTemplateOutlet,
    NgClass,
    TranslateModule,
    NgbDropdownModule,
    MatAccordion,
    MatExpansionModule,
    NgxZendeskWebwidgetModule,
  ],
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [
    NgxZendeskWebwidgetService,
    { provide: NgxZendeskWebwidgetConfig, useClass: ZendeskConfig },
  ],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  version = packageJson.version;
  currentUserService = inject(CurrentUserService);
  responsiveUiService = inject(ResponsiveUiService);
  zendeskService = inject(NgxZendeskWebwidgetService);

  destroyRef = inject(DestroyRef);

  @ViewChild('sideMenu') sideMenu!: ElementRef;

  isSidebarCollapsed = signal<boolean>(false);

  @Output() sidebarCollapsed = new EventEmitter<boolean>();

  currentProfile!: Maybe<IProfile>;
  supportWidget = false;
  supportDisplayName!: Maybe<string>;
  supportEmail!: Maybe<string>;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  get superAdminLinksEnabled() {
    return (
      this.currentUserService.canViewUserList &&
      this.currentUserService.canViewOrganizationList &&
      this.currentUserService.isSuperAdmin
    );
  }

  get currentUserCanManageDevices() {
    return this.currentUserService.canManageDevices;
  }

  get profileRelatedLinkEnabled() {
    return this.currentUserService.canManageProfiles;
  }

  get contentRelatedLinkEnabled() {
    return (
      this.currentUserService.canManageProfiles ||
      this.currentUserService.isContentManager
    );
  }

  get profileDetailsLinkEnabled() {
    return false; // this.currentUserService.isContentManager;
  }

  get isSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }
  hamburgerOpen: boolean = false;
  settingsTreeOpen: boolean = false;

  settingsMenuArray = [
    '/profile/settings',
    '/profile/details',
    '/profile/devices',
    '/profile/locations',
    '/profile/user-select',
    '/profile/resource-groups',
  ];

  ngOnInit() {
    this.initSubscriptions();
    this.zendeskInit();
    this.settingsTreeOpen = this.settingsMenuArray.some((route) =>
      this.router.url.includes(route)
    );
  }

  ngAfterViewInit() {
    const storedSidebarExpandedValue: Maybe<boolean> =
      this.localStorageService.retrieve(localStorageKeys.SIDEBAR_COLLAPSED);

    this.setSidebarState(storedSidebarExpandedValue ?? false);
  }

  gotoRoute(route: string) {
    this.hamburgerOpen = false;
    this.router.navigate([route]);
  }

  initSubscriptions() {
    this.currentProfile = this.currentUserService.currentProfile;

    this.responsiveUiService
      .isHandheldScreenSizes$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        if (result.matches) {
          this.setSidebarState(true);
        }
      });
  }

  setSidebarState(isSidebarCollapsed: boolean) {
    this.isSidebarCollapsed.set(isSidebarCollapsed);

    this.localStorageService.store(
      localStorageKeys.SIDEBAR_COLLAPSED,
      isSidebarCollapsed
    );

    this.sidebarCollapsed.emit(isSidebarCollapsed);
  }

  async zendeskInit() {
    await this.zendeskService.initZendesk();

    const user = this.currentUserService.currentUser;
    const profile = this.currentUserService.currentProfile;
    if (user) {
      (this.supportDisplayName = user.displayName),
        (this.supportEmail = user.auth0Profile?.email);
      this.zendeskService.zE('webWidget', 'identify', {
        name: user.displayName,
        email: user.auth0Profile?.email,
      });
      this.zendeskService.zE('webWidget', 'prefill', {
        name: { value: `${user.displayName} (${profile?.name})` },
        email: { value: user.auth0Profile?.email },
      });
    }
    this.zendeskService.zE('webWidget', 'hide');
    this.zendeskService.zE('webWidget:on', 'close', () => {
      this.zendeskService.zE('webWidget', 'hide');
      this.supportWidget = false;
    });
  }
  openZendeskWidget() {
    if (!this.supportWidget) {
      this.zendeskService.zE('webWidget', 'show');
      this.zendeskService.zE('webWidget', 'open');
      this.supportWidget = true;
    } else {
      this.zendeskService.zE('webWidget', 'close');
      this.zendeskService.zE('webWidget', 'hide');
      this.supportWidget = false;
    }
  }
}
