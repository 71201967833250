import { initialAppState, IAppState } from '@desquare/store/states';
import { createReducer, on, Action } from '@ngrx/store';
import { appActions } from '@desquare/store/actions';

const reducer = createReducer(
  initialAppState,
  on(appActions.setInitializing, (state, action) => ({
    ...state,
    initializing: action.value,
  }))
);

export function appReducer(state: IAppState | undefined, action: Action) {
  return reducer(state, action);
}
