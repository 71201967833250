{
  "possibleTypes": {
    "AssetItem": [
      "BrowserAsset",
      "HtmlAsset",
      "IFrameAsset",
      "ImageAsset",
      "VideoAsset",
      "WidgetAsset"
    ],
    "NotificationContent": [
      "ChannelDetailNotificationContent",
      "DeviceDeletionNotificationContent",
      "DeviceDetailNotificationContent",
      "DeviceStatusNotificationContent",
      "LocationDetailNotificationContent",
      "MediaUploadFailedNotificationContent",
      "MediaUploadLargeNotificationContent",
      "MediaUploadNotificationContent"
    ]
  }
}