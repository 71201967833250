import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutManageComponent } from '../layout-manage/layout-manage.component';
import { SlidePanelComponent } from '../../shared/slide-panel/slide-panel.component';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, LayoutManageComponent],
  selector: 'designage-layout-manage-panel',
  template: `<app-slide-panel
    (closePanel)="closeLayoutManagePanel()"
    [fullscreen]="true"
    [panelContent]="'layoutManage'"
  >
    <app-layout-manage></app-layout-manage>
  </app-slide-panel> `,
})
export class LayoutManagePanelComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  closeLayoutManagePanel() {
    this.router.navigate(['layouts']);
  }
}
