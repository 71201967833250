import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Maybe,
  GetMediaForMediaManagePageQuery,
  GetMediaForMediaManagePageGQL,
  ResourceType,
  UpdateMediaSubscriptionGQL,
} from '@designage/gql';
import {
  EncryptionService,
  ToasterService,
  RoutingStateService,
} from '@desquare/services';
import { ApolloError } from '@apollo/client/errors';
import { environment } from '@desquare/environments';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { CloudinaryMediaComponent } from '@desquare/components/common/src/cloudinary/cloudinaryMedia.component';
import { MediaSettingsFormComponent } from '../media-settings-form/media-settings-form.component';
import { MediaMetaDataComponent } from '../media-meta-data/media-meta-data.component';

enum Tabs {
  SETTINGS,
  DETAILS,
}
@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    LoaderComponent,
    CloudinaryMediaComponent,
    MediaSettingsFormComponent,
    MediaMetaDataComponent,
  ],
  selector: 'app-media-manage',
  templateUrl: './media-manage.component.html',
  styleUrls: ['./media-manage.component.scss'],
})
export class MediaManageComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  currentTab!: Tabs;
  loaderMessage!: string;
  loading!: boolean;
  tabs = Tabs;
  media!: Maybe<GetMediaForMediaManagePageQuery['media']>;
  downloadLink = '';

  constructor(
    private route: ActivatedRoute,
    private encryptionService: EncryptionService,
    private toasterService: ToasterService,
    private getMediaGQL: GetMediaForMediaManagePageGQL,
    private router: Router,
    private routingStateService: RoutingStateService,
    private updateMediaSubscription: UpdateMediaSubscriptionGQL
  ) {}

  get title() {
    return this.media?.metadata ? this.media.name : 'METADATA_MISSING_ERROR';
  }

  get mediaUrl() {
    if (!this.media?.metadata) {
      return environment.assets.missingImagePlaceholder;
    } else {
      return environment.assets.placeholderImage;
    }
  }

  get isMediaUnavailable() {
    return !this.media && !this.loading;
  }

  get isMetadataAvailable() {
    return this.media?.metadata;
  }

  get isImage() {
    return this.media?.type === ResourceType.Image;
  }

  get isVideo() {
    return this.media?.type === ResourceType.Video;
  }

  get isRaw() {
    return this.media?.type === ResourceType.Raw;
  }

  ngOnInit() {
    this.initVariables();
  }

  initVariables() {
    this.currentTab = Tabs.SETTINGS;
    this.subs.sink = this.route.params.subscribe((params) => {
      const encryptedMediaId = params.mediaId;
      if (encryptedMediaId) {
        this.initMedia(encryptedMediaId);
      }
    });
    // this.subs.sink = this.route.paramMap.subscribe((params) => {
    //   const id = params.get('id');
    //   if (id) {
    //     this.initMedia(id);
    //   }
    // });
  }

  initMedia(id: string) {
    this.loaderMessage = 'FETCHING_MEDIA';
    this.loading = true;
    this.media = null;

    const decryptedId = this.encryptionService.decrypt(id);
    this.subs.sink = this.getMediaGQL
      .fetch(
        { id: decryptedId },
        { fetchPolicy: 'no-cache', errorPolicy: 'all' }
      )
      .subscribe({
        next: ({ loading, data: { media }, errors }) => {
          this.loading = loading;

          if (media) {
            this.media = media;
            this.downloadLink = media.secureUrl.replace(
              'upload/',
              'upload/fl_attachment/'
            );
          }

          if (errors) {
            this.toasterService.error('METADATA_MISSING_ERROR');
          }
        },
        error: (error: ApolloError) => {
          this.loading = false;
          error.graphQLErrors.forEach((err) => {
            console.error('initMedia', err);
            this.toasterService.handleGqlError(err);
          });
        },
      });

    this.subs.sink = this.updateMediaSubscription
      .subscribe({ id: decryptedId })
      .subscribe(({ data }) => {
        if (data?.updateMedia?.media) {
          const oldMedia = this.media;
          const newMedia = data.updateMedia.media;
          if (!newMedia.metadata) {
            newMedia.metadata = oldMedia?.metadata;
          }
          this.media = newMedia;
          this.downloadLink = this.media.secureUrl.replace(
            'upload/',
            'upload/fl_attachment/'
          );
        }
      });
  }

  navigateBack() {
    this.router.navigateByUrl(this.routingStateService.getPreviousUrl());
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
