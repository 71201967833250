import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  GetChannelForChannelDetailGQL,
  GetChannelForChannelDetailQuery,
} from '@designage/gql';
import { SubSink } from 'subsink';
import { ActivatedRoute } from '@angular/router';
import { EncryptionService } from '@desquare/services';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule],
  selector: 'app-channel-detail',
  template: `<div class="mb-0 row">
    <div class="col-md-12 card mb-0">
      <h5 class="mb-3 text-uppercase bg-light p-2">
        {{ 'CHANNEL_INFO' | translate }}
      </h5>
      <div class="card-body">
        <div class="row">
          <div class="col-12 form-group">
            <label for="channelName">{{ 'CHANNEL_NAME' | translate }}</label>
            <span>{{ channel?.name || ('NO_DATA' | translate) }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-12 form-group">
            <label for="description">{{
              'TAGLINE_OR_DESCRIPTION' | translate
            }}</label>
            <span>{{ channel?.description || ('NO_DATA' | translate) }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-12 form-group">
            <label for="location">{{ 'LOCATION_REMOVE' | translate }}</label>
            <!-- TODO: move this to device details? -->
            <!-- <span>{{ channel?.location?.name || ('NO_DATA' | translate) }}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div> `,
})
export class ChannelDetailComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  @Input() channel?: Maybe<GetChannelForChannelDetailQuery['channel']>;
  loaderMessage!: string;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private encryptionService: EncryptionService,
    private getChannelGQL: GetChannelForChannelDetailGQL
  ) {}

  ngOnInit() {
    this.initChannel();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initChannel() {
    if (!this.channel) {
      this.subs.sink = this.route.paramMap.subscribe((params) => {
        const id = params.get('id');

        if (id) {
          this.getChannel(id);
        }
      });
    }
  }

  async getChannel(id: string) {
    this.loading = true;
    this.channel = null;

    const decryptedId = this.encryptionService.decrypt(id);
    this.subs.sink = this.getChannelGQL
      .fetch({ id: decryptedId }, { fetchPolicy: 'no-cache' })
      .subscribe(({ loading, data: { channel } }) => {
        this.loading = loading;
        this.loaderMessage = 'FETCHING_CHANNEL';
        this.channel = channel;
      });
  }
}
