import { ScheduleDays } from '@designage/gql';

export interface ScheduleDisplay {
  days: IDisplayScheduleDays;
  off: string;
  on: string;
}

export interface IDisplayScheduleDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export const weekdaysSortOrder = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};
