<div class="d-flex flex-column h-100">
  <!-- pager, filter-input row -->
  <div class="d-flex">
    <h4 class="flex-grow-1">
      {{ 'LAYOUTS_RELATED_TO_THIS_PROFILE' | translate }}
    </h4>
    <div class="text-end">
      <button
        class="btn btn-md btn-outline-primary"
        (click)="onCreateLayoutButtonClicked()"
      >
        {{ 'CREATE_LAYOUT' | translate }}
      </button>
    </div>
  </div>

  <!-- layouts table/list -->
  <app-layout-list
    class="overflow-hidden"
    [layouts]="filteredLayouts$ | async"
  ></app-layout-list>

  <!-- Layout Manage Panel -->
  <router-outlet></router-outlet>
</div>
