import { IUserState, initialUserState } from './user.state';
import { IProfileState, initialProfileState } from './profile.state';
import { IChannelState, initialChannelState } from './channel.state';
import { initialDeviceState, IDeviceState } from './device.state';
import { ILocationState, initialLocationState } from './location.state';

export interface IAppState {
  user: IUserState;
  profile: IProfileState;
  channel: IChannelState;
  device: IDeviceState;
  location: ILocationState;
  initializing: boolean;
}

export interface IOperationStatus {
  isSuccessful: boolean;
  message: string;
}

export const initialAppState: IAppState = {
  user: initialUserState,
  profile: initialProfileState,
  channel: initialChannelState,
  device: initialDeviceState,
  location: initialLocationState,
  initializing: true,
};
