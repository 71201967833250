import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ResponsiveUiService,
  SlidePanelService,
  UiDataService,
} from '@desquare/services';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSplitModule } from 'angular-split';
import { SubSink } from 'subsink';

@Component({
  standalone: true,
  imports: [NgIf, AngularSplitModule, TranslateModule, NgbTooltip],
  selector: 'app-slide-panel',
  template: `<as-split
    class="panel-container transparent-panel"
    useTransition="true"
    (dragEnd)="splitDragEnd($event.sizes[0])"
    (transitionEnd)="splitDragEnd()"
  >
    <as-split-area
      [visible]="!slidingPanelSetting.fullscreen && responsiveUiService.lg()"
      [size]="slidingPanelSetting.openWidth"
      order="1"
    ></as-split-area>

    <as-split-area
      order="2"
      class="h-100 pt-4 content-panel"
      [class]="!slidingPanelSetting.fullscreen ? 'gutter-fix' : ''"
      [size]="100 - slidingPanelSetting.openWidth"
    >
      <div
        *ngIf="responsiveUiService.lg()"
        class="show-hide-btn left d-block"
        (click)="setFullscreen()"
        ngbTooltip="{{ fullscreenTooltip | translate }}"
        placement="end"
      >
        {{ slidingPanelSetting.fullscreen ? '>' : '<' }}
      </div>

      <div
        class="show-hide-btn right"
        (click)="onClosePanel()"
        ngbTooltip="{{ closePanelTooltip | translate }}"
        placement="start"
      >
        x
      </div>

      <!-- panel content -->
      <ng-content></ng-content>
    </as-split-area>
  </as-split> `,
  styleUrls: ['./slide-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlidePanelComponent implements OnInit, OnDestroy {
  @Input() fullscreenTooltip = 'TOGGLE_PAGE_BEHIND_PANEL_TOOLTIP';
  @Input() closePanelTooltip = 'CLOSE_PANEL_TOOLTIP';
  @Input() openWidth = 25;
  @Input() panelContent = '';
  @Input() fullscreen = true;

  @Output() closePanel = new EventEmitter<boolean>();

  private subs = new SubSink();
  slidingPanelSetting!: {
    panelContent: string;
    openWidth: number;
    fullscreen: boolean;
  };

  constructor(
    private slidePanelService: SlidePanelService,
    private uiDataService: UiDataService,
    public responsiveUiService: ResponsiveUiService
  ) {
    this.initServiceVariables();
  }

  ngOnInit(): void {
    this.slidingPanelSetting = this.slidePanelService.getPanelSettings(
      this.panelContent,
      this.openWidth,
      this.fullscreen
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.slidePanelService.setIsPanelOpen(false);
  }

  initServiceVariables() {
    this.slidePanelService.setIsPanelOpen(true);
  }

  onClosePanel() {
    this.closePanel.emit(true);
  }

  setFullscreen() {
    if (
      this.slidingPanelSetting.fullscreen &&
      this.slidingPanelSetting.openWidth < 15
    ) {
      this.slidingPanelSetting.openWidth = 20;
    }
    this.slidingPanelSetting.fullscreen = !this.slidingPanelSetting.fullscreen;
    // this.slidePanelService.setPanelSettings(
    //   this.slidingPanelSetting.panelContent,
    //   this.slidingPanelSetting.openWidth,
    //   this.slidingPanelSetting.fullscreen
    // );
  }

  splitDragEnd(e?: number) {
    if (e) {
      this.slidingPanelSetting.openWidth = e;
      if (e < 2) this.slidingPanelSetting.fullscreen = true;
    }

    this.slidePanelService.setPanelSettings(
      this.slidingPanelSetting.panelContent,
      this.slidingPanelSetting.openWidth,
      this.slidingPanelSetting.fullscreen
    );

    this.uiDataService.updateViewMode();
  }
}
