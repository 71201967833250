import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DeleteLocationGQL } from '@designage/gql';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { locationActions, LocationActionTypes } from '@desquare/store/actions';
import { map, exhaustMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ILocationState, IAppState } from '@desquare/store/states';
import { fromLocation } from '@desquare/store/selectors';

@Injectable()
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private store: Store<IAppState & ILocationState>,
    private deleteProfileLocationGQL: DeleteLocationGQL
  ) {}

  deleteLocation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof locationActions.deleteLocation>>(
        LocationActionTypes.DELETE_LOCATION
      ),
      withLatestFrom(this.store.select(fromLocation.locations)),
      exhaustMap(([action, locations]) =>
        this.deleteProfileLocationGQL.mutate({ input: action.input }).pipe(
          map(({ data }) => {
            if (data?.deleteLocation?.isSuccessful && locations) {
              return locationActions.deleteLocationSuccess({
                status: {
                  isSuccessful: true,
                  message: 'DELETE_LOCATION_SUCCESS',
                },
                locations: locations.filter((x) => x.id !== action.input.id),
              });
            } else {
              return locationActions.deleteLocationSuccess({
                status: { isSuccessful: false, message: 'UNKNOWN_ERROR' },
              });
            }
          }),
          catchError((error) =>
            of(locationActions.deleteLocationError({ error }))
          )
        )
      )
    )
  );
}
