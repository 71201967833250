<div ngbAccordion class="accordion-custom hide-caret" open="false">
  <div ngbAccordionItem [collapsed]="false">
    <div ngbAccordionHeader>
      <div ngbAccordionButton class="accordion-button">
        <div class="row w-100 align-items-center">
          <h4 class="col-4 text-truncate">
            {{ playlist().name }}
          </h4>
          <!-- <div class="col-1" *ngIf="devicePlaylistStatus">
            <span
              class="badge"
              [class.bg-success]="statusSuccess"
              [class.bg-danger]="statusDanger"
              [class.bg-warning]="isForcePublishShown"
            >
              {{ devicePlaylistStatus | translate }}
            </span>
          </div> -->
          <div
            class="col-3 col-xl-2 text-truncate"
            ngbTooltip="{{
              (playlist().updatedAt | date : 'short') || ('NO_DATA' | translate)
            }}"
          >
            {{
              (playlist().updatedAt | date : 'short') || ('NO_DATA' | translate)
            }}
          </div>
          @if(showLayoutRegions){
          <div class="col-3 d-flex">
            @if(canChangeRegion){
            <div
              ngbDropdown
              container="body"
              class="d-none d-sm-flex flex-grow-1"
            >
              @if(layout()){
              <app-layout-explorer
                [layout]="layout()"
                [boundingBox]="'40px'"
                [selected]="playlist().region"
                [canSelect]="false"
                [showText]="false"
                (selectedChange)="onSelectedChange($event)"
              ></app-layout-explorer>
              }
              <input
                (click)="$event.stopPropagation()"
                type="button"
                class="ms-1 form-control form-control-dark dropdown"
                ngbDropdownToggle
                value="{{
                  !isDefaultRegion
                    ? playlist().region
                    : ('REGION_CANVAS_ROOT' | translate)
                }}"
              />
              <div
                ngbDropdownMenu
                class="form-control form-control-dark shadowed-box text-white"
              >
                <span
                  ngbDropdownItem
                  class="form-control form-control-dark dropdown-items"
                  (click)="setRegion('')"
                  >{{ 'REGION_CANVAS_ROOT' | translate }}</span
                >
                @for(region of layoutRegions(); track region){
                <span
                  ngbDropdownItem
                  class="form-control form-control-dark dropdown-items"
                  (click)="setRegion(region)"
                  >{{ region }}</span
                >
                }
              </div>
            </div>
            } @if(mustUpdateRegion){
            <button
              class="ms-1 btn btn-outline-primary"
              (click)="resetRegion(); $event.stopPropagation()"
              ngbTooltip="Region is not available in the layout. Reset to background."
            >
              {{ 'REGION_RESET' | translate }}
            </button>
            }
          </div>
          }

          <!-- <div class="col-2">
            {{ (playlist().devicePlaylistTimestamp | date: 'short') || ('NO_DATA' | translate) }}
          </div> -->
          <div class="d-flex justify-content-end col-2 col-xl-3">
            <button
              class="me-1 btn btn-outline-primary"
              (click)="redirectToPage(); $event.stopPropagation()"
            >
              <span class="d-none d-xl-block">
                {{ 'EDIT' | translate }}
              </span>
              <span
                class="d-block d-xl-none"
                ngbTooltip="{{ 'EDIT' | translate }}"
              >
                <i class="ri-edit-box-fill"></i>
              </span>
            </button>
            @if(isForcePublishShown){
            <button
              class="me-1 btn btn-outline-primary"
              (click)="forcePublish(); $event.stopPropagation()"
            >
              {{ 'FORCE_PUBLISH' | translate }}
            </button>
            }
            <button
              class="btn btn-outline-danger"
              (click)="removePlaylist(); $event.stopPropagation()"
            >
              <span class="d-none d-xl-block">
                {{ 'REMOVE' | translate }}
              </span>
              <span
                class="d-block d-xl-none"
                ngbTooltip="{{ 'REMOVE' | translate }}"
              >
                <i class="ri-delete-bin-5-fill"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          @for(asset of playlist().assets; track asset.id){
          <app-asset-row
            class="asset-row"
            [asset]="asset"
            [currentPlaylistStatus]="activityStatus()"
            [readOnly]="true"
            [assetsCount]="playlist().assets.length"
          ></app-asset-row>
          }
        </ng-template>
      </div>
    </div>
  </div>
</div>
